import { useAppSelector } from "@src/ducks/ducksHook";
import useSoundService from "@src/ducks/hooks/useSoundService";
import { selectedUserCoins } from "@src/ducks/slices/user.slice";
import numeral from "numeral";
import React from "react";
import { Text } from "react-native";
import { CoinContext } from "../../main/CoinProvider";
import useStyles from "./styles.css";

const SilverCoins = ({ baseHeight }: any) => {
  const styles = useStyles();
  const countRef = React.useRef<any>();
  const { claimSoundToggle } = useSoundService();
  const [deducted, setDeducted] = React.useState(0);
  const [addamount, setAddAmount] = React.useState(0);
  const { SILVER }  = useAppSelector(selectedUserCoins);
  const [currentSilver, setCurrentSilver] = React.useState(0);
  const { currency, onAdditionalCurrency } = React.useContext(CoinContext);

  const onIncrease = (amount: number) => {
    setAddAmount(amount);
    setDeducted(Math.trunc(amount / 360));
  };

  React.useEffect(() => {
    setCurrentSilver(SILVER);
  }, [SILVER]);

  React.useEffect(() => {
    if (addamount <= 0) {
      countRef.current && clearInterval(countRef.current);
      setCurrentSilver((lastNumber) => {
        return lastNumber + addamount;
      });
      onAdditionalCurrency({ silver: 0, bg: 0, gwz: 0});
      setAddAmount(0);
      return;
    }

    countRef.current = setInterval(() => {
      setAddAmount((lastNumber) => {
        return lastNumber - deducted;
      });
      setCurrentSilver((lastNumber) => {
        return lastNumber + deducted;
      });
    }, 1);

    return () => clearInterval(countRef.current);
  }, [addamount, deducted]);

  React.useEffect(() => {
    if (currency.silver > 0) {
      onIncrease(currency.silver);
      claimSoundToggle();
    }
  }, [currency.silver]);

  return (
    <Text style={[styles.silverText, { fontSize: baseHeight * 0.13}]}
    >{numeral(currentSilver).format("0,000")}</Text>
  );
};

export default SilverCoins;
