import React from "react";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { useAuthService } from "@src/ducks/hooks";
import { ImageButton } from "components/button";
import useStyles from "./styles.css";
import images from "@assets/images";
import { FB_APP_ID } from "@env";

const Facebook = () => {
  const styles = useStyles();
  const { onSignInWithFB } = useAuthService();

  return (
    <FacebookLogin
      appId={FB_APP_ID}
      initParams={{ version: "v16.0" }}
      onSuccess={(res: any) => onSignInWithFB(res.accessToken)}
      onFail={() => {}}
      onProfileSuccess={() => {}}
      render={({ onClick }: any) => (
        <ImageButton
          onPress={onClick}
          style={[styles.btnGoogle]}
          source={images.btn_facebook}
        />
      )}
    />
  );
};

export default Facebook;
