import { CDN_URL } from "@env";
import LandscapeAssets from "./landscape-assets";
import { MainTypes } from "./types/main-types";

const images: MainTypes = {
  // GOLD SHOP
  "gs-shop": CDN_URL + "/image-assets/goldshop/gs-shop.png",
  "kirikito": CDN_URL + "/image-assets/goldshop/kirikito.png",
  "goldshop": CDN_URL + "/image-assets/goldshop/goldshop.png",
  "product-item": CDN_URL + "/image-assets/goldshop/product-item.png",
  "goldshop-hud": CDN_URL + "/image-assets/goldshop/goldshop-hud.webp",
  "treasure-chest": CDN_URL + "/image-assets/goldshop/treasure-chest.webp",
  "confirm-background": CDN_URL + "/image-assets/goldshop/confirm-background.png",
  "silver-package": CDN_URL + "/image-assets/goldshop/silver-package.png",
  "sword-master-package": CDN_URL + "/image-assets/goldshop/sword-master-package.png",
  "product-item-active": CDN_URL + "/image-assets/goldshop/product-item-active.png",
  "goldshop-background": CDN_URL + "/image-assets/goldshop/goldshop-background.webp",
  "purchase-modal-bg": CDN_URL + "/image-assets/goldshop/purchase-modal-bg.png",
  "cross": CDN_URL + "/image-assets/goldshop/cross.png",
  "check": CDN_URL + "/image-assets/goldshop/check.png",
  "notes-success": CDN_URL + "/image-assets/goldshop/notes-success.png",
  "notes-insufficient": CDN_URL + "/image-assets/goldshop/notes-insufficient.png",

  // THUMBNAILS
  "th_dragonroller": CDN_URL + "/image-assets/thumbnails/th_dragonroller.webp",
  "th_highroller": CDN_URL + "/image-assets/thumbnails/th_highroller.webp",
  "th_hotdeals": CDN_URL + "/image-assets/thumbnails/th_hotdeals.webp",
  "th_starterpack": CDN_URL + "/image-assets/thumbnails/th_starterpack.webp",
  "th_tgif": CDN_URL + "/image-assets/thumbnails/th_tgif.webp",
  "th_weeklybooster": CDN_URL + "/image-assets/thumbnails/th_weeklybooster.webp",

  // AMOUNT
  "150k": CDN_URL + "/image-assets/amount/150k.png",
  "200k": CDN_URL + "/image-assets/amount/200k.png",
  "250k": CDN_URL + "/image-assets/amount/250k.png",
  "300k": CDN_URL + "/image-assets/amount/300k.png",
  "350k": CDN_URL + "/image-assets/amount/350k.png",
  "400k": CDN_URL + "/image-assets/amount/400k.png",
  "450k": CDN_URL + "/image-assets/amount/450k.png",
  "500k": CDN_URL + "/image-assets/amount/500k.png",

  // ADS FOLDER
  featured: CDN_URL + "/image-assets/ads/featured.png",

  // MAIN FOLDER
  fortune_logo: CDN_URL + "/image-assets/main/fortune_logo.png",
  phone_rotate: CDN_URL + "/image-assets/main/phone_rotate.png",
  logo_bitpace: CDN_URL + "/image-assets/main/logo_bitpace.png",
  bg_popup: CDN_URL + "/image-assets/main/bg_popup.png",
  gameworkz: CDN_URL + "/image-assets/main/gameworkz.png",
  bg_progress: CDN_URL + "/image-assets/main/bg_progress.png",
  fb_register: CDN_URL + "/image-assets/main/fb_register.png",
  bg_transparent: CDN_URL + "/image-assets/main/bg_transparent.png",
  maintenance_screen: CDN_URL + "/image-assets/main/maintenance_screen.png",
  mainCreateAccountTitle: CDN_URL + "/image-assets/main/main_create_account_title.png",
  mainRegisterGoogleButton: CDN_URL + "/image-assets/main/main_register_google_button.png",
  mainRegisterEmailButton: CDN_URL + "/image-assets/main/main_register_button.png",
  main_login_button: CDN_URL + "/image-assets/main/main_login_button.png",
  t_terms: CDN_URL + "/image-assets/main/t_terms.png",

  // BUTTON FOLDER
  "btn-mask": CDN_URL + "/image-assets/buttons/btn-mask.png",
  btn_update: CDN_URL + "/image-assets/buttons/btn_update.png",
  btn_done: CDN_URL + "/image-assets/buttons/btn_done.png",
  btn_buy_now: CDN_URL + "/image-assets/buttons/btn_buy_now.png",
  m_btn_play: CDN_URL + "/image-assets/buttons/m_btn_play.png",
  m_btn_done: CDN_URL + "/image-assets/buttons/m_btn_done.png",
  m_btn_collect: CDN_URL + "/image-assets/buttons/m_btn_collect.png",
  btn_continue: CDN_URL + "/image-assets/buttons/btn_continue.png",
  btn_playnow: CDN_URL + "/image-assets/buttons/btn_playnow.png",

  // LOGIN FOLDER
  gw_bg: CDN_URL + "/image-assets/lobby/home_bg.webp",
  login_text: CDN_URL + "/image-assets/login/login_text.png",
  btn_google: CDN_URL + "/image-assets/login/btn_google.png",
  btn_facebook: CDN_URL + "/image-assets/login/btn_facebook.png",

  // SIGNUP FOLDER
  btn_back: CDN_URL + "/image-assets/signup/btn_back.png",
  create_text: CDN_URL + "/image-assets/signup/create_text.png",
  "verify-success": CDN_URL + "/image-assets/signup/verify-success.png",
  "text-email-verification": CDN_URL + "/image-assets/signup/text-email-verification.png",
  "text-verification-failed": CDN_URL + "/image-assets/signup/text-verification-failed.png",

  // FORGOT FOLDER
  forgot: CDN_URL + "/image-assets/forgot/forgot.png",
  btn_close: CDN_URL + "/image-assets/forgot/btn_close.png",
  success: CDN_URL + "/image-assets/forgot/success.png",
  check_email: CDN_URL + "/image-assets/forgot/check_email.png",
  reset_password: CDN_URL + "/image-assets/forgot/reset_password.png",

  // TOPNAV FOLDER
  hud_top: CDN_URL + "/image-assets/topnav/hud_top.webp",
  btn_home: CDN_URL + "/image-assets/topnav/btn_home.webp",
  btn_mail: CDN_URL + "/image-assets/topnav/btn_mail.webp",
  btn_settings: CDN_URL + "/image-assets/topnav/btn_settings.webp",
  silver_bar: CDN_URL + "/image-assets/topnav/silver_bar.webp",
  gold_bar: CDN_URL + "/image-assets/topnav/gold_bar.webp",
  avatar_bg: CDN_URL + "/image-assets/topnav/avatar_bg.webp",
  avatar_shine: CDN_URL + "/image-assets/topnav/avatar_shine.webp",
  gwz_shop: CDN_URL + "/image-assets/topnav/gwz_shop.png",
  gwz_gold: CDN_URL + "/image-assets/topnav/gwz_gold.png",
  bg_gold: CDN_URL + "/image-assets/topnav/bg_gold.png",
  Egg_Jade: CDN_URL + "/image-assets/topnav/Egg_Jade.png",
  warning: CDN_URL + "/image-assets/topnav/warning.png",
  gold_dropdown: CDN_URL + "/image-assets/topnav/gold_dropdown.webp",
  "btn_switch_silver": CDN_URL + "/image-assets/topnav/btn_switch_silver.png",
  "btn_switch_gold": CDN_URL + "/image-assets/topnav/btn_switch_gold.png",


  // BOTTOM NAV FOLDER
  hud_bot: CDN_URL + "/image-assets/bottomnav/hud_bot.webp",
  hud_bot_back: CDN_URL + "/image-assets/bottomnav/hud_bot_back.png",
  btn_bonus: CDN_URL + "/image-assets/bottomnav/btn_bonus.webp",
  btn_hourglass: CDN_URL + "/image-assets/bottomnav/btn_hourglass.webp",
  daily_timer: CDN_URL + "/image-assets/bottomnav/daily_timer.webp",
  gw_shop_button: CDN_URL + "/image-assets/bottomnav/gw-shop-button.png",

  // LOBBY FOLDER
  "game-thumbnail": CDN_URL + "/image-assets/lobby/game-thumbnail.png",
  game_border: CDN_URL + "/image-assets/lobby/game_border.png",
  game_frame: CDN_URL + "/image-assets/lobby/game_frame.png",
  bg_error: CDN_URL + "/image-assets/lobby/bg_error.png",
  arrow_right: CDN_URL + "/image-assets/lobby/arrow_right.png",
  home_bg: CDN_URL + "/image-assets/lobby/home_bg.webp",
  lock_diamond: CDN_URL + "/image-assets/lobby/lock_diamond.png",
  lock_sapphire: CDN_URL + "/image-assets/lobby/lock_sapphire.png",
  lock_emerald: CDN_URL + "/image-assets/lobby/lock_emerald.png",
  ic_top_game: CDN_URL + "/image-assets/lobby/ic_top_game.png",
  ic_level_up: CDN_URL + "/image-assets/lobby/ic_level_up.png",
  curtain: CDN_URL + "/image-assets/lobby/curtain.png",
  "tier-emerald": CDN_URL + "/image-assets/lobby/tier_emerald.png",
  "tier-ruby": CDN_URL + "/image-assets/lobby/tier_ruby.png",
  "tier-diamond": CDN_URL + "/image-assets/lobby/tier_diamond.png",
  "lobby-slot": CDN_URL + "/image-assets/lobby/lobby-slot.png",
  "lobby-table": CDN_URL + "/image-assets/lobby/lobby-table.png",
  "lobby-instant": CDN_URL + "/image-assets/lobby/lobby-instant.png",
  "lobby-action": CDN_URL + "/image-assets/lobby/lobby-action.png",
  "lobby-puzzle": CDN_URL + "/image-assets/lobby/lobby-puzzle.png",
  "lobby-arcade": CDN_URL + "/image-assets/lobby/lobby-arcade.png",

  // SIDE DRAWER
  "side-bar": CDN_URL + "/image-assets/drawer/side-bar.webp",

  // AVATARS FOLDER
  avatar_bill_wb: CDN_URL + "/image-assets/avatars/avatar_bill_wb.png",
  avatar_casino_wb: CDN_URL + "/image-assets/avatars/avatar_casino_wb.png",
  avatar_cowboy_wb: CDN_URL + "/image-assets/avatars/avatar_cowboy_wb.png",
  avatar_cowgirl_wb: CDN_URL + "/image-assets/avatars/avatar_cowgirl_wb.png",
  avatar_gambler_wb: CDN_URL + "/image-assets/avatars/avatar_gambler_wb.png",
  avatar_gamer_wb: CDN_URL + "/image-assets/avatars/avatar_gamer_wb.png",
  avatar_glam_wb: CDN_URL + "/image-assets/avatars/avatar_glam_wb.png",
  avatar_hiphop_wb: CDN_URL + "/image-assets/avatars/avatar_hiphop_wb.png",
  avatar_popstar_wb: CDN_URL + "/image-assets/avatars/avatar_popstar_wb.png",
  avatar_rockstar_wb: CDN_URL + "/image-assets/avatars/avatar_rockstar_wb.png",
  avatar_frame: CDN_URL + "/image-assets/avatars/avatar_frame.png",

  // PROFILE FOLDER
  frame_avatar: CDN_URL + "/image-assets/profile/frame_avatar.png",
  avatar_chair: CDN_URL + "/image-assets/profile/avatar_chair.png",
  btn_arrow: CDN_URL + "/image-assets/profile/btn_arrow.webp",
  btn_tier_rewards: CDN_URL + "/image-assets/profile/btn_tier_rewards.png",
  btn_edit_profile: CDN_URL + "/image-assets/profile/btn_edit_profile.png",
  btn_change_avatar: CDN_URL + "/image-assets/profile/btn_change_avatar.png",
  profile_bg: CDN_URL + "/image-assets/profile/profile_bg.webp",
  profile_frame: CDN_URL + "/image-assets/profile/profile_frame.webp",
  your_tier: CDN_URL + "/image-assets/profile/your_tier.png",
  your_rank: CDN_URL + "/image-assets/profile/your_rank.png",
  icon_edit: CDN_URL + "/image-assets/profile/icon_edit.png",
  tier_ruby: CDN_URL + "/image-assets/profile/tier_ruby.png",
  tier_emerald: CDN_URL + "/image-assets/profile/tier_emerald.png",
  tier_diamond: CDN_URL + "/image-assets/profile/tier_diamond.png",
  bg_edit_name: CDN_URL + "/image-assets/profile/bg_edit_name.png",
  update_profile: CDN_URL + "/image-assets/profile/update_profile.png",

  // BONUS FOLDER
  vault_bg: CDN_URL + "/image-assets/bonus/vault_bg.png",
  btn_collect: CDN_URL + "/image-assets/bonus/btn_collect.png",
  silver_coin: CDN_URL + "/image-assets/bonus/silver_coin.png",

  // EGG JADE FOLDER
  behold_text: CDN_URL + "/image-assets/eggjade/behold_text.png",
  note_text: CDN_URL + "/image-assets/eggjade/note_text.png",
  dragon: CDN_URL + "/image-assets/eggjade/dragon.png",
  bg_egg_full: CDN_URL + "/image-assets/eggjade/bg_egg_full.png",
  bg_collected: CDN_URL + "/image-assets/eggjade/bg_collected.webp",
  btn_ok: CDN_URL + "/image-assets/eggjade/btn_ok.png",
  silver_coin_lbl: CDN_URL + "/image-assets/eggjade/silver_coin_lbl.png",
  silver_coin_frame: CDN_URL + "/image-assets/eggjade/silver_coin_frame.png",
  btn_hatch: CDN_URL + "/image-assets/eggjade/btn_hatch.png",
  btn_play_hatch: CDN_URL + "/image-assets/eggjade/btn_play_hatch.png",
  btn_hatch_now: CDN_URL + "/image-assets/eggjade/btn_hatch_now.png",
  btn_hatch_later: CDN_URL + "/image-assets/eggjade/btn_hatch_later.png",
  btnHome: CDN_URL + "/image-assets/eggjade/home.webp",

  // MISSION FOLDER
  btn_mission: CDN_URL + "/image-assets/mission/btn_mission.png",
  bg_item_mission: CDN_URL + "/image-assets/mission/bg_item_mission.png",
  key: CDN_URL + "/image-assets/mission/key.png",
  note_1_key: CDN_URL + "/image-assets/mission/note_1_key.png",
  note_3_key: CDN_URL + "/image-assets/mission/note_3_key.png",
  mission_1key: CDN_URL + "/image-assets/mission/mission_1key.png",
  mission_3keys: CDN_URL + "/image-assets/mission/mission_3keys.png",
  vault_notes: CDN_URL + "/image-assets/mission/vault_notes.png",
  fortune_vault: CDN_URL + "/image-assets/mission/fortune_vault.png",
  mission_vault: CDN_URL + "/image-assets/mission/mission_vault.png",
  btn_open_vault: CDN_URL + "/image-assets/mission/btn_open_vault.png",
  btn_lock_vault: CDN_URL + "/image-assets/mission/btn_lock_vault.png",
  mission_completed: CDN_URL + "/image-assets/mission/mission_completed.png",
  task_completed: CDN_URL + "/image-assets/mission/task_completed.png",
  ic_mission_500k: CDN_URL + "/image-assets/mission/ic_mission_500k.png",
  ic_mission_1m: CDN_URL + "/image-assets/mission/ic_mission_1m.png",
  ic_mission_2m: CDN_URL + "/image-assets/mission/ic_mission_2m.png",
  ic_mission_change_avatar: CDN_URL + "/image-assets/mission/ic_mission_change_avatar.png",
  ic_mission_collect_bonus: CDN_URL + "/image-assets/mission/ic_mission_collect_bonus.png",
  ic_mission_daily_bonus: CDN_URL + "/image-assets/mission/ic_mission_daily_bonus.png",
  ic_mission_display_name: CDN_URL + "/image-assets/mission/ic_mission_display_name.png",
  ic_mission_instant_games: CDN_URL + "/image-assets/mission/ic_mission_instant_games.png",
  ic_mission_purchase_item: CDN_URL + "/image-assets/mission/ic_mission_purchase_item.png",
  ic_mission_slots: CDN_URL + "/image-assets/mission/ic_mission_slots.png",
  ic_mission_subscribe: CDN_URL + "/image-assets/mission/ic_mission_subscribe.png",
  ic_mission_table_games: CDN_URL + "/image-assets/mission/ic_mission_table_games.png",
  ic_mission_update_profile: CDN_URL + "/image-assets/mission/ic_mission_update_profile.png",
  open_vault_1m: CDN_URL + "/image-assets/mission/open_vault_1m.png",
  ic_open: CDN_URL + "/image-assets/mission/ic_open.png",
  text_complete_mission: CDN_URL + "/image-assets/mission/text_complete_mission.png",
  "mission-background": CDN_URL + "/image-assets/mission/mission-background.png",
  "100K": CDN_URL + "/image-assets/mission/100K.png",
  "stacked-coin": CDN_URL + "/image-assets/mission/stacked-coin.png",
  "missions-button-play": CDN_URL + "/image-assets/mission/missions-button-play.png",
  "missions-button-collect": CDN_URL + "/image-assets/mission/missions-button-collect.png",
  "missions-button-collected": CDN_URL + "/image-assets/mission/missions-button-collected.png",
  "t-mission-title": CDN_URL + "/image-assets/mission/t-mission-title.png",

  // SETTINGS FOLDER
  fb_share: CDN_URL + "/image-assets/settings/fb_share.png",
  btn_about_gold: CDN_URL + "/image-assets/settings/btn_about_gold.webp",

  // GAMEVIEW FOLDER
  fortune8: CDN_URL + "/image-assets/gameview/fortune8.png",
  shop: CDN_URL + "/image-assets/gameview/shop.png",
  ic_change: CDN_URL + "/image-assets/gameview/ic_change.png",
  ic_arrow: CDN_URL + "/image-assets/gameview/ic_arrow.png",
  arrow_red: CDN_URL + "/image-assets/gameview/arrow_red.png",
  switch_silver: CDN_URL + "/image-assets/gameview/switch_silver.png",
  play_silver: CDN_URL + "/image-assets/gameview/play_silver.png",
  play_gold: CDN_URL + "/image-assets/gameview/play_gold.png",
  select_coin: CDN_URL + "/image-assets/gameview/select_coin.png",
  close_circle: CDN_URL + "/image-assets/gameview/close_circle.png",

  // INBOX FOLDER
  "bg-inbox": CDN_URL + "/image-assets/inbox/bg-inbox.webp",
  "t-inbox-empty": CDN_URL + "/image-assets/inbox/t-inbox-empty.png",
  "t-inbox-title": CDN_URL + "/image-assets/inbox/t-inbox-title.png",
  "inbox-item-bg": CDN_URL + "/image-assets/inbox/inbox-item-bg.png",
  "inbox-item-unread-bg": CDN_URL + "/image-assets/inbox/inbox-item-unread-bg.png",
  "btn-delete-all-inbox": CDN_URL + "/image-assets/inbox/btn-delete-all-inbox.png",
  "inbox-close": CDN_URL + "/image-assets/inbox/inbox-close.png",
  bg_message: CDN_URL + "/image-assets/inbox/bg_message.png",
  btn_read: CDN_URL + "/image-assets/inbox/btn_read.png",
  logo_inbox: CDN_URL + "/image-assets/inbox/logo_inbox.png",
  bg_panel: CDN_URL + "/image-assets/inbox/bg_panel.png",
  ic_bonus: CDN_URL + "/image-assets/inbox/ic_bonus.png",
  ic_congrats: CDN_URL + "/image-assets/inbox/ic_congrats.png",
  ic_fortune: CDN_URL + "/image-assets/inbox/ic_fortune.png",
  ic_message: CDN_URL + "/image-assets/inbox/ic_message.png",
  ic_more_games: CDN_URL + "/image-assets/inbox/ic_more_games.png",
  ic_play: CDN_URL + "/image-assets/inbox/ic_play.png",
  ic_trophy_bronze: CDN_URL + "/image-assets/inbox/ic_trophy_bronze.png",
  ic_trophy_gold: CDN_URL + "/image-assets/inbox/ic_trophy_gold.png",
  ic_trophy_silver: CDN_URL + "/image-assets/inbox/ic_trophy_silver.png",
  ic_version_update: CDN_URL + "/image-assets/inbox/ic_version_update.png",
  ic_version: CDN_URL + "/image-assets/inbox/ic_version.png",
  ic_welcome: CDN_URL + "/image-assets/inbox/ic_welcome.png",
  ic_elves: CDN_URL + "/image-assets/inbox/ic_elves.png",
  ic_daily: CDN_URL + "/image-assets/inbox/ic_daily.png",
  ic_trophy: CDN_URL + "/image-assets/inbox/ic_trophy.png",
  ic_silver: CDN_URL + "/image-assets/inbox/ic_silver.png",
  ic_sale: CDN_URL + "/image-assets/inbox/ic_sale.png",
  ic_pachin: CDN_URL + "/image-assets/inbox/ic_pachin.png",
  ic_candy: CDN_URL + "/image-assets/inbox/ic_candy.png",
  ic_shop: CDN_URL + "/image-assets/inbox/ic_shop.png",
  cheaptuesday: CDN_URL + "/image-assets/inbox/cheaptuesday.png",
  tryluck: CDN_URL + "/image-assets/inbox/tryluck.png",
  loveslot: CDN_URL + "/image-assets/inbox/loveslot.png",
  pachin: CDN_URL + "/image-assets/inbox/pachin.png",
  candy: CDN_URL + "/image-assets/inbox/candy.png",
  needmore: CDN_URL + "/image-assets/inbox/needmore.png",

  // LEADERBOARD FOLDER
  l_logo: CDN_URL + "/image-assets/leaderboard/l_logo.png",
  l_bar: CDN_URL + "/image-assets/leaderboard/l_bar.png",
  bg_lboard: CDN_URL + "/image-assets/leaderboard/bg_lboard.png",
  bg_tournament: CDN_URL + "/image-assets/leaderboard/bg_tournament.webp",
  weekly_tournament: CDN_URL + "/image-assets/leaderboard/weekly_tournament.png",
  BRONZE: CDN_URL + "/image-assets/leaderboard/BRONZE.png",
  GOLD: CDN_URL + "/image-assets/leaderboard/GOLD.png",
  SILVER: CDN_URL + "/image-assets/leaderboard/SILVER.png",
  l_your_rank: CDN_URL + "/image-assets/leaderboard/your_rank.png",
  bg_rank: CDN_URL + "/image-assets/leaderboard/bg_rank.png",
  bg_player_field: CDN_URL + "/image-assets/leaderboard/bg_player.png",
  current_rank_badge: CDN_URL + "/image-assets/leaderboard/current_rank_badge.png",
  bg_selected_player: CDN_URL + "/image-assets/leaderboard/bg_selected_player.png",

  // SHOP FOLDER
  shop_close: CDN_URL + "/image-assets/shop/shop_close.png",
  bg_shop_allset: CDN_URL + "/image-assets/shop/bg_shop_allset.png",
  bg_subscription_allset: CDN_URL + "/image-assets/shop/bg_subscription_allset.png",
  best_value: CDN_URL + "/image-assets/shop/best_value.png",
  most_popular: CDN_URL + "/image-assets/shop/most_popular.png",

  // BITPACE
  bitpace_error: CDN_URL + "/image-assets/bitpace/bitpace_error.png",
  bitpace_success: CDN_URL + "/image-assets/bitpace/bitpace_success.png",
  "apcopay-logo": CDN_URL + "/image-assets/bitpace/apcopay-logo.png",

  // POP UPS
  thank_you: CDN_URL + "/image-assets/popup/thank_you.png",
  star: CDN_URL + "/image-assets/popup/star.png",
  "dragon-roller": CDN_URL + "/image-assets/popup/dragon-roller.png",
  "high-roller": CDN_URL + "/image-assets/popup/high-roller.png",
  "hot-deals": CDN_URL + "/image-assets/popup/hot-deals.png",
  "starter-pack": CDN_URL + "/image-assets/popup/starter-pack.png",
  "tgif": CDN_URL + "/image-assets/popup/weekly-booster.png",
  "weekly-booster": CDN_URL + "/image-assets/popup/weekly-booster.png",
};

// load splash screen resources first
export const SplashAssets = [
  images["gw_bg"],
];

// load portrait animation
export const PortraitAssets = [
  LandscapeAssets["img_0"],
  LandscapeAssets["img_1"],
  LandscapeAssets["img_2"],
  LandscapeAssets["img_3"],
];

// load portrait animation
export const HowTopPlayAssets = [
  // images["step-1"],
  // images["step-2"],
  // images["step-3"],
];

// image preloading
export const ImageAssets = Object.keys(images).map((key: string) => images[key as keyof typeof images]);

export default images;
