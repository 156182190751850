import { useLobbyService } from "@src/ducks/hooks";
import images from "@assets/images";
import React from "react";
import { Image, Pressable, Text, View } from "react-native";
import Animated, {
  Extrapolation,
  interpolate,
  useAnimatedStyle,
  useSharedValue
} from "react-native-reanimated";
import { ResponsiveContext } from "../provider";
import useStyles from "./styles.css";

type Props = {
  onClose: () => void;
}

const ItemShop = ({onClose}: Props) => {
  const styles = useStyles();
  const animated = useSharedValue(0);
  const { onChangeScreen } = useLobbyService();
  const { baseWidth, baseHeight } = React.useContext(ResponsiveContext);

  const onPress = () => {
    onChangeScreen({ screen: "gold-shop" });
    onClose();
  };

  const animateStyle = useAnimatedStyle(() => {
    const translateX = interpolate(
      animated.value,
      [0, 1],
      [baseWidth * 0.11, 0],
      Extrapolation.CLAMP
    );

    return {
      transform: [{ translateX }],
      width: baseWidth * 0.11
    };
  }, [baseWidth, baseHeight]);

  return (
    <View style={styles.v_container_shop}>
      <Pressable onPress={onPress} style={styles.v_item_shop}>
        <Image
          resizeMode="stretch"
          source={images.gwz_shop}
          style={styles.i_gwz_shop}
        />
        <Text style={[styles.t_gold_amount, { marginRight: 0, fontSize: baseHeight * 0.09}]}>Visit Gold Shop</Text>
      </Pressable>
      <Animated.View style={[styles.v_coming_soon, animateStyle]}>
        <Image
          resizeMode="stretch"
          source={images.warning}
          style={styles.i_warning}
        />
        <Text style={[styles.t_gold_amount, { marginRight: 0, fontSize: baseHeight * 0.09}]}>COMING SOON</Text>
      </Animated.View>
    </View>
  );
};

export default ItemShop;
