import React from "react";
import * as Google from "expo-auth-session/providers/google";
import { useAuthService } from "@src/ducks/hooks";
import { ImageButton } from "components/button";
import useStyles from "./styles.css";
import images from "@assets/images";
import {
  EXPO_CLIENT_ID,
  IOS_CLIENT_ID,
  ANDROID_CLIENT_ID,
  WEB_CLIENT_ID,
} from "@env";

const config = {
  expoClientId: EXPO_CLIENT_ID,
  iosClientId: IOS_CLIENT_ID,
  androidClientId: ANDROID_CLIENT_ID,
  webClientId: WEB_CLIENT_ID,
};

const GoogleButton = () => {
  const styles = useStyles();
  const { onSignInWithGoogle } = useAuthService();
  const [request, response, promptAsync]: any = Google.useAuthRequest(config);

  React.useEffect(() => {
    if (response?.type === "success") {
      const { accessToken } = response.authentication;
      onSignInWithGoogle(accessToken);
    }
  }, [response]);

  return (
    <ImageButton
      disabled={!request}
      onPress={() => promptAsync()}
      style={[styles.btnGoogle]}
      source={images.btn_google}
    />
  );
};

export default GoogleButton;
