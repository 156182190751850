import { CDN_URL } from "@env";
import { HotDealsTypes } from "./types/hot-deals/hot-deals-types";

const HotDealsAssets: HotDealsTypes = {
  "img_0": CDN_URL + "/image-assets/animation-assets/hot-deals/hot-deals/images/img_0.png",
  "img_1": CDN_URL + "/image-assets/animation-assets/hot-deals/hot-deals/images/img_1.png",
  "img_2": CDN_URL + "/image-assets/animation-assets/hot-deals/hot-deals/images/img_2.png",
  "img_3": CDN_URL + "/image-assets/animation-assets/hot-deals/hot-deals/images/img_3.png",
  "img_4": CDN_URL + "/image-assets/animation-assets/hot-deals/hot-deals/images/img_4.png",
  "img_5": CDN_URL + "/image-assets/animation-assets/hot-deals/hot-deals/images/img_5.png",

  "seq_0_0": CDN_URL + "/image-assets/animation-assets/hot-deals/hot-deals/images/seq_0_0.png",
  "seq_0_1": CDN_URL + "/image-assets/animation-assets/hot-deals/hot-deals/images/seq_0_1.png",
  "seq_0_2": CDN_URL + "/image-assets/animation-assets/hot-deals/hot-deals/images/seq_0_2.png",
  "seq_0_3": CDN_URL + "/image-assets/animation-assets/hot-deals/hot-deals/images/seq_0_3.png",
  "seq_0_4": CDN_URL + "/image-assets/animation-assets/hot-deals/hot-deals/images/seq_0_4.png",
  "seq_0_5": CDN_URL + "/image-assets/animation-assets/hot-deals/hot-deals/images/seq_0_5.png",
  "seq_0_6": CDN_URL + "/image-assets/animation-assets/hot-deals/hot-deals/images/seq_0_6.png",
};

export default HotDealsAssets;
