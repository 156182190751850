import { CreateResponsiveStyle } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      width: "100%",
      height: "100%",
      position: "absolute",
      alignItems: "center",
      justifyContent: "center",
    },
    imageStyle: {
      width: "100%",
      height: "100%",
      position: "absolute",
      borderRadius: 1000,
    },
    t_timer: {
      color: "black",
      position: "absolute",
      fontFamily: "Roboto-Bold",
    },
  },
);

export default useStyles;
