import { useLobbyService } from "@src/ducks/hooks";
import images from "@assets/images";
import { ImageButton } from "components/button";
import React from "react";
import { ResponsiveContext } from "../provider";

const GWZShop = () => {
  const { onChangeScreen } = useLobbyService();
  const onPress = () =>  onChangeScreen({ screen: "gold-shop" });
  const { baseWidth, baseHeight } = React.useContext(ResponsiveContext);

  return (
    <ImageButton
      scaleSize={0.97}
      onPress={onPress}
      activeOpacity={1}
      source={images.gwz_shop}
      style={[{ zIndex: 2, position: "absolute", right: -baseWidth * 0.07, width: baseHeight * 0.5381, height: baseHeight * 0.5381 }]}
    />
  );
};

export default GWZShop;
