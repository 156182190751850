import React from "react";
import { View, TouchableOpacity } from "react-native";
import { DimensionContext } from "@src/DimensionProvider";
import { useSettingsService } from "@src/ducks/hooks";
import Icon from "@expo/vector-icons/Ionicons";
import SVGSettings from "@assets/svg/settings";
import useStyles from "./styles.css";
import { colors } from "@src/theme";
import Policy from "./Policy";
import Logout from "./Logout";
import Sound from "./Sound";
import Terms from "./Terms";

const Settings = ({ style, onBack }: any) => {
  const styles = useStyles();
  const { onChangeSettings } = useSettingsService();
  const { hudHeight, baseWidth, baseHeight } = React.useContext(DimensionContext);
  const size = React.useMemo(() => {
    return {
      width: baseWidth * 0.3228,
      height: baseHeight * 1,
      top: hudHeight * 0.45
    };
  },[hudHeight, baseHeight, baseWidth]);

  return (
    <View style={[styles.container, size, style]}>
      <View style={{position: "absolute" }}>
        <SVGSettings name="bg-settings" width={size.width} height={size.height} />
      </View>
      <View style={styles.v_settings}>
        <View style={{height: size.height * 0.15}} />
        <Sound baseWidth={size.width} baseHeight={size.height} />
        <View style={{height: size.height * 0.04}} />
        <Terms baseWidth={size.width} baseHeight={size.height} />
        <View style={{height: size.height * 0.04}} />
        <Policy baseWidth={size.width} baseHeight={size.height} />
        <View style={{height: size.height * 0.04}} />
        <Logout baseWidth={size.width} baseHeight={size.height} />
        <TouchableOpacity
          onPress={() => (onBack ? onBack() : onChangeSettings(false))}
          style={styles.btn_back} >
          <Icon size={size.width * 0.11} color={colors.white} name="chevron-up-outline" />
        </TouchableOpacity>
      </View>
      <View style={styles.v_bottom} />
    </View>
  );
};

export default Settings;
