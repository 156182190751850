import { colors } from "@src/theme";
import { StyleSheet } from "react-native";
import { CreateResponsiveStyle } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      width: "100%",
      height: "90%",
      position: "absolute",
      alignItems: "center",
    },
    bodyStyle: {
      top: "10%",
      height: "98%",
      width: "85.87%",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    i_bg: {
      width: "100%",
      height: "100%",
      ...StyleSheet.absoluteFillObject,
    },
    v_bg: {
      width: "96%",
      height: "100%",
      alignItems: "center",
      flexDirection: "row",
    },

    // AVATAR STYLES
    v_container: {
      width: "33%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    v_topdivider: { height: "10%", width: 100 },
    v_centerframe: {
      width: "89%",
      height: "100%",
      overflow: "hidden",
      alignItems: "center",
      justifyContent: "center",
    },
    i_avatar_frame: {
      zIndex: 1,
      width: "130%",
      height: "160%",
      position: "absolute",
    },
    i_avatar_wb: {
      top: "-5%",
      zIndex: 2,
      width: "80%",
      height: "94%",
    },
    btn_change: { width: "60%", height: "13%", top: "-8%", zIndex: 3 },

    // PROFILE STYLE
    p_container: {
      zIndex: 10,
      width: "35%",
      height: "135%",
      alignItems: "center",
    },
    v_center_profile: {
      top: "-1%",
      width: "100%",
      height: "100%",
      alignItems: "center",
    },
    i_profile_frame: {
      zIndex: 1,
      width: "100%",
      height: "100%",
      position: "absolute",
    },
    v_profile_divider: {
      zIndex: 2,
      width: 100,
      height: "25%",
    },
    v_details: {
      zIndex: 4,
      top: "13%",
      width: "85%",
      height: "58%",
      marginRight: 10,
      alignItems: "center",
      justifyContent: "space-evenly",
    },
    v_player: {
      width: "72%",
      height: "15%",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.black,
    },
    i_player_bg: {
      width: "100%",
      height: "100%",
      position: "absolute",
    },
    btn_edit: {
      top: "17%",
      right: "4%",
      zIndex: 4,
      width: 24,
      height: 24,
      position: "absolute",
    },
    t_player_name: {
      zIndex: 2,
      fontSize: 12,
      color: colors.white,
      fontFamily: "Roboto",
    },
    t_userid: {
      zIndex: 2,
      fontSize: 12,
      color: colors.white,
      fontFamily: "Roboto",
    },
    i_yourrank: {
      zIndex: 3,
      top: "-0.1%",
      width: "90%",
      height: "40%",
    },
    v_ranking: {
      width: "72%",
      height: "15%",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.black,
    },
    t_rank: {
      zIndex: 2,
      fontSize: 20,
      color: colors.white,
      fontFamily: "Roboto",
    },
    btn_edit_profile: { 
      width: "50%", 
      height: "20%", 
      marginTop: "0%", 
      marginLeft: "1%" 
    },
    btn_back: {
      zIndex: 5,
      bottom: "2%",
      width: "20%",
      height: "10%",
      marginRight: "4%",
      position: "absolute",
      alignItems: "center",
      justifyContent: "center",
    },

    // TIER STYLES
    t_topdivider: { height: "10%", width: 100 },
    i_yourtier: {
      top: "5%",
      zIndex: 3,
      width: "70%",
      height: "25%",
      position: "absolute",
    },
    v_tiercenter: {
      width: "100%",
      height: "50%",
      overflow: "hidden",
      top: "4%",
      left: "-4%",
      alignItems: "center",
      justifyContent: "center",
    },
    i_tierlevel: {
      zIndex: 1,
      width: "116%",
      height: "120%",
      position: "absolute",
    },
    btn_tier: { top: "-3%", left: "-4%", width: "60%", height: "13%", zIndex: 3 },
  },
);

export default useStyles;
