import React from "react";
import { Image, ImageRequireSource, Text, View } from "react-native";
import { ResponsiveContext } from "../provider";
import useStyles from "./styles.css";

type Props = {
  source: ImageRequireSource;
  amount: string;
}

const ItemGold = ({ source, amount }: Props) => {
  const styles = useStyles();
  const { baseHeight } = React.useContext(ResponsiveContext);

  return (
    <View style={styles.v_item_gold}>
      <Image
        resizeMode="stretch"
        source={source}
        style={styles.i_gold}
      />
      <Text style={[styles.t_gold_amount, { fontSize: baseHeight * 0.12}]}>{amount}</Text>
    </View>
  );
};

export default ItemGold;
