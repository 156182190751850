/* eslint-disable react/no-unstable-nested-components */
import React from "react";
import Animated, { useAnimatedStyle } from "react-native-reanimated";
import { StyleSheet, FlatList, View } from "react-native";
import images from "@assets/images";
import ActionItem from "./item";

const AnimatedFlatList = Animated.createAnimatedComponent(FlatList);

const Data = [
  {
    name: "Home",
    icon: images.btn_home,
  },
  {
    name: "Settings",
    icon: images.btn_settings,
  },
  {
    name: "Shop",
    icon: images.shop,
  },
  {
    name: "Share",
    icon: images.fb_share,
  },
];

const Actions = ({ actionHeight }: any) => {
  const HStyle = useAnimatedStyle(() => {
    return {
      height: actionHeight.value,
    };
  }, [actionHeight]);

  return (
    <AnimatedFlatList
      data={Data}
      style={[styles.container, HStyle]}
      ListHeaderComponent={<View style={styles.divider} />}
      showsVerticalScrollIndicator={false}
      keyExtractor={(item: any) => item.name}
      ItemSeparatorComponent={() => <View style={styles.divider} />}
      renderItem={({ item }: any) => {
        return <ActionItem key={item.name} {...item} />;
      }}
    />
  );
};

const styles = StyleSheet.create({
  container: { marginTop: 0, overflow: "hidden" },
  divider: { height: 10 },
});

export default Actions;
