import { CreateResponsiveStyle, DEVICE_SIZES } from "rn-responsive-styles";
import { StyleSheet } from "react-native";
import { colors } from "@src/theme";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      zIndex: 3,
      width: "100%",
      height: "100%",
      position: "absolute",
      alignItems: "center",
      justifyContent: "center",
    },
    bodyStyle: {
      width: "58%",
      height: "35%",
      alignItems: "center",
      justifyContent: "center",
    },
    i_background: {
      ...StyleSheet.absoluteFillObject,
      width: "100%",
      height: "100%",
    },
    btn_close: {
      width: "6%",
      height: "20%",
      top: "16%",
      right: "6%",
      zIndex: 6,
      position: "absolute",
    },
    v_error: {
      top: "12%",
      right: "10%",
      width: "63%",
      height: "68%",
      position: "absolute",
      alignItems: "center",
      justifyContent: "center",
    },
    t_error: {
      fontFamily: "Roboto",
      fontSize: 25,
      textAlign: "center",
      color: colors.red,
    },
  },

  {
    [DEVICE_SIZES.MD]: {
      t_error: {
        fontSize: 17,
      },
    },
    [DEVICE_SIZES.SM]: {
      t_error: {
        fontSize: 16,
      },
    },
    [DEVICE_SIZES.XS]: {
      t_error: {
        fontSize: 16,
      },
    },
  }
);

export default useStyles;
