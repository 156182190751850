import React from "react";
import { selectedWeeklyMission } from "@src/ducks/slices/lobby.slice";
import { useAppDispatch, useAppSelector } from "@src/ducks/ducksHook";
import { authActions } from "@src/ducks/slices/auth.slice";
import { useNavigation } from "@react-navigation/native";
import { ImageButton02 } from "components/button";
import VaultJson from "@assets/json/vault.json";
import { ResponsiveContext } from "../provider";
import Lottie from "react-lottie-player";
import useStyles from "./styles.css";

const Vault = () => {
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const navigation = useNavigation<any>();
  const [play, setPlay] = React.useState(false);
  const { baseHeight } = React.useContext(ResponsiveContext);
  const isMissionActive = useAppSelector(selectedWeeklyMission);
  const onSetErrorMessage = React.useCallback((value: string) => {
    dispatch(authActions.setErrorMessage(value));
  }, [dispatch]);

  const onMission = () => {
    if (isMissionActive) {
      onSetErrorMessage("You’ve already claimed\nyour reward today.\n Come back next week for new missions.");
    } else {
      setPlay(true);
    }
  };

  const onComplete = React.useCallback(() => {
    navigation.navigate("Mission");
    setPlay(false);
  },[play]);

  return (
    <ImageButton02
      disabled={play}
      onPress={onMission}
      scaleSize={isMissionActive ? 1 : 0.9}
      style={[
        styles.container,
        { width: baseHeight * 0.8808, height: baseHeight * 0.8808 },
      ]}
    >
      <Lottie
        play
        loop={play}
        onLoopComplete={onComplete}
        animationData={VaultJson}
        segments={[0, 51]}
        style={{ alignSelf: "center", width: "100%", height: "100%"}}
      />
    </ImageButton02>
  );
};

export default React.memo(Vault);
