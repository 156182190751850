import { colors } from "@src/theme";
import React from "react";
import { StyleSheet, View } from "react-native";
import InboxScreen from "./list";
import InboxProvider from "./provider";

const Inbox = () => {
  return (
    <InboxProvider>
      <View style={styles.container}>
        <View style={styles.container}>
          <InboxScreen />
        </View>
      </View>
    </InboxProvider>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.translucent,
  },
});

export default React.memo(Inbox);
