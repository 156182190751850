import { CDN_URL } from "@env";
import { MissionVaultTypes } from "./types/mission/mission-vault-types";

const MissionVaultAssets: MissionVaultTypes = {
  "img_0": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/img_0.png",
  "img_1": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/img_1.png",
  "img_2": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/img_2.png",
  "img_3": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/img_3.png",
  "img_4": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/img_4.png",
  "img_5": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/img_5.png",
  "img_6": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/img_6.png",
  "img_7": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/img_7.png",
  "seq_0_0": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_0.png",
  "seq_0_1": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_1.png",
  "seq_0_2": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_2.png",
  "seq_0_3": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_3.png",
  "seq_0_4": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_4.png",
  "seq_0_5": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_5.png",
  "seq_0_6": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_6.png",
  "seq_0_7": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_7.png",
  "seq_0_8": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_8.png",
  "seq_0_9": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_9.png",
  "seq_0_10": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_10.png",
  "seq_0_11": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_11.png",
  "seq_0_12": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_12.png",
  "seq_0_13": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_13.png",
  "seq_0_14": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_14.png",
  "seq_0_15": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_15.png",
  "seq_0_16": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_16.png",
  "seq_0_17": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_17.png",
  "seq_0_18": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_18.png",
  "seq_0_19": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_19.png",
  "seq_0_20": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_20.png",
  "seq_0_21": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_21.png",
  "seq_0_22": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_22.png",
  "seq_0_23": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_23.png",
  "seq_0_24": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_24.png",
  "seq_0_25": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_25.png",
  "seq_0_26": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_26.png",
  "seq_0_27": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_27.png",
  "seq_0_28": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_28.png",
  "seq_0_29": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_29.png",
  "seq_0_30": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_30.png",
  "seq_0_31": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_31.png",
  "seq_0_32": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_32.png",
  "seq_0_33": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_33.png",
  "seq_0_34": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_34.png",
  "seq_0_35": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_35.png",
  "seq_0_36": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_36.png",
  "seq_0_37": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_37.png",
  "seq_0_38": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_38.png",
  "seq_0_39": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_39.png",
  "seq_0_40": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_40.png",
  "seq_0_41": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_41.png",
  "seq_0_42": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_42.png",
  "seq_0_43": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_43.png",
  "seq_0_44": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_44.png",
  "seq_0_45": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_45.png",
  "seq_0_46": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_46.png",
  "seq_0_47": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_47.png",
  "seq_0_48": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_48.png",
  "seq_0_49": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_49.png",
  "seq_0_50": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_50.png",
  "seq_0_51": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_51.png",
  "seq_0_52": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_52.png",
  "seq_0_53": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_53.png",
  "seq_0_54": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_54.png",
  "seq_0_55": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_55.png",
  "seq_0_56": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_56.png",
  "seq_0_57": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_57.png",
  "seq_0_58": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_58.png",
  "seq_0_59": CDN_URL + "/image-assets/animation-assets/mission/mission-vault/images/seq_0_59.png",
};

export default MissionVaultAssets;
