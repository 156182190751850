import { useAppSelector } from "../ducksHook";
import { selectedProducts, selectedSubscriptionProducts } from "../slices/lobby.slice";
import { Product } from "../types";

type ServiceOperators = {
    getProductById: (id: number) => Product;
    getProductByName: (id: string) => Product;
    getSubscriptionById: (id: string) => Product;
};

export const useProductService = (): Readonly<ServiceOperators> => {
  const products = useAppSelector(selectedProducts);
  const subscription = useAppSelector(selectedSubscriptionProducts);
    
  const getProductById = (id: number): Product => {
    const item = products.find((product) => product.id === id);
    return item || {};
  };

  const getProductByName = (name: string): Product => {
    const item = products.find((product) => product.name === name);
    return item || {};
  };

  const getSubscriptionById = (id: string | number): Product => {
    const item = subscription.find((product) => product.id === id);
    return item || {};
  };

  return {
    getProductById,
    getProductByName,
    getSubscriptionById,
  };
};