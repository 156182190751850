import React from "react";
import { selectedGModeFailed, selectedGModeLoading, selectedSwordPlay } from "@src/ducks/slices/gmode.slice";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useGModeService } from "@src/ducks/hooks";
import Loading from "components/loading/Loading";
import { ImageButton } from "components/button";
import { GModeContext } from "../provider";
import { View, Image } from "react-native";
import { Scale } from "utils/animation";
import useMetrics from "utils/metrics";
import useStyles from "./styles.css";
import images from "@assets/images";
import _ from "lodash";
import { selectedUserCoins } from "@src/ducks/slices/user.slice";
import { useNavigation } from "@react-navigation/native";

const CoinsDialog = () => {
  const styles = useStyles();
  const { verticalScale } = useMetrics();
  const navigation = useNavigation<any>();
  const success = useAppSelector(selectedSwordPlay);
  const failed: any = useAppSelector(selectedGModeFailed);
  const { onSwordPlayBuyDiamonds } = useGModeService();
  const isLoading = useAppSelector(selectedGModeLoading);
  const { GOLD, SILVER } = useAppSelector(selectedUserCoins);
  const { smdata, onSetSMdata, onErrorMessage, onSuccessMessage } = React.useContext(GModeContext);

  const onTopup = (currency: string) => {
    const productPrice = Math.round(smdata.productPrice || 0);
    const silverPrice  = productPrice * 50000;
    if(currency === "SILVER" && silverPrice > SILVER){
      onErrorMessage("Insufficient silver, Please top up more");
      navigation.navigate("BuyShop");
    }else  if(currency === "GOLD" && productPrice > GOLD){
      onErrorMessage("Insufficient gold, Please top up more");
      navigation.navigate("BuyShop");
    }else{
      onSwordPlayBuyDiamonds({...smdata, currency});
    }
  };

  React.useEffect(() => {
    if(!_.isEmpty(success)){
      onSetSMdata({});
      onSuccessMessage();
    }
  },[success]);

  React.useEffect(() => {
    if(!_.isEmpty(failed.message)){
      onErrorMessage(failed.message);
    }
  },[failed]);

  if(_.isEmpty(smdata)){
    return null;
  }

  return (
    <View style={styles.container}>
      <Scale style={styles.container}>
        <View
          style={[
            styles.center_view,
            { width: verticalScale(400), height: verticalScale(250) },
          ]}
        >
          <Image
            style={styles.i_background}
            source={images.bg_popup}
            resizeMode="stretch"
          />
          <ImageButton
            onPress={() => onSetSMdata({})}
            style={[styles.btn_close, 
              { 
                top: -verticalScale(10),
                right: -verticalScale(10),
                width: verticalScale(30),
                height: verticalScale(30) 
              }]}
            source={images.close_circle}
          />
          <Image
            style={{ width: verticalScale(180), height: verticalScale(30) }}
            source={images.select_coin} resizeMode="contain" />
          <View style={{height: verticalScale(10)}} />
          <ImageButton
            scaleSize={0.97}
            onPress={() => onTopup("SILVER")}
            style={{ width: verticalScale(280), height: verticalScale(70) }}
            source={images.play_silver} />
          <View style={{height: verticalScale(10)}} />
          <ImageButton
            scaleSize={0.97}
            onPress={() => onTopup("GOLD")}
            style={{ width: verticalScale(280), height: verticalScale(70) }}
            source={images.play_gold} />
        </View>
      </Scale>
      <Loading isLoading={isLoading} />
    </View>
  );
};

export default CoinsDialog;
