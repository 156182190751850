import { CreateResponsiveStyle } from "rn-responsive-styles";
import { StyleSheet } from "react-native";
import { colors } from "@src/theme";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    bodyStyle: {
      width: "58%",
      height: "72.53%",
      // maxHeight: 460,
      alignItems: "center",
      justifyContent: "center",
    },
    imageBorderStyle: {
      width: "100%",
      height: "100%",
      ...StyleSheet.absoluteFillObject,
    },
    smallLogoContainer: {
      height: 100,
      width: 100,
      top: -45,
      position: "absolute",
    },
    bottomHeight: {
      width: 100,
      height: "5%",
    },
    header: {
      zIndex: 1,
      fontSize: 30,
      paddingTop: 48,
      color: "#6C0B7B",
      marginBottom: 40,
      fontFamily: "Roboto-Medium",
    },
    forgotWrapper: { width: "60%",  flexDirection: "row", justifyContent: "space-between" },
    btnForgot: { alignSelf: "flex-end" },
    txtSignUp: {
      fontSize: 14.8,
      color: colors.white,
      fontFamily: "Roboto",
    },
    txtForgot: {
      fontSize: 14,
      color: colors.white,
      fontFamily: "Roboto",
      textDecorationLine: "underline",
    },
    v_inputs: {
      width: "100%",
      height: "37%",
      alignItems: "center",
      justifyContent: "center",
    },
    viewLoginStyle: {
      width: "49%",
      height: "17%",
      marginTop: "2%",
      alignItems: "center",
      justifyContent: "center",
    },
    loginTextStyle: { width: "80%", height: "100%" },
    textAlign: { textAlign: "center", paddingLeft: 0 },
    ti_email: {
      height: "26%",
      marginBottom: "1.8%"
    },

    // FOOTER COMPONENT STYLE
    loginWrapper: {
      flex: 1,
      height: "47%",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    separator: {
      height: "25%",
      width: "45%",
      paddingBottom: 10,
      marginRight: 10,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    divider: {
      height: 1,
      width: "20%",
      backgroundColor: "white",
    },
    txtOrstyle: {
      color: "white",
      paddingHorizontal: 10,
      fontFamily: "Roboto",
    },
    btnLogin: { width: "20%", height: "33%" },
    btnGoogle: { width: "47%", height: "100%" },
    v_footer: {
      flexDirection: "row",
      width: "76%",
      height: "33%",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
);

export default useStyles;
