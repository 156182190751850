import React from "react";
import { DimensionContext } from "@src/DimensionProvider";
import { useNavigation } from "@react-navigation/native";
import { Pressable } from "react-native";
import useStyles from "./styles.css";

const ShopButton = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const { hudHeight } = React.useContext(DimensionContext);

  return (
    <Pressable onPress={() => navigation.navigate("BuyShop")} 
      style={[styles.btn_style, { width: hudHeight * 1.31, height: hudHeight * 1.31,  }]} />
  );
};

export default ShopButton;
