import React from "react";
import { View, ScrollView, Text } from "react-native";
import { DimensionContext } from "@src/DimensionProvider";
import { Button, ImageButton02 } from "components/button";
import { useNavigation } from "@react-navigation/native";
import SVGSettings from "@assets/svg/settings";
import useStyles from "./styles.css";
import { colors } from "@src/theme";
import SVGIcon from "@assets/svg";

const TierMoreInfo = ({ onBack }: any) => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const { baseWidth, height } = React.useContext(DimensionContext);
  const WIDTH = baseWidth * 0.7;

  return (
    <View style={styles.container}>
      <ScrollView style={[styles.scrollview, { height }]}>
        <View style={[styles.v_info_more, { width: WIDTH, height: WIDTH * 1.42 }]}>
          <View style={{position: "absolute"}}>
            <SVGSettings name="bg-tier-explanation" width={WIDTH} height={WIDTH * 1.42} />
          </View>
          <ImageButton02
            onPress={() => navigation.goBack()}
            style={styles.btn_close2}
          >
            <SVGIcon name="close" width={baseWidth * 0.04} height={baseWidth * 0.09} />
          </ImageButton02>
          <View style={styles.v_scroll}>
            <SVGSettings name="g-scroll" width={baseWidth * 0.04} height={baseWidth * 0.06} />
          </View>
          <View style={[styles.v_content]}>
            <View style={{marginTop: "4%"}}>
              <SVGSettings name="t-tier-explanation" width={baseWidth * 0.4} height={baseWidth * 0.06} />
            </View>
            <View style={{width: "80%", marginTop: "4%"}}>
              <Text style={[styles.t_regular, {fontFamily: "Roboto-Medium", fontSize: baseWidth * 0.018}]}>SAPPHIRE TIER - ENTRY LEVEL{"\n\n"}</Text>
              <Text style={[styles.t_regular, {fontSize: baseWidth * 0.018}]}>• limited games access - slots, table games and instant games.</Text>
              <Text style={[styles.t_regular, {fontSize: baseWidth * 0.018}]}>• Access to all app bonus features and reward features.{"\n\n"}</Text>

              <Text style={[styles.t_regular, {fontFamily: "Roboto-Medium", fontSize: baseWidth * 0.018}]}>EMERALD TIER - PLAYER SPEND OF USD$10+ IN A PERIOD OF 12 MONTHS{"\n\n"}</Text>
              <Text style={[styles.t_regular, {fontSize: baseWidth * 0.018}]}>• Receive 3,000,000 Bonus Silver Coins</Text>
              <Text style={[styles.t_regular, {fontSize: baseWidth * 0.018}]}>• Additional access to Emerald tier games - slots, table games, RPG, arcade and instant games.</Text>
              <Text style={[styles.t_regular, {fontSize: baseWidth * 0.018}]}>• Access to all app bonus features and reward features.</Text>
              <Text style={[styles.t_regular, {fontSize: baseWidth * 0.018}]}>• Eligibility to earn GWz Gold Coins for every play.{"\n\n"}</Text>

              <Text style={[styles.t_regular, {fontFamily: "Roboto-Medium", fontSize: baseWidth * 0.018}]}>RUBY TIER - PLAYER SPEND OF USD$30+ IN A PERIOD OF 12 MONTHS{"\n\n"}</Text>
              <Text style={[styles.t_regular, {fontSize: baseWidth * 0.018}]}>• Receive 10,000,000 Bonus Coins</Text>
              <Text style={[styles.t_regular, {fontSize: baseWidth * 0.018}]}>• Access to Premium games - slots, table games, RPG, arcade and instant games.</Text>
              <Text style={[styles.t_regular, {fontSize: baseWidth * 0.018}]}>• Access to all app bonus features and reward features.</Text>
              <Text style={[styles.t_regular, {fontSize: baseWidth * 0.018}]}>• Eligibility to earn GWz Gold Coins for every play.{"\n\n"}</Text>

              <Text style={[styles.t_regular, {fontFamily: "Roboto-Medium", fontSize: baseWidth * 0.018}]}>DIAMOND TIER - PLAYER SPEND OF USD$50+ IN A PERIOD OF 12 MONTHS{"\n\n"}</Text>
              <Text style={[styles.t_regular, {fontSize: baseWidth * 0.018}]}>• Receive 17,000,000 Bonus Coins</Text>
              <Text style={[styles.t_regular, {fontSize: baseWidth * 0.018}]}>• All Access to Premium and VIP games in slots, table games, RPG, arcade and instant games, plus early access to upcoming new games.</Text>
              <Text style={[styles.t_regular, {fontSize: baseWidth * 0.018}]}>• Access to all app bonus features and reward features.</Text>
              <Text style={[styles.t_regular, {fontSize: baseWidth * 0.018}]}>• Eligibility to earn GWz Gold Coins for every play.{"\n\n"}</Text>
              <View style={{backgroundColor: colors.red7, paddingHorizontal: "5%", borderRadius: 50, alignSelf: "center", marginTop: "2%", paddingVertical: "0.6%"}}>
                <Text style={[styles.t_description, {fontSize: baseWidth * 0.018 }]}>• NOTE: Accounts Inactive for 12 months and over will{"\n"}automatically reset to Sapphire Tier.</Text>
              </View>
            </View>
          </View>
          <Button
            onPress={onBack}
            style={[styles.btnSubmit, { bottom: "10%" }]}
            width={baseWidth * 0.12}
            height={baseWidth * 0.05}
            label="BACK"
            labelStyle={{fontSize: baseWidth * 0.011}}
          />
        </View>
      </ScrollView>
    </View>
  );
};

export default TierMoreInfo;
