import SVGIcon from "@assets/svg";
import { DimensionContext } from "@src/DimensionProvider";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useAuthService } from "@src/ducks/hooks";
import { selectedChangeScreen, selectedOneTimeShowFavourite } from "@src/ducks/slices/lobby.slice";
import { selectedProfileStatus } from "@src/ducks/slices/settings.slice";
import React from "react";
import { Pressable } from "react-native";
import { Opacity } from "utils/animation";
import useStyles from "./styles.css";

const FavoriteAds = () => {
  const styles = useStyles();
  const { onFavAdsFirstShow } = useAuthService();
  const { screen } = useAppSelector(selectedChangeScreen);
  const { baseWidth } = React.useContext(DimensionContext);
  const hasProfileOpen = useAppSelector(selectedProfileStatus);
  const favAdsFirstShow = useAppSelector(selectedOneTimeShowFavourite);

  if(!favAdsFirstShow || screen !== "main") return null;

  return (
    <Opacity pointerEvents={"auto"} toValue={hasProfileOpen ? 0 : 1} style={[styles.favorite_ads, 
      { width: baseWidth * 0.34, height: baseWidth * 0.12 }]}>
      <SVGIcon name="add-favorite" width={baseWidth * 0.32} height={baseWidth * 0.10} />
      <Pressable onPress={onFavAdsFirstShow} style={styles.btn_close}>
        <SVGIcon name="round-close" width={baseWidth * 0.03} height={baseWidth * 0.03} />
      </Pressable>
    </Opacity>
  );
};

export default FavoriteAds;
