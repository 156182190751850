import { CreateResponsiveStyle } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      // zIndex: 4,
      width: "100%",
      height: "100%",
      position: "absolute",
      alignSelf: "center",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 1000,
      bottom: "-46%",
    },
    imageStyle: { width: "100%", height: "100%" },
    btn_style: { 
      zIndex: 5,
      width: "100%", height: "100%", 
      position: "absolute", borderRadius: 1000, 
      bottom: "-46%",
    },
  },
);

export default useStyles;
