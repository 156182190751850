import { colors } from "@src/theme";
import { StyleSheet } from "react-native";
import { CreateResponsiveStyle } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    centerStyle: {
      width: "100%",
      height: "100%",
      position: "absolute",
    },
    container: {
      flex: 1,
      flexDirection: "row",
    },
    h_100: { height: "100%" },
    v_bgcolor: { backgroundColor: colors.translucent },

    // ADS COMPONENT
    f8_logo_wrapper: {
      zIndex: 3,
      width: "31%",
      heigth: "100%",
      marginLeft: "4%",
      alignItems: "center",
      justifyContent: "center",
    },
    v_mask_banner: {
      width: "60%",
      height: "105%",
      position: "absolute",
      shadowColor: "black",
      shadowOffset: { width: 55, height: 0 },
      shadowOpacity: 0.7,
      shadowRadius: 20,
    },
    f8_style: {
      width: "100%",
      height: "100%",
    },
    b_playnow: {
      width: "96%",
      height: "13%",
      bottom: "17%",
      alignSelf: "center",
      position: "absolute",
      backgroundColor: colors.transparent,
    },

    // ITEM LIST STYLES
    bodyStyle: {
      flex: 1,
      zIndex: 3,
      flexDirection: "row",
      ...StyleSheet.absoluteFillObject,
    },
    v_divider_left: {
      width: "11%",
      height: "100%",
    },
    i_prev: {
      zIndex: 6,
      width: 60,
      height: 90,
      left: "31%",
      alignSelf: "center",
      position: "absolute",
    },
    i_next: {
      zIndex: 6,
      width: 60,
      height: 90,
      right: "3%",
      alignSelf: "center",
      position: "absolute",
    },
    contentContainerStyle: {
      paddingLeft: "23%",
      paddingRight: "12%",
      alignItems: "center",
      // justifyContent: 'center',
    },
    v_empty: {
      left: "60%",
      width: "100%",
      height: "100%",
      position: "absolute",
      alignItems: "center",
      justifyContent: "center",
    },

    // ITEM SLOT STYLES
    slotContainerStyle: {
      width: 230,
      height: 230,
      marginHorizontal: 10,
      alignItems: "center",
      justifyContent: "center",
    },
    border: {
      width: "100%",
      height: "100%",
      position: "absolute",
      overflow: "hidden",
    },
    slotImageStyle: {
      width: "100%",
      height: "100%",
    },
    v_item_center: {
      width: "91%",
      height: "90%",
      overflow: "hidden",
      zIndex: 5,
    },
    v_item_center_80: {
      height: "92.5%",
    },
    v_item_center_20: {
      zIndex: 6,
      height: "20%",
      width: "85%",
      alignSelf: "center",
    },
    translateX: {
      position: "absolute",
      zIndex: 6,
    },
    v_deactivated: {
      width: "100%",
      height: "100%",
      backgroundColor: "#3F363658",
      position: "absolute",
      zIndex: 7,
    },
    i_stone: {
      width: 30,
      height: 45,
      position: "absolute",
      top: "10%", 
      left: "2%"
    },

    i_new: {
      top: -20,
      alignSelf: "center",
      width: "85%",
      height: "25%",
      position: "absolute",
      overflow: "hidden",
      zIndex: 6,
    },
  },
);

export default useStyles;
