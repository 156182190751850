import { CreateResponsiveStyle } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    vw_botnav: {
      bottom: 0,
      zIndex: 4,
      width: "100%",
      height: "17.87%",
      position: "absolute",
    },
    style_navbottom: {
      left: 3,
      width: "100%",
      height: "100%",
      position: "absolute",
    },
    wrapcenter: {
      flex: 1,
      zIndex: 3,
      flexDirection: "row",
    },

    middleStyle: {
      width: "27%",
      borderRadius: 100,
      alignItems: "center",
      justifyContent: "center",
    },
    leftStyle: {
      flex: 1,
      zIndex: 10,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    width10: { width: 10 },
    width30: { width: 30 },
    width35: { width: 35 },

    rightStyle: {
      flex: 1,
      alignItems: "flex-start",
      // justifyContent: "center",
    },
    rightStyleCenter: {
      width: "80%",
      height: "75%",
      flexDirection: "row",
      alignItems: "flex-end",
      justifyContent: "space-between",
    },
    width16: { width: 16 },
  }
);

export default useStyles;
