import React from "react";
import { ImageButton, ImageButton05 } from "components/button";
import { useAuthService } from "@src/ducks/hooks";
import SVGSettings from "@assets/svg/settings";
import { View, Linking } from "react-native";
import useStyles from "./styles.css";
import images from "@assets/images";

const Logout = ({ baseWidth, baseHeight }: any) => {
  const styles = useStyles();
  const {onLogout} = useAuthService();
  const onFaq = () => Linking.openURL("https://fortune8.online/faq");
  const onAboutGold = () => Linking.openURL("https://fortune8.online/gold-coin-rules");

  return (
    <View style={[styles.v_policy, { justifyContent: "center"}]}>
      <ImageButton05 scaleSize={0.98} onPress={onFaq} >
        <SVGSettings name="btn-faq" width={baseWidth * 0.2148} height={baseHeight * 0.1903} />
      </ImageButton05>
      <View style={{width: baseWidth * 0.015}}/>
      <ImageButton
        onPress={onAboutGold}
        source={images.btn_about_gold}
        style={[styles.btn_about_gold, { width: baseWidth * 0.2148, height: baseHeight * 0.1903}]}
      />
      <View style={{width: baseWidth * 0.08}}/>
      <ImageButton05 scaleSize={0.98} onPress={onLogout} >
        <SVGSettings name="btn-logout" width={baseWidth * 0.3389} height={baseHeight * 0.222} />
      </ImageButton05>
    </View>
  );
};

export default React.memo(Logout);
