import HDBJson from "@assets/json/hot-deals-button";
import { useNavigation } from "@react-navigation/native";
import { DimensionContext } from "@src/DimensionProvider";
import React from "react";
import Lottie from "react-lottie-player";
import { Pressable, StyleSheet } from "react-native";
import Animated, {
  Extrapolate,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";

const AnimatedPressable = Animated.createAnimatedComponent(Pressable);

const HotDealsButton = () => {
  const scale = useSharedValue(1);
  const animated = useSharedValue(0);
  const navigation = useNavigation<any>();
  const { baseHeight, baseWidth } = React.useContext(DimensionContext);

  const animateStyle = useAnimatedStyle(() => {
    const translateY = interpolate(
      animated.value,
      [0, 1],
      [baseWidth * 0.033, 0],
      Extrapolate.CLAMP
    );

    return {
      opacity: animated.value,
      transform: [{ translateX: -baseWidth * 0.07 }, {translateY}, {scale: scale.value}],
    };
  }, [baseWidth]);

  const onPress = () => {
    navigation.navigate("HotDealsThumbnails");
  };

  const onPressIn = () => {
    animated.value = withTiming(0.9, { duration: 100});
  };

  const onPressOut = () => {
    animated.value = withTiming(1, { duration: 100});
  };

  React.useEffect(() => {
    animated.value = withTiming(1, { duration: 1000});
  }, []);

  return (
    <AnimatedPressable 
      onPress={onPress}
      onPressIn={onPressIn}
      onPressOut={onPressOut}
      style={[styles.container, animateStyle]}>
      <Lottie
        loop
        play
        animationData={HDBJson}
        rendererSettings={{ preserveAspectRatio: "xMinYMid slice" }}
        style={{width: baseHeight * 0.36, height: baseHeight * 0.28, }}
      />
    </AnimatedPressable>
  );
};

const styles = StyleSheet.create({
  container: { zIndex: 3, position: "absolute", left: 0 }
});

export default HotDealsButton;
