import React from "react";
import ResponsiveProvider from "./provider";
import ShopButton from "./shop/button-mask";
import { View, Image } from "react-native";
import LeaderBoard from "./leaderboard";
import useStyles from "./styles.css";
import images from "@assets/images";
import Collect from "./collect";
import Bonus from "./bonus";
import Shop from "./shop";
import Egg from "./egg";
import Vault from "./vault";
import HotDealsButton from "./hotdeals";
import GWZShop from "./gwz-shop";

const BottomNav = () => {
  const styles = useStyles();

  return (
    <ResponsiveProvider>
      <>
        <Image
          source={{uri: images.hud_bot_back}}
          style={styles.style_navbottom}
          resizeMode="stretch"
        />
        <Shop />
        <Image
          source={{uri: images.hud_bot}}
          style={[styles.style_navbottom]}
          resizeMode="stretch"
        />
        <View pointerEvents="box-none" style={styles.wrapcenter}>
          <View style={styles.leftStyle}>
            <Bonus />
            <Collect />
            <GWZShop />
          </View>
          <View style={styles.middleStyle}>
            <ShopButton />
          </View>
          <View style={[styles.rightStyle]}>
            <View style={styles.rightStyleCenter}>
              <HotDealsButton />
              <LeaderBoard />
              <Egg />
              <Vault />
            </View>
          </View>
        </View>
      </>
    </ResponsiveProvider>
  );
};

export default React.memo(BottomNav);
