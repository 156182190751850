import React from "react";
import { DimensionContext } from "@src/DimensionProvider";
import { Button, ImageButton02 } from "components/button";
import { useNavigation } from "@react-navigation/native";
import SVGSettings from "@assets/svg/settings";
import { View, Image, Text } from "react-native";
import useStyles from "./styles.css";
import images from "@assets/images";
import SVGIcon from "@assets/svg";

const TierLevelReward = ({ onPress }: any) => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const { baseWidth } = React.useContext(DimensionContext);

  return (
    <View style={styles.container2}>
      <View style={[styles.bodyStyle,
        { width: baseWidth * 0.73, height: baseWidth * 0.4142 },
      ]}>
        <Image
          source={images.bg_transparent}
          style={styles.imageBorderStyle}
          resizeMode="stretch"
        />
        <ImageButton02
          onPress={() => navigation.goBack()}
          style={styles.btn_close}
        >
          <SVGIcon name="close" width={baseWidth * 0.04} height={baseWidth * 0.09} />
        </ImageButton02>
        <View style={styles.v_center}>
          <View style={styles.viewForgotStyle}>
            <SVGSettings name="t-tier-level-rewards" width={baseWidth * 0.4} height={baseWidth * 0.06} />
          </View>
          <View style={styles.viewEmail}>
            <Text style={[styles.t_description, { fontSize: baseWidth * 0.017}]}>
            We hope you are enjoying your time playing our games and as we love to give our users lots of bonuses and rewards we have applied Tier rewards for all of you.{"\n\n"}
            There are plenty of games for you to choose from to play for free. As a social gaming platform these games will not give you real money or prizes.{"\n\n"}
            Tier Rewards is our way of giving back for your spend. This feature is enabled on all accounts and you will automatically move up a tier depending on your account activity. 
            </Text>
          </View>
          <Button
            onPress={onPress}
            style={styles.btnSubmit}
            width={baseWidth * 0.12}
            height={baseWidth * 0.05}
            label="READ MORE"
            labelStyle={{fontSize: baseWidth * 0.012}}
          />
        </View>
      </View>
    </View>
  );
};

export default TierLevelReward;
