import React from "react";
import { selectedSettingsStatus } from "@src/ducks/slices/settings.slice";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useSettingsService } from "@src/ducks/hooks";
import { ResponsiveContext } from "../provider";
import { ImageButton } from "components/button";
import images from "@assets/images";

const Settings = () => {
  const { onChangeSettings }  = useSettingsService();
  const { baseHeight } = React.useContext(ResponsiveContext);
  const hasSettingsOpen = useAppSelector(selectedSettingsStatus);
  const onPress = () => onChangeSettings(!hasSettingsOpen);

  return (
    <ImageButton
      onPress={onPress}
      source={images.btn_settings}
      style={[{ width: baseHeight * 0.4248, height: baseHeight * 0.4352 }]}
    />
  );
};

export default React.memo(Settings);
