import { PAYPAL_CLIENT_ID } from "@env";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { StackActions, useNavigation, useRoute } from "@react-navigation/native";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedPurchaseData, selectedPurchaseLoad } from "@src/ducks/slices/lobby.slice";
import { Loading01 } from "components/loading";
import _ from "lodash";
import React, { useEffect } from "react";
import { ScrollView, useWindowDimensions } from "react-native";
import { PROMOTION_POPUPS } from "utils/constants";
import { CoinContext } from "../home/main/CoinProvider";
import ApcopayButton from "./apcopay-button";
import BitpaceButton from "./bitpace/bitpace-button";
import PaypalButtons from "./paypal-buttons";
import { PaymentContext } from "./provider";

const initialOptions: any = {
  currency: "USD",
  intent: "capture",
  "client-id": PAYPAL_CLIENT_ID,
};

const PaymentOption = () => {
  const route = useRoute<any>();
  const price = route?.params?.price;
  const navigation = useNavigation<any>();
  const { width } = useWindowDimensions();
  const { loading } = React.useContext(PaymentContext);
  const isLoading = useAppSelector(selectedPurchaseLoad);
  const paypalSuccess = useAppSelector(selectedPurchaseData);
  const { onAdditionalCurrency } = React.useContext(CoinContext);

  useEffect(() => {
    if (!_.isEmpty(paypalSuccess)) {
      if (route?.params?.name === PROMOTION_POPUPS.JADE_EGG) {
        navigation.goBack();
      } else {
        navigation.dispatch(StackActions.replace("PaymentConfirm", route?.params));
        onAdditionalCurrency({
          silver: route?.params?.value ?? route?.params?.rewards, 
          bg: route?.params?.goldBonus ?? 0, 
          gwz: 0
        });
      }
    }
  }, [paypalSuccess]);

  return (
    <>
      <ScrollView style={{ flex: 1, width: width * 0.5 }}>
        {price > 10 && <BitpaceButton />}
        <ApcopayButton />
        <PayPalScriptProvider options={initialOptions}>
          <PaypalButtons />
        </PayPalScriptProvider>
      </ScrollView>
      <Loading01 isLoading={isLoading || loading} color="black" />
    </>
  );
};

export default React.memo(PaymentOption);
