import { CDN_URL } from "@env";
import { TierLevelUpTypes } from "../types/tier-level-up-types";

const EmeraldAssets: TierLevelUpTypes | any = {
  // BRONZE
  "img_0": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/img_0.png",
  "img_1": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/img_1.png",
  "img_2": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/img_2.png",
  "img_3": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/img_3.png",
  "img_4": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/img_4.png",
  "img_5": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/img_5.png",
  "img_6": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/img_6.png",
  "img_7": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/img_7.png",
  "img_8": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/img_8.png",
  "img_9": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/img_9.png",
  "img_10": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/img_10.png",
  "img_11": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/img_11.png",
  "img_12": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/img_12.png",
  "img_13": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/img_13.png",
  "img_14": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/img_14.png",
  "img_15": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/img_15.png",
  "img_16": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/img_16.png",
  "img_17": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/img_17.png",
  "img_18": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/img_18.png",
  "img_19": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/img_19.png",

  "seq_0_0": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/seq_0_0.png",
  "seq_0_1": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/seq_0_1.png",
  "seq_0_2": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/seq_0_2.png",
  "seq_0_3": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/seq_0_3.png",
  "seq_0_4": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/seq_0_4.png",
  "seq_0_5": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/seq_0_5.png",
  "seq_0_6": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/seq_0_6.png",
  "seq_0_7": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/seq_0_7.png",
  "seq_0_8": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/seq_0_8.png",
  "seq_0_9": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/seq_0_9.png",
  "seq_0_10": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/seq_0_10.png",
  "seq_0_11": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/seq_0_11.png",
  "seq_0_12": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/seq_0_12.png",
  "seq_0_13": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/seq_0_13.png",
  "seq_0_14": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/seq_0_14.png",
  "seq_0_15": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/seq_0_15.png",
  "seq_0_16": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/seq_0_16.png",
  "seq_0_17": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/seq_0_17.png",
  "seq_0_18": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/seq_0_18.png",
  "seq_0_19": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/seq_0_19.png",
  "seq_0_20": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/seq_0_20.png",
  "seq_0_21": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/seq_0_21.png",
  "seq_0_22": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/seq_0_22.png",
  "seq_0_23": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/seq_0_23.png",
  "seq_0_24": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/seq_0_24.png",
  "seq_0_25": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/seq_0_25.png",
  "seq_0_26": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/seq_0_26.png",
  "seq_0_27": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/seq_0_27.png",
  "seq_0_28": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/seq_0_28.png",
  "seq_0_29": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/seq_0_29.png",
  "seq_0_30": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/seq_0_30.png",
  "seq_0_31": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/seq_0_31.png",
  "seq_0_32": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/seq_0_32.png",
  "seq_0_33": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/seq_0_33.png",
  "seq_0_34": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/seq_0_34.png",
  "seq_0_35": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/seq_0_35.png",
  "seq_0_36": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/seq_0_36.png",
  "seq_0_37": CDN_URL + "/image-assets/animation-assets/tier-level-up/emerald/images/seq_0_37.png",

};

export default EmeraldAssets;
