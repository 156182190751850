import React from "react";
import { View, Image, Text } from "react-native";
import { useNavigation, useRoute, CommonActions } from "@react-navigation/native";
import { selectAuthLoggedIn } from "@src/ducks/slices/auth.slice";
import { useAppSelector } from "@src/ducks/ducksHook";
import Icon from "@expo/vector-icons/MaterialIcons";
import { ImageButton } from "components/button";
import { Scale } from "utils/animation";
import useStyles from "./styles.css";
import images from "@assets/images";

const ApcopayResult = () => {
  const styles = useStyles();
  const route = useRoute<any>();
  const status = route.params?.status;
  const isSuccess = status === "success";
  const navigation = useNavigation<any>();
  const isLoggedIn = useAppSelector(selectAuthLoggedIn);
  const label = isSuccess ? "PAYMENT SUCCESSFUL\nThank you" : "PAYMENT ERROR!";

  const onClose = () => {
    navigation.dispatch(
      CommonActions.reset({
        index: 1,
        routes: [
          { name: isLoggedIn ? "Home" : "SignInOption" },
        ],
      })
    );
  };

  return (
    <View style={styles.container}>
      <Scale style={styles.container}>
        <View style={styles.center_view}>
          <View style={styles.v_top}>
            <Image style={styles.image_logo} 
              source={images["apcopay-logo"]} resizeMode="contain" />
          </View>
          <View style={styles.v_bottom}>
            <Text style={styles.t_label}>{label}</Text>
            <View style={styles.v_circle}>
              <Icon name={isSuccess ? "check" : "close"} color={isSuccess ? "white" : "red"} size={80} />
            </View>
          </View>
          <ImageButton
            onPress={onClose}
            style={styles.btn_close}
            source={images.btn_close}
          />
        </View>
      </Scale>
    </View>
  );
};

export default ApcopayResult;
