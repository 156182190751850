import { CDN_URL } from "@env";
import { BackgroundTypes } from "./types/jade-egg/background-types";

const JadeEggBGAssets: BackgroundTypes = {
  "img_0": CDN_URL + "/image-assets/animation-assets/jade-egg/background/images/img_0.png",
  "img_1": CDN_URL + "/image-assets/animation-assets/jade-egg/background/images/img_1.png",
  "img_2": CDN_URL + "/image-assets/animation-assets/jade-egg/background/images/img_2.png",
  "img_3": CDN_URL + "/image-assets/animation-assets/jade-egg/background/images/img_3.png",
  "img_4": CDN_URL + "/image-assets/animation-assets/jade-egg/background/images/img_4.png",
  "img_5": CDN_URL + "/image-assets/animation-assets/jade-egg/background/images/img_5.png",
  "img_6": CDN_URL + "/image-assets/animation-assets/jade-egg/background/images/img_6.png",
  "img_7": CDN_URL + "/image-assets/animation-assets/jade-egg/background/images/img_7.png",
  "img_8": CDN_URL + "/image-assets/animation-assets/jade-egg/background/images/img_8.png",
  "img_9": CDN_URL + "/image-assets/animation-assets/jade-egg/background/images/img_9.webp",
};

export default JadeEggBGAssets;
