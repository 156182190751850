import React from "react";
import { View, Image } from "react-native";
import { useNavigation, useRoute, CommonActions } from "@react-navigation/native";
import { ImageButton } from "components/button";
import { Scale } from "utils/animation";
import useStyles from "./styles.css";
import images from "@assets/images";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectAuthLoggedIn } from "@src/ducks/slices/auth.slice";

const BitpacePopupResult = () => {
  const styles = useStyles();
  const route = useRoute<any>();
  const status = route.params?.status;
  const navigation = useNavigation<any>();
  const isLoggedIn = useAppSelector(selectAuthLoggedIn);

  const onClose = () => {
    navigation.dispatch(
      CommonActions.reset({
        index: 1,
        routes: [
          { name: isLoggedIn ? "Home" : "SignInOption" },
        ],
      })
    );
  };

  return (
    <View style={styles.container}>
      <Scale style={styles.container}>
        <View style={styles.center_view}>
          <Image
            style={styles.i_background}
            source={
              status === "success"
                ? images.bitpace_success
                : images.bitpace_error
            }
            resizeMode="stretch"
          />
          <ImageButton
            onPress={onClose}
            style={styles.btn_close}
            source={images.btn_close}
          />
        </View>
      </Scale>
    </View>
  );
};

export default BitpacePopupResult;
