import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      width: "9%",
      height: "53%",
      alignItems: "center",
      marginHorizontal: "3%",
      justifyContent: "center",
    },
    size_egg: {
      width: "100%",
      height: "100%",
    },
  },
  {
    [maxSize(DEVICE_SIZES.MD)]: {
      container: {
        width: "10%",
        height: "50%",
      },
    },
  }
);

export default useStyles;
