import React from "react";
import { ImageButton05 } from "components/button";
import SVGSettings from "@assets/svg/settings";
import { View, Linking } from "react-native";
import useStyles from "./styles.css";

const Terms = ({baseWidth, baseHeight}: any) => {
  const styles = useStyles();
  const onPolicy = () => Linking.openURL("https://fortune8.online/privacy-policy");
  const onTerms = () => Linking.openURL("https://fortune8.online/web-terms-%2B-conditions");

  return (
    <View style={styles.v_terms}>
      <ImageButton05 scaleSize={0.98} onPress={onTerms} >
        <SVGSettings name="btn-terms-condition" width={baseWidth * 0.5175} height={baseHeight * 0.083} />
      </ImageButton05>
      <ImageButton05 scaleSize={0.98} onPress={onPolicy} >
        <SVGSettings name="btn-privacy-policy" width={baseWidth * 0.3828} height={baseHeight * 0.083} />
      </ImageButton05>
    </View>
  );
};

export default Terms;
