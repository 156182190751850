import { CreateResponsiveStyle, DEVICE_SIZES } from "rn-responsive-styles";
import { colors } from "@src/theme";
import { StyleSheet } from "react-native";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      zIndex: 6,
      alignItems: "center",
    },
    v_opacity: {
      width: "100%",
      height: "100%",
      position: "absolute",
    },
    v_dropin: {
      zIndex: 3,
      width: "80%",
      bottom: "1%",
      minHeight: "20%",
      maxHeight: "80%",
      position: "absolute",
      alignItems: "center",
      backgroundColor: colors.white,
    },
    v_spinner: {
      zIndex: 10,
      width: "100%",
      height: "100%",
      
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.white,
      ...StyleSheet.absoluteFillObject
    },
    indicator: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      position: "absolute",
    },

    // NEW WINDOW PAYMENT
    window_container: {width: "100%", height: "100%",minHeight: 400,
      alignItems: "center", justifyContent: "center", backgroundColor: "black"},
    btn_close: {position: "absolute", top: 7, right: 7},
    window_t_payment: {fontFamily: "Roboto-Bold", fontSize: 30, color: "white", textAlign: "center"},
    window_t_notes: {fontFamily: "Roboto", fontSize: 14, color: "white", textAlign: "center", marginVertical: 30},
    window_t_click: {fontFamily: "Roboto-Bold", fontSize: 16, color: "white", textAlign: "center", textDecorationLine: "underline"},
  },
  {
    [DEVICE_SIZES.SM]: {},
    [DEVICE_SIZES.XS]: {},
  }
);

export default useStyles;