import React from "react";
import { NavigationScreenProps } from "@src/navigators/NavigatorTypes";
import { selectAuthLoggingIn } from "@src/ducks/slices/auth.slice";
import { DimensionContext } from "@src/DimensionProvider";
import { useNavigation } from "@react-navigation/native";
import { ImageButton02 } from "@src/components/button";
import { useAppSelector } from "@src/ducks/ducksHook";
import { Loading01 } from "@src/components/loading";
import { useAuthService } from "@src/ducks/hooks";
import { View, Text, Image } from "react-native";
import useStyles from "./styles.css";
import images from "@assets/images";
import Facebook from "./Facebook";
import Google from "./Google";

const SignInOption = () => {
  const styles = useStyles();
  const { onResetLoading } = useAuthService();
  const { baseWidth } = React.useContext(DimensionContext);
  const isLoggingIn = useAppSelector(selectAuthLoggingIn);
  const navigation = useNavigation<NavigationScreenProps<"SignIn"| "SignUp">>();

  React.useEffect(() => {
    onResetLoading();
  }, []);

  return (
    <View style={styles.container}>
      <Image
        source={images.gw_bg}
        style={[styles.i_background]}
        resizeMode="stretch"
      />
      <View style={styles.container}>
        <View style={[styles.bodyStyle, { height: baseWidth * 0.3004}]}>
          <Image
            source={images.bg_transparent}
            style={styles.imageBorderStyle}
            resizeMode="stretch"
          />
          <Image
            source={images.fortune_logo}
            style={styles.smallLogoContainer}
            resizeMode="stretch"
          />
          <View style={styles.heightTop} />
          <View style={styles.viewTitle}>
            <Image
              source={images.mainCreateAccountTitle}
              style={styles.imageTitle}
              resizeMode="contain"
            />
          </View>
          <View style={styles.viewCenter}>
            <View style={[styles.v_social_login, { height: baseWidth * 0.051}]}>
              <Google />
              <Facebook />
            </View>
            <View style={[styles.separator, { height: baseWidth * 0.05}]}>
              <View style={styles.divider} />
              <Text style={[styles.txtOrstyle, { fontSize: baseWidth * 0.011}]}>Or</Text>
              <View style={styles.divider} />
            </View>
            <View style={[styles.v_social_login, { height: baseWidth * 0.051}]}>
              <ImageButton02
                style={styles.btn_reg_email}
                onPress={() => navigation.navigate("SignUp")} >
                <Image
                  style={styles.imageStyle}
                  source={images.mainRegisterEmailButton}
                  resizeMode="stretch"
                />
              </ImageButton02>
              <ImageButton02
                style={styles.btn_reg_email}
                onPress={() => navigation.navigate("SignIn")} >
                <Image
                  style={styles.imageStyle}
                  source={images.main_login_button}
                  resizeMode="stretch"
                />
              </ImageButton02>
            </View>
          </View>
          <Loading01 isLoading={isLoggingIn} />
        </View>
      </View>
    </View>
  );
};

export default SignInOption;
