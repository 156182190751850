import SVGShop from "@assets/svg";
import { DimensionContext } from "@src/DimensionProvider";
import { ImageButton05 } from "components/button";
import _ from "lodash";
import React, { useMemo } from "react";
import { View } from "react-native";
import { useInboxContext } from "../provider";
import useStyles from "./styles.css";
import TopPlace from "./top-place";
import Welcome from "./welcome";

const Details = () => {
  const styles = useStyles();
  const { details, onClose } = useInboxContext();
  const { baseWidth } = React.useContext(DimensionContext);

  const RenderItem = useMemo(() => {
    const subject = (details?.subject ?? "").toLowerCase();
    if (
      subject.includes("place first") ||
      subject.includes("place second") ||
      subject.includes("place third")
    ) {
      return <TopPlace {...details} />;
    } else if(subject.includes("welcome")){
      return <Welcome />;
    }
  }, [details]);

  if (_.isEmpty(details)) {
    return null;
  }

  return (
    <View style={styles.details_container}>
      <ImageButton05
        onPress={onClose}
        style={[styles.btn_close]}
      >
        <SVGShop name="round-close" width={baseWidth * 0.038} height={baseWidth * 0.04}/>
      </ImageButton05>
      <View style={styles.v_center}>
        {RenderItem}
      </View>
    </View>
  );
};

export default Details;
