import { CreateResponsiveStyle, DEVICE_SIZES } from "rn-responsive-styles";
import { StyleSheet, Platform } from "react-native";
import { colors } from "@src/theme";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      zIndex: 6,
      alignItems: "center",
      justifyContent: "center",
      ...StyleSheet.absoluteFillObject,
      backgroundColor: colors.translucent,
    },
    v_bodystyle: {
      width: "55%",
      height: "70%",
      alignItems: "center",
      position: "absolute",
      justifyContent: "center",
    },
    i_background: {
      width: "100%",
      height: "100%",
      position: "absolute",
    },
    t_silver_coin: {
      zIndex: 2,
      ...Platform.select({
        web: {
          fontSize: "3.7vh",
        },
        default: {
          fontSize: 25,
        },
      }),
      bottom: "42.2%",
      color: "white",
      position: "absolute",
      fontFamily: "Roboto",
    },
    btn_collect: {
      width: "25%",
      height: "13%",
      bottom: "7%",
      position: "absolute",
    },
  },
  {
    [DEVICE_SIZES.MD]: {
      v_bodystyle: {
        height: "80%",
      },
      t_silver_coin: {
        fontSize: 18,
        bottom: "41.7%",
      },
      btn_collect: {
        height: "16%",
        bottom: "8%",
      },
    },
    [DEVICE_SIZES.SM]: {
      v_bodystyle: {
        height: "75%",
      },
      t_silver_coin: {
        fontSize: 17,
        bottom: "41.4%",
      },
      btn_collect: {
        height: "16%",
        bottom: "8%",
      },
    },
    [DEVICE_SIZES.XS]: {
      v_bodystyle: {
        height: "75%",
      },
      t_silver_coin: {
        fontSize: 17,
        bottom: "41.7%",
      },
      btn_collect: {
        height: "16%",
        bottom: "8%",
      },
    },
  }
);

export default useStyles;
