import React from "react";
import { ImageBackground } from "react-native";
import { selectResetPasswordSuccess } from "@src/ducks/slices/forgot.slice";
import { useAppSelector } from "@src/ducks/ducksHook";
import ResetPassword from "./ResetPassword";
import useStyles from "./styles.css";
import images from "@assets/images";
import Success from "./Success";

const Forgot = () => {
  const styles = useStyles();
  const isResetPasswordSuccess = useAppSelector(selectResetPasswordSuccess);

  return (
    <ImageBackground
      source={images.gw_bg}
      style={[styles.container]}
      resizeMode="stretch"
    >
      {isResetPasswordSuccess ? <Success /> : <ResetPassword />}
    </ImageBackground>
  );
};

export default Forgot;
