import images from "@assets/images";
import SVGShop from "@assets/svg";
import { useNavigation } from "@react-navigation/native";
import { useDimensionContext } from "@src/DimensionProvider";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedNotifications } from "@src/ducks/slices/lobby.slice";
import { ImageButton02, ImageButton06 } from "components/button";
import _ from "lodash";
import React from "react";
import { Image, View } from "react-native";
import InboxDetails from "../details";
import DefaultDetails from "../details/default";
import { useInboxContext } from "../provider";
import InboxList from "./InboxList";
import useStyles from "./styles.css";

const InboxScreen = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const { details, onClose } = useInboxContext();
  const { baseWidth, maxHeight } = useDimensionContext();
  const notifications = useAppSelector(selectedNotifications);

  const isHotDeals = React.useMemo(() => {
    return details?.notificationTypeId === 4;
  },[details?.notificationTypeId]);

  const handleClose = () => {
    if(isHotDeals){
      onClose();
    }else{
      navigation.pop();
    }
  };

  return (
    <View style={[styles.container ]}>
      <ImageButton02
        onPress={handleClose}
        style={[styles.btn_close, { width: baseWidth * 0.031, height: baseWidth * 0.031 }]} >
        <SVGShop name="round-close" width={baseWidth * 0.031} height={baseWidth * 0.031}/>
      </ImageButton02>
      <View
        style={[
          styles.center_view,
          { width: baseWidth * 0.6010, height: maxHeight * 0.63 },
          { display: isHotDeals ? "none" : "flex"}
        ]}
      >
        <Image
          style={styles.i_background}
          source={{uri: images["bg-inbox"]}}
          resizeMode="stretch"
        />
        <View style={[styles.v_inbox, { width: baseWidth * 0.13, height: baseWidth * 0.03}]}>
          <Image
            style={[styles.i_inbox]}
            source={{uri: images["t-inbox-title"]}}
            resizeMode="stretch"
          />
          <Image
            style={styles.i_message}
            source={{uri: images.btn_mail}}
            resizeMode="stretch"
          />
        </View>
        <InboxList />
        <InboxDetails />
        {_.isEmpty(details) && 
        <ImageButton06
          label="DELETE ALL"
          style={styles.btn_delete_all}
          disabled={_.isEmpty(notifications)}
          source={images["btn-delete-all-inbox"]}
          onPress={() => navigation.navigate("delete-all-inbox")}
          labelStyle={{fontSize: baseWidth * 0.01}} />}
      </View>

      {isHotDeals && <View
        style={[
          styles.center_view,
          { width: baseWidth * 0.5341, height: maxHeight * 0.5565 },
        ]}
      >
        <DefaultDetails {...details}/>
      </View>}
    </View>
  );
};

export default InboxScreen;
