import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { CommonActions, useNavigation, useRoute } from "@react-navigation/native";
import { DimensionContext } from "@src/DimensionProvider";
import { useSignupService } from "@src/ducks/hooks";
import images from "@assets/images";
import { Button } from "components/button";
import { Loading01 } from "components/loading";
import React from "react";
import { Image, ImageBackground, Text, View } from "react-native";
import { accountVerify, getAuth } from "utils/api";
import SignUpVerificationFailed from "./signup-verification-failed";
import useStyles from "./styles.css";

const SignUpVerification = () => {
  const styles = useStyles();
  const route = useRoute<any>();
  const sendDataToGTM = useGTMDispatch();
  const navigation = useNavigation<any>();
  const { onSignupReset } = useSignupService();
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const { baseWidth } = React.useContext(DimensionContext);

  const onPress = () => {
    navigation.dispatch(
      CommonActions.reset({
        index: 1,
        routes: [
          { name: "SignInOption" },
        ],
      })
    );
    onSignupReset();
  };

  const callVerify = async() => {
    try {
      setLoading(true);
      const token = await getAuth();
      const params = {
        "userId": route.params.userid,
        "verifyCode": route.params.code
      };
      const result = await accountVerify(params, token.token);
      setLoading(false);
      setData(result);
    } catch (error: any) {
      console.log("error", error);
      setLoading(false);
      
      if(typeof error === "string"){
        setError(error);
      }else if(error.message){
        setError(error.message);
      }else if(error.error && typeof error.error === "object" && error.error.code){
        if(error.error.code === "User already verified"){
          setData(error.error);
        }else{
          setError(error.error.code);
        }
      }else if(error.error && typeof error.error === "object" && error.error.message){
        if(error.error.message === "User already verified"){
          setData(error.error);
        }else{
          setError(error.error.message);
        }
      }else if(error.error && typeof error.error === "string"){
        setError(error.error);
      }else{
        setError("Something went wrong");
      }
    }
  };

  React.useEffect(() => {
    callVerify();
  }, [route.params]);

  React.useEffect(() => {
    sendDataToGTM({
      event: "signup_completed",
      value: "success",
    });
  }, []);

  return (
    <ImageBackground
      source={images.gw_bg}
      style={[styles.container]}
      resizeMode="stretch"
    >
      <View style={[styles.v_completed, { height: baseWidth * 0.3349}]}>
        <Image
          source={images.bg_transparent}
          style={styles.imageBorderStyle}
          resizeMode="stretch"
        />
        {loading && <Loading01 isLoading /> }
        {error && <SignUpVerificationFailed />}
        {data && <View style={styles.viewEmail}>
          <Image
            resizeMode="contain"
            source={images["success"]}
            style={styles.i_success}
          />
          <View style={{ height: "5%"}} />
          <Image
            style={{width: "12%", height: "22%"}}
            source={images["verify-success"]}
            resizeMode="stretch"
          />
          <View style={{ height: "5%"}} />
          <Text style={[styles.t_thankyou, {fontSize: baseWidth * 0.017}]}>You have successfully verified your email.</Text>
          <Button
            onPress={onPress}
            style={styles.btn_login}
            width={baseWidth * 0.12}
            height={baseWidth * 0.05}
            label="CONTINUE"
            labelStyle={{fontSize: baseWidth * 0.012}}
          />
        </View>}
      </View>
    </ImageBackground>
  );
};

export default SignUpVerification;
