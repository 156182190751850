import * as Font from "expo-font";

// fonts preloading
export const fontAssets = [
  {
    "ABeeZee-Regular": require("./ABeeZee-Regular.ttf"),
  },{
    "Roboto": require("./Roboto-Regular.ttf"),
  },{
    "Roboto-Light": require("./Roboto-Light.ttf"),
  },{
    "Roboto-Medium": require("./Roboto-Medium.ttf"),
  },{
    "Roboto-Bold": require("./Roboto-Bold.ttf"),
  },{
    "BebasNeue-Regular": require("./BebasNeue-Regular.ttf"),
  },{
    "Lalezar-Regular": require("./Lalezar-Regular.ttf"),
  },{
    "Risque-Regular": require("./Risque-Regular.ttf"),
  },
].map((x: any) => Font.loadAsync(x));
