import { CDN_URL } from "@env";
import { RandomSlotTypes } from "../types/daily-bonus/random-slot-types";

const RandomSlotAssets: RandomSlotTypes = {
  "img_0_01": CDN_URL + "/image-assets/animation-assets/daily-bonus/random-slot/images/img_0_01.png",
  "img_0_03": CDN_URL + "/image-assets/animation-assets/daily-bonus/random-slot/images/img_0_03.png",
  "img_0_06": CDN_URL + "/image-assets/animation-assets/daily-bonus/random-slot/images/img_0_06.png",
  "img_0_07": CDN_URL + "/image-assets/animation-assets/daily-bonus/random-slot/images/img_0_07.png",
  "img_0_08": CDN_URL + "/image-assets/animation-assets/daily-bonus/random-slot/images/img_0_08.png",
  "img_0_09": CDN_URL + "/image-assets/animation-assets/daily-bonus/random-slot/images/img_0_09.png",
  "img_0_11": CDN_URL + "/image-assets/animation-assets/daily-bonus/random-slot/images/img_0_11.png",
  "img_0_88": CDN_URL + "/image-assets/animation-assets/daily-bonus/random-slot/images/img_0_88.png",
  "img_1": CDN_URL + "/image-assets/animation-assets/daily-bonus/random-slot/images/img_1.png",
  "img_2": CDN_URL + "/image-assets/animation-assets/daily-bonus/random-slot/images/img_2.png",
  "img_3": CDN_URL + "/image-assets/animation-assets/daily-bonus/random-slot/images/img_3.png",
  "img_4": CDN_URL + "/image-assets/animation-assets/daily-bonus/random-slot/images/img_4.png",
  "img_5": CDN_URL + "/image-assets/animation-assets/daily-bonus/random-slot/images/img_5.png",
  "img_6": CDN_URL + "/image-assets/animation-assets/daily-bonus/random-slot/images/img_6.png",
  "img_7": CDN_URL + "/image-assets/animation-assets/daily-bonus/random-slot/images/img_7.png",
  "img_8": CDN_URL + "/image-assets/animation-assets/daily-bonus/random-slot/images/img_8.png",
  "img_9": CDN_URL + "/image-assets/animation-assets/daily-bonus/random-slot/images/img_9.png",
  "img_10": CDN_URL + "/image-assets/animation-assets/daily-bonus/random-slot/images/img_10.png",
  "img_11": CDN_URL + "/image-assets/animation-assets/daily-bonus/random-slot/images/img_11.png",
  "img_12": CDN_URL + "/image-assets/animation-assets/daily-bonus/random-slot/images/img_12.png",
  "img_13": CDN_URL + "/image-assets/animation-assets/daily-bonus/random-slot/images/img_13.png",
  "img_14": CDN_URL + "/image-assets/animation-assets/daily-bonus/random-slot/images/img_14.png",
  "img_15": CDN_URL + "/image-assets/animation-assets/daily-bonus/random-slot/images/img_15.png",
  "img_16": CDN_URL + "/image-assets/animation-assets/daily-bonus/random-slot/images/img_16.png",
  "img_17": CDN_URL + "/image-assets/animation-assets/daily-bonus/random-slot/images/img_17.png",
  "img_18": CDN_URL + "/image-assets/animation-assets/daily-bonus/random-slot/images/img_18.png",
  "img_19": CDN_URL + "/image-assets/animation-assets/daily-bonus/random-slot/images/img_19.png",
  "img_20": CDN_URL + "/image-assets/animation-assets/daily-bonus/random-slot/images/img_20.png",
  "img_21": CDN_URL + "/image-assets/animation-assets/daily-bonus/random-slot/images/img_21.webp",
};

export default RandomSlotAssets;
