import React, { Dispatch, SetStateAction, createContext, useCallback, useContext, useState } from "react";

export interface DetailsProps {
  subject: string;
  status: string;
  textValues: string;
  notificationTypeId?: number;
}

interface InboxContextValue {
  details: DetailsProps;
  onClose: () => void;
  setDetails: Dispatch<SetStateAction<DetailsProps>>;
}

export const InboxContext = createContext<InboxContextValue>({} as InboxContextValue);

interface InboxProviderProps {
  children: React.ReactElement;
}

const InboxProvider = ({ children }: InboxProviderProps) => {
  const [details, setDetails] = useState<any>({});

  const onClose = useCallback(() => {
    setDetails({});
  }, []);

  return (
    <InboxContext.Provider value={{ details, setDetails, onClose }} >
      {children}
    </InboxContext.Provider>
  );
};

export const useInboxContext = () => {
  return useContext(InboxContext);
};

export default InboxProvider;
