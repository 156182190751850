import { CreateResponsiveStyle } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      width: "25%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    size_settings: {
      width: "100%",
      height: "100%",
    },
  },
);

export default useStyles;
