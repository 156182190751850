import images from "@assets/images";
import { useLobbyService } from "@src/ducks/hooks";
import { ImageButton } from "components/button";
import React from "react";
import Animated, {
  Extrapolate,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import { ResponsiveContext } from "../provider";
import useStyles from "./styles.css";

const LeaderBoard = () => {
  const styles = useStyles();
  const animated = useSharedValue(0);
  const { onChangeScreen } = useLobbyService();
  const onPress = () =>  onChangeScreen({ screen: "leaderboard" });
  const { baseWidth, baseHeight } = React.useContext(ResponsiveContext);

  const animateStyle = useAnimatedStyle(() => {
    const translateX = interpolate(
      animated.value,
      [0, 1],
      [0, baseWidth * 0.07],
      Extrapolate.CLAMP
    );

    return {
      transform: [{ translateX }],
    };
  }, [baseWidth]);

  React.useEffect(() => {
    animated.value = withTiming(1, { duration: 1000});
  }, []);

  return (
    <Animated.View style={animateStyle}>
      <ImageButton
        scaleSize={0.97}
        onPress={onPress}
        activeOpacity={1}
        source={images.l_logo}
        style={[styles.silverCoin, { width: baseHeight * 0.5181, height: baseHeight * 0.5181 }]}
      />
    </Animated.View>
  );
};

export default LeaderBoard;
