import { CreateResponsiveStyle } from "rn-responsive-styles";
import { colors } from "@src/theme";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      position: "absolute",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    v_timer: {
      borderRadius: 50,
      alignItems: "center",
      justifyContent: "center", 
      backgroundColor: colors.black2
    },
    goldBar: {
      width: "85%",
      height: "44%",
    },
    btnCollect: {
      zIndex: 3,
      left: "-17%",
      width: "40%",
      height: "86%",
      position: "absolute",
    },
    indicator: {
      zIndex: 2,
      marginRight: "5%",
      position: "absolute",
    },
    imageCollect: {
      width: "100%",
      height: "100%",
    },
    goldText: {
      lineHeight: 24,
      color: colors.white,
      fontFamily: "Roboto-Bold",
    },
  }
);

export default useStyles;
