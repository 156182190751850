import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedProfileStatus } from "@src/ducks/slices/settings.slice";
import React, { useContext } from "react";
import { StyleSheet } from "react-native";
import HeightWithTransition from "utils/animation/HeightWithTransition";
import { AvatarContext } from "./AvatarProvider";
import AvatarList from "./avatarlist";
import Profile from "./profile";
import { DimensionContext } from "@src/DimensionProvider";

const Avatars = () => {
  const { showScreen } = useContext(AvatarContext);
  const { height } = useContext(DimensionContext);
  const hasProfileOpen =  useAppSelector(selectedProfileStatus);

  return (
    <HeightWithTransition
      screenHeight={height}
      style={styles.container} 
      toValue={hasProfileOpen ? 1 : 0}>
      {(showScreen === "avatar" && <AvatarList />) || <Profile />}
    </HeightWithTransition>
  );
};

const styles = StyleSheet.create({
  container: {
    top: 0,
    zIndex: 5,
    width: "100%",
    height: "100%",
    overflow: "hidden",
    position: "absolute",
  },
});

export default Avatars;
