import React, { useEffect } from "react";
import Animated, {
  Extrapolation,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";

const HeightWithTransition = ({ children, style, toValue, duration, screenHeight }: any) => {
  const animated = useSharedValue(0);

  useEffect(() => {
    animated.value = withTiming(toValue, { duration: duration || 250 });
  }, [toValue]);

  const animateStyle = useAnimatedStyle(() => {
    const translateY = interpolate(
      animated.value,
      [0, 1],
      [-screenHeight, 0],
      Extrapolation.CLAMP
    );

    return { 
      transform: [{translateY}],
    };
  }, [screenHeight]);

  return (
    <Animated.View style={[style, animateStyle]}>{children}</Animated.View>
  );
};

export default HeightWithTransition;
