import images from "@assets/images";
import { useDimensionContext } from "@src/DimensionProvider";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedNotifications } from "@src/ducks/slices/lobby.slice";
import _ from "lodash";
import React from "react";
import { FlatList, Image, View } from "react-native";
import { useInboxContext } from "../provider";
import InboxItem from "./InboxItem";
import useStyles from "./styles.css";

const InboxList = () => {
  const styles = useStyles();
  const { baseWidth } = useDimensionContext();
  const { details, setDetails } = useInboxContext();
  const notifications = useAppSelector(selectedNotifications);

  return (
    <View style={[styles.v_body, { display: _.isEmpty(details) ? "flex" : "none"}]}>
      <FlatList
        data={notifications}
        numColumns={3}
        showsVerticalScrollIndicator={false}
        style={[styles.flatlist]}
        ListEmptyComponent={
          <Image source={{uri: images["t-inbox-empty"]}} 
            style={{width: "60%", height: baseWidth * 0.03, top: baseWidth * 0.07, alignSelf: "center", position: "absolute"}} resizeMode="stretch" />
        }
        ItemSeparatorComponent={() => <View style={{height: baseWidth * 0.017}} />}
        keyExtractor={(_, index: number) => index.toString()}
        renderItem={({ item, index }: any) => {
          return <InboxItem key={index.toString()} {...{ item, detailsChange: setDetails }} />;
        }}
      />
    </View>
  );
};

export default React.memo(InboxList);
