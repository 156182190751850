type PromotionPopups = {
  // HOT DEALS
    STARTER_PACK: string;
    CHEAPY_TUESDAY: string;
    WEEKLY_BOOSTER: string;
    HIGH_ROLLER: string;
    TGIF: string;
    DRAGON_ROLLER: string;

  // JADE EGG
    JADE_EGG: string;

  // PREMIUM PACKAGES
    PREMIUM_PACK1: string;
    PREMIUM_PACK2: string;
    PREMIUM_PACK3: string;

    // BUY SHOP
    PACKAGE_1: string;
    PACKAGE_2: string;
    PACKAGE_3: string;
    PACKAGE_4: string;
    PACKAGE_5: string;
    PACKAGE_6: string;
    SUBSCRIPTION_PACKAGE_1: string;
    SUBSCRIPTION_PACKAGE_2: string;
}

export const PROMOTION_POPUPS: PromotionPopups = {
  // HOT DEALS
  STARTER_PACK: "gwz_3.00_3m_silv",
  CHEAPY_TUESDAY: "gwz_4.00_4m_silv",
  WEEKLY_BOOSTER: "gwz_7.00_8m_silv",
  HIGH_ROLLER: "gwz_11.00_13m_silv",
  TGIF: "gwz_7.00_7m_silv",
  DRAGON_ROLLER: "gwz_20.00_25m_silv",

  // PREMIUM PACKAGES
  PREMIUM_PACK1: "gwz_1.99_1m_silv",
  PREMIUM_PACK2: "gwz_2.99_4.4m_silv",
  PREMIUM_PACK3: "gwz_4.99_10.5m_silv",

  // JADE EGG
  JADE_EGG: "gwz_7.99_jade",

  // BUY SHOP
  PACKAGE_1: "gwz_2.99_2m_silv",
  PACKAGE_2: "gwz_5.99_6m_silv",
  PACKAGE_3: "gwz_10.99_10m_silv",
  PACKAGE_4: "gwz_19.99_20m_silv",
  PACKAGE_5: "gwz_49.99_50m_silv",
  PACKAGE_6: "gwz_74.99_75m_silv",
  SUBSCRIPTION_PACKAGE_1: "gwz_21.99_14d_package_1",
  SUBSCRIPTION_PACKAGE_2: "gwz_54.99_14d_package_2",
};