import { colors } from "@src/theme";
import { CreateResponsiveStyle } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      zIndex: 2,
      width: "90%",
      height: "79%",
      alignItems: "center",
      justifyContent: "center",
    },
    details_container: {
      zIndex: 2,
      width: "97%",
      height: "82%",
      alignItems: "center",
      justifyContent: "center",
    },
    center_view: {
      width: "80%",
      height: "86%",
      alignItems: "center",
      justifyContent: "center",
    },
    i_background: {
      width: "100%",
      height: "100%",
      position: "absolute",
    },
    v_center: {
      width: "96%",
      height: "92%",
      borderRadius: 20,
      alignItems: "center",
    },
    btn_close: {
      top: "5%",
      zIndex: 200,
      width: "5%",
      right: "5%",
      height: "9%",
      position: "absolute",
    },
    v_inbox: {
      top: "-5%",
      width: "15%",
      height: "12%",
      position: "absolute",
      alignSelf: "center",
    },
    i_inbox: {
      width: "100%",
      height: "100%",
    },
    i_message: {
      width: "27%",
      height: "68%",
      position: "absolute",
      top: "-9%",
      right: "-9%",
    },

    // INBOX LIST STYLE
    v_body: {
      zIndex: 2,
      width: "94%",
      height: "100%",
      paddingLeft: "5%",
      paddingRight: "1%",
      justifyContent: "center",
    },
    t_subject: {
      fontFamily: "Roboto-Bold",
      fontSize: 12,
      color: colors.yellow4,
    },
    t_details: {
      marginTop: 10,
      fontFamily: "Roboto-Bold",
      fontSize: 12,
      color: colors.white,
    },
    default_details: {
      width: "100%",
      height: "90%",
      marginTop: "3%",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    v_details: {
      width: "100%",
      height: "80%",
      marginTop: "3%",
    },
    v_info: {
      width: "60%",
      height: "100%",
    },
    v_icon: {
      width: "50%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    i_icon: { width: "100%", height: "100%" },
    v_welcome: {
      width: "50%",
      height: "100%",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    i_welcome_icon: { width: "55%", height: "100%" },
    i_welcome_logo: { width: "40%", height: "80%", position: "absolute", left: "35%", zIndex: 2, top: "26%" },
    i_welcome_girl: { width: "55%", height: "100%", transform: [{rotateY: "180deg"}] },

    // TROPHY
    v_top_body: {
      width: "100%",
      height: "100%",
    },
    v_top_player: {
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    i_trophy: {
      top: "6%",
      width: "20%",
      height: "27%",
    },
    top_info: {
      top: "2%",
      width: "95%",
      height: "40%",
      alignItems: "center",
      justifyContent: "center",
    },
    t_congrats: {
      fontFamily: "Roboto-Bold",
      fontSize: 12,
      color: colors.white,
    },
    t_place: {
      marginTop: "1%",
      fontFamily: "Roboto-Bold",
      fontSize: 12,
      textAlign: "center",
      color: colors.white,
    },
    t_place_color: {
      color: "#F7E96E",
    },

    top_silver: {
      width: "50%",
      height: "27%",
      alignItems: "center",
      justifyContent: "center",
    },
    top_silver_icon: { width: "100%", height: "100%" },
    t_silver_amount: {
      marginTop: "1%",
      marginLeft: "6%",
      fontFamily: "Roboto-Bold",
      fontSize: 30,
      color: colors.white,
      position: "absolute",
    },

    // STARTER PACK
    i_pack: { width: "100%", height: "100%", transform: [{ scale: 1.2 }] },
    btn_buynow: {
      bottom: "2%",
      position: "absolute",
    },

    // TRY GAMES
    i_games: { width: "70%", height: "80%" },
    i_text: { width: "80%", height: "23%" },
    i_avatar: {
      top: "20%",
      width: "30%",
      height: "80%",
      position: "absolute",
      left: 0,
      zIndex: 2,
    },
    btn_playnow: {
      right: "2%",
      bottom: "15%",
      position: "absolute",
    },

    // NEED MORE COINS
    i_needmore: { width: "80%", height: "100%" },
    v_top_row: {
      flexDirection: "row",
      width: "100%",
      height: "25%",
    },
    v_bottom_row: {
      flexDirection: "row",
      width: "100%",
      height: "66%",
      marginTop: "3%",
      alignItems: "center",
      justifyContent: "space-evenly",
    },



    v_default: {
      zIndex: 2,
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    v_action: {
      width: "90%", 
      height: "15%", 
      position: "absolute", 
      bottom: "-10%", 
      alignItems: "center"
    },
    btn_style: { width: "24%", height: "100%"},
    "LEFT": { alignItems: "flex-start" },
    "CENTER": { alignItems: "center" },
    "RIGHT": { alignItems: "center" },
  },
);

export default useStyles;
