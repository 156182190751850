import React from "react";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { useAuthService } from "@src/ducks/hooks";
import { ImageButton } from "components/button";
import useStyles from "./styles.css";
import images from "@assets/images";
import { FB_APP_ID } from "@env";

const Facebook = () => {
  const styles = useStyles();
  const { onSignInWithFB } = useAuthService();

  return (
    <FacebookLogin
      appId={FB_APP_ID}
      initParams={{ version: "v16.0" }}
      fields='name,email,picture,first_name,last_name,gender'
      scope='public_profile, email'
      onSuccess={(res: any) => {
        console.log("resresresres", res);
        onSignInWithFB(res.accessToken);
      }}
      render={({ onClick }: any) => (
        <ImageButton
          onPress={onClick}
          style={styles.buttonStyle}
          source={images.fb_register}
        />
      )}
    />
  );
};

export default React.memo(Facebook);
