import { useLobbyService, useSettingsService } from "@src/ducks/hooks";
import images from "@assets/images";
import { ImageButton } from "components/button";
import React from "react";
import { ResponsiveContext } from "../provider";

const Home = () => {
  const { baseHeight } = React.useContext(ResponsiveContext);
  const { onChangeScreen, onSwitchCategory } = useLobbyService();
  const { onChangeSettings, onChangeProfile }  = useSettingsService();

  const onHome = () => {
    onChangeScreen({ screen: "main" });
    onSwitchCategory(-1);
    onChangeSettings(false);
    onChangeProfile(false);
  };

  return (
    <ImageButton
      onPress={onHome}
      source={images.btn_home}
      style={[{ width: baseHeight * 0.4404, height: baseHeight * 0.445 }]}
    />
  );
};

export default React.memo(Home);
