import { CreateResponsiveStyle } from "rn-responsive-styles";
import { StyleSheet } from "react-native";
import { colors } from "@src/theme";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      zIndex: 3,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      backgroundColor: colors.translucent,
      ...StyleSheet.absoluteFillObject
    },
    view_panel: { height: "100%", },
    image_style: { width: "100%", height: "100%"},
    image_style_right: { width: "100%", height: "100%", transform: [{rotate: "180deg"}]},
    loading_style: { 
      ...StyleSheet.absoluteFillObject, 
      paddingTop: 0, alignItems: "center", 
      justifyContent: "center", zIndex: 2
    },
  }
);

export default useStyles;
