import images from "@assets/images";
import React from "react";
import { Image, View } from "react-native";
import { ResponsiveContext } from "../provider";
import GoldCoins from "./gold-coins";
import GoldDropdown from "./gold-dropdown";
import useStyles from "./styles.css";

const GoldBar = () => {
  const styles = useStyles();
  const { baseWidth, baseHeight } = React.useContext(ResponsiveContext);

  return (
    <View pointerEvents="box-none" style={[styles.container, { 
      width: baseWidth * 0.1427, height: baseHeight * 0.44 }]}>
      <View pointerEvents="none" style={styles.goldBar}>
        <Image
          resizeMode="stretch"
          source={images.gold_bar}
          style={styles.goldBar}
        />
      </View>
      <GoldCoins baseHeight={baseHeight} />
      <GoldDropdown />
    </View>
  );
};

export default GoldBar;
