import { ImageButton06 } from "components/button";
import React from "react";
import { Text, View } from "react-native";
import Facebook from "./Facebook";
import Google from "./Google";
import useStyles from "./styles.css";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectAuthLoggingIn } from "@src/ducks/slices/auth.slice";

interface FooterProps {
  onPress?: () => void;
  baseWidth: number;
}

const Footer = ({ onPress, baseWidth }: FooterProps) => {
  const styles = useStyles();
  const isLoading = useAppSelector(selectAuthLoggingIn);

  return (
    <View style={styles.loginWrapper}>
      <ImageButton06
        onPress={onPress}
        label="LOG IN"
        isLoading={isLoading}
        style={[styles.btnLogin]}
        labelStyle={{fontSize: baseWidth * 0.015}}
      />
      <View style={[styles.separator]}>
        <View style={styles.divider} />
        <Text style={[styles.txtOrstyle, { fontSize: baseWidth * 0.01}]}>Or</Text>
        <View style={styles.divider} />
      </View>
      <View style={styles.v_footer}>
        <Google />
        <Facebook />
      </View>
    </View>
  );
};

export default Footer;
