import React from "react";
import useStyles from "./styles.css";
import { Text } from "react-native";
import numeral from "numeral";

const CountDown = ({ dailyTimer, baseHeight, onDone }: any) => {
  const styles = useStyles();
  const timerId = React.useRef<any>(null);
  const [timer, setTimer] = React.useState(100);

  React.useEffect(() => {
    setTimer(dailyTimer);
  }, [dailyTimer]);

  React.useEffect(() => {
    if (!timer) {
      timerId.current && clearInterval(timerId.current);
      onDone();
      return;
    }

    timerId.current = setInterval(() => {
      setTimer((lastTimerCount) => lastTimerCount - 1);
    }, 1000);

    return () => clearInterval(timerId.current);
  }, [timer]);

  return (
    <Text style={[styles.t_timer, {fontSize: baseHeight * 0.1}]}>
      {numeral(timer).format("00:00:00")}
    </Text>
  );
};

export default CountDown;
