import { CDN_URL } from "@env";
import { SubscriptionTypes } from "../types/buyshop/subscription-types";

const SubscriptionAssets: SubscriptionTypes | any = {
  "img_0": CDN_URL + "/image-assets/animation-assets/buy-shop/subscription/images/img_0.png",
  "img_1": CDN_URL + "/image-assets/animation-assets/buy-shop/subscription/images/img_1.png",
  "img_2": CDN_URL + "/image-assets/animation-assets/buy-shop/subscription/images/img_2.png",
  "img_3": CDN_URL + "/image-assets/animation-assets/buy-shop/subscription/images/img_3.png",
  "img_4": CDN_URL + "/image-assets/animation-assets/buy-shop/subscription/images/img_4.png",
  "img_5": CDN_URL + "/image-assets/animation-assets/buy-shop/subscription/images/img_5.png",
  "img_6": CDN_URL + "/image-assets/animation-assets/buy-shop/subscription/images/img_6.png",
  "img_7": CDN_URL + "/image-assets/animation-assets/buy-shop/subscription/images/img_7.png",
  "img_8": CDN_URL + "/image-assets/animation-assets/buy-shop/subscription/images/img_8.png",
  "img_9": CDN_URL + "/image-assets/animation-assets/buy-shop/subscription/images/img_9.png",
  "img_10": CDN_URL + "/image-assets/animation-assets/buy-shop/subscription/images/img_10.png",
  "img_11": CDN_URL + "/image-assets/animation-assets/buy-shop/subscription/images/img_11.png",
  "img_12": CDN_URL + "/image-assets/animation-assets/buy-shop/subscription/images/img_12.png",
  "img_13": CDN_URL + "/image-assets/animation-assets/buy-shop/subscription/images/img_13.png",
  "img_14": CDN_URL + "/image-assets/animation-assets/buy-shop/subscription/images/img_14.webp",
};

export default SubscriptionAssets;
