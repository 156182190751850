import { DimensionContext } from "@src/DimensionProvider";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedActiveAvatarWB } from "@src/ducks/slices/lobby.slice";
import images from "@assets/images";
import { ImageButton } from "components/button";
import React, { useContext } from "react";
import { Image, View } from "react-native";
import { AvatarContext } from "../AvatarProvider";
import useStyles from "./styles.css";

const Avatar = () => {
  const styles = useStyles();
  const { setShowScreen } = useContext(AvatarContext);
  const avatar = useAppSelector(selectedActiveAvatarWB);
  const { baseWidth } = React.useContext(DimensionContext);

  return (
    <View style={styles.v_container}>
      <View style={[styles.v_centerframe, 
        { width: baseWidth * 0.29, height: baseWidth * 0.3 }]}>
        <Image
          style={[styles.i_avatar_frame, { transform: [{scale: 1.1}], backgroundColor: "transparent"}]}
          source={images.frame_avatar}
          resizeMode="stretch"
        />
        <Image
          style={[
            { zIndex: 2, position: "absolute", top: "19%"},
            { height: baseWidth * 0.198, width: baseWidth * 0.14 },
            // { height: baseWidth * 0.2, width: baseWidth * 0.2 },
          ]}
          source={images.avatar_chair}
          resizeMode="stretch"
        />
        <Image
          style={[
            styles.i_avatar_wb,
            { height: baseWidth * 0.208, width: baseWidth * 0.17 },
          ]}
          source={avatar}
          resizeMode="stretch"
        />
      </View>
      <ImageButton
        style={[
          styles.btn_change,
          { height: baseWidth * 0.03, width: baseWidth * 0.13 },
        ]}
        source={images.btn_change_avatar}
        onPress={() => setShowScreen("avatar")}
      />
    </View>
  );
};

export default Avatar;
