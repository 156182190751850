import { useNavigation } from "@react-navigation/native";
import { DimensionContext } from "@src/DimensionProvider";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedAvatarTier } from "@src/ducks/slices/lobby.slice";
import images from "@assets/images";
import { ImageButton } from "components/button";
import React, { useContext } from "react";
import { Image, View } from "react-native";
import { AvatarContext } from "../AvatarProvider";
import useStyles from "./styles.css";

const Tier = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const tier = useAppSelector(selectedAvatarTier);
  const { setShowScreen } = useContext(AvatarContext);
  const { baseWidth, baseHeight } = React.useContext(DimensionContext);

  return (
    <View style={[styles.v_container, ]}>
      <View
        style={[
          styles.v_tiercenter,
          { width: baseWidth * 0.264, height: baseWidth * 0.26 },
        ]}
      >
        <Image
          style={[
            styles.i_yourtier,

            { width: baseWidth * 0.1841, height: baseHeight * 0.09},
          ]}
          source={images.your_tier}
          resizeMode="stretch"
        />
        <Image
          style={[styles.i_tierlevel, { transform: [{scale: 1.5}]}]}
          source={images.frame_avatar}
          resizeMode="cover"
          resizeMethod="scale"
        />
        <Image
          style={[
            { zIndex: 2, position: "absolute" },
            { height: baseWidth * 0.15, width: baseWidth * 0.15 },
          ]}
          source={tier.tierAvatar}
          // source={images.tier_diamond}
          resizeMode="stretch"
        />
      </View>
      <ImageButton
        onPress={() => {
          setShowScreen("");
          navigation.navigate("TierRewards");
        }}
        style={[
          styles.btn_tier,
          { height: baseWidth * 0.03, width: baseWidth * 0.13 },
        ]}
        source={images.btn_tier_rewards}
      />
    </View>
  );
};

export default Tier;
