import CongratsAssets from "./congrats-assets";
import GoldCoinEarning from "./gold-coin-earning-assets";
import PremiumPackagesAssets from "./premium-package-assets";
import SignupBonus from "./signup-bonus-assets";

// BUY SHOP
import ButtonShopAssets from "./buyshop/button-shop-assets";
import ShopAssets from "./buyshop/shop-assets";
import SubscriptionAssets from "./buyshop/subscription-assets";

// DAILY BONUS
import DailyBannerAssets from "./daily-bonus/daily-banner-assets";
import RandomSlotAssets from "./daily-bonus/random-slot-assets";

// HOT DEALS
import DragonRollerAssets from "./dragon-roller-assets";
import HighRollerAssets from "./high-roller-assets";
import HotDealsAssets from "./hot-deals-assets";
import HotDealsButtonAssets from "./hot-deals-button-assets";
import StarterPackAssets from "./starter-pack-assets";
import TGIFAssets from "./tgif-assets";
import WeeklyBoosterAssets from "./weekly-booster-assets";

// TIER LEVEL UP
import TierLevelUpDiamond from "./tier-level-up-assets/diamond";
import TierLevelUpEmerald from "./tier-level-up-assets/emerald";
import TierLevelUpRuby from "./tier-level-up-assets/ruby";
import TierLevelRewards from "./tier-rewards-assets";

// MISSION
import MissionRewardAssets from "./mission-reward-assets";
import MissionVaultAssets from "./mission-vault-assets";

// JADE EGG
import JadeEggBgAssets from "./jade-egg-bg-assets";
import JadeEggCurtainAssets from "./jade-egg-curtain-assets";
import JadeEggExplodeAssets from "./jade-egg-explode-assets";
import JadeEggShakeAssets from "./jade-egg-shake-assets";


const AnimationImages = {
  ...SignupBonus,
  ...CongratsAssets,
  ...GoldCoinEarning,
  ...PremiumPackagesAssets,

  // BUY SHOP
  ...ShopAssets,
  ...ButtonShopAssets,
  ...SubscriptionAssets,

  // DAILY BONUS
  ...DailyBannerAssets,
  ...RandomSlotAssets,

  // HOT DEALS
  ...DragonRollerAssets,
  ...HighRollerAssets,
  ...HotDealsAssets,
  ...HotDealsButtonAssets,
  ...StarterPackAssets,
  ...TGIFAssets,
  ...WeeklyBoosterAssets,
  
  // TIER LEVEL UP
  ...TierLevelUpDiamond,
  ...TierLevelUpRuby,
  ...TierLevelUpEmerald,
  ...TierLevelRewards,

  // MISSION
  ...MissionRewardAssets,
  ...MissionVaultAssets,

  // JADE EGG
  ...JadeEggBgAssets,
  ...JadeEggCurtainAssets,
  ...JadeEggExplodeAssets,
  ...JadeEggShakeAssets,
};

// image preloading
export const AnimationAssets = Object.keys(AnimationImages).map((key: string) => AnimationImages[key as keyof typeof AnimationImages]);

// LOAD ONLY JADE EGG
export const JadeEggAssets = Object.keys({
  ...JadeEggBgAssets,
  ...JadeEggCurtainAssets,
  ...JadeEggExplodeAssets,
  ...JadeEggShakeAssets,
})
  .map((key: string) => AnimationImages[key as keyof typeof AnimationImages]);

export default AnimationImages;
