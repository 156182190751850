import { CDN_URL } from "@env";
import { ShopTypes } from "../types/buyshop/shop-types";

const ShopAssets: ShopTypes = {
  "img_0": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_0.png",
  "img_1": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_1.png",
  "img_2": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_2.png",
  "img_3": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_3.png",
  "img_4": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_4.png",
  "img_5": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_5.png",
  "img_6": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_6.png",
  "img_7": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_7.png",
  "img_8": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_8.png",
  "img_9": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_9.png",
  "img_10": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_10.png",
  "img_11": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_11.png",
  "img_12": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_12.png",
  "img_13": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_13.png",
  "img_14": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_14.png",
  "img_15": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_15.png",
  "img_16": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_16.png",
  "img_17": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_17.png",
  "img_18": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_18.png",
  "img_19": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_19.png",
  "img_20": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_20.png",
  "img_21": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_21.png",
  "img_22": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_22.png",
  "img_23": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_23.png",
  "img_24": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_24.png",
  "img_25": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_25.png",
  "img_26": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_26.png",
  "img_27": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_27.png",
  "img_28": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_28.png",
  "img_29": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_29.png",
  "img_30": CDN_URL + "/image-assets/animation-assets/buy-shop/shop/images/img_30.webp",
};

export default ShopAssets;
