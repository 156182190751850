import React from "react";
import { SvgProps } from "react-native-svg";

//SVGs
import BTNAction from "./action.svg";
import BTNArcade from "./arcade.svg";
import BTNGrayHeart from "./heart";
import BTNInstant from "./instant.svg";
import BTNMyFavorite from "./my-favorite.svg";
import BTNPuzzle from "./puzzle.svg";
import BTNSlots from "./slots.svg";
import BTNTable from "./table.svg";
import BTNArrow from "./arrow.svg";
import colors from "theme/colors";

export interface IconProps extends SvgProps {
  name: "action" | "arcade" | "instant" | "my-favorite" | "puzzle" |
    "slots" | "table" | "btn-tier-level" | "bg-settings" | "gray-heart" | "arrow";
  size?: number;
  stroke?: string;
  fill?: string;
  fill2?: string;
  isActive?: boolean;
}

const SVGDrawer = (props: IconProps) => {
  const { name, width, height, isActive } = props;
  const fill = isActive ? colors.red : colors.gray3;


  switch (name) {
  case "action":
    return <BTNAction width={width} height={height} fill={fill} />;
  case "arcade":
    return <BTNArcade width={width} height={height} fill={fill} />;
  case "instant":
    return <BTNInstant width={width} height={height} fill={fill} />;
  case "my-favorite":
    return <BTNMyFavorite width={width} height={height} fill={fill} />;
  case "gray-heart":
    return <BTNGrayHeart width={width} height={height} fill={props.fill} />;
  case "slots":
    return <BTNSlots width={width} height={height} fill={fill} />;
  case "table":
    return <BTNTable width={width} height={height} fill={fill} />;
  case "puzzle":
    return <BTNPuzzle width={width} height={height} fill={fill} />;
  case "arrow":
    return <BTNArrow width={width} height={height} fill={fill} />;
  default:
    return null;
  }
};

export default SVGDrawer;
