import images from "@assets/images";
import React from "react";
import { Image, View, useWindowDimensions } from "react-native";
import { CoinContext } from "../../main/CoinProvider";
import { ResponsiveContext } from "../provider";
import SilverCoins from "./silver-coins";
import useStyles from "./styles.css";

const SilverBar = () => {
  const styles = useStyles();
  const viewRef = React.useRef<any>();
  const { setCoordination } = React.useContext(CoinContext);
  const { width: WIDTH, height: HEIGHT } = useWindowDimensions();
  const { baseWidth, baseHeight } = React.useContext(ResponsiveContext);

  React.useEffect(() => {
    if (viewRef.current) {
      viewRef.current?.measure(
        (fx: any, fy: any, width: number, height: any) => {
          const transX = -(fx + fy);
          const transY = -(HEIGHT / 2 - (height / 2 + fy));

          setCoordination({ transX, transY });
        }
      );
    }
  }, [viewRef.current, WIDTH, HEIGHT]);

  return (
    <View ref={viewRef} style={[styles.container, { width: baseWidth * 0.1427, height: baseHeight * 0.3834 } ]}>
      <Image
        resizeMode="stretch"
        style={styles.silverBar}
        source={images.silver_bar}
      />
      <SilverCoins baseHeight={baseHeight} />
    </View>
  );
};

export default SilverBar;
