import images from "@assets/images";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useLobbyService } from "@src/ducks/hooks";
import { selectedAuthSession } from "@src/ducks/slices/auth.slice";
import { selectedHourlyReward } from "@src/ducks/slices/lobby.slice";
import { ImageButton06 } from "components/button";
import React from "react";
import { Image, View } from "react-native";
import { check3hoursReward, collect3HoursRewards } from "utils/api";
import { useFetchPost } from "utils/api/hooks";
import { CoinContext } from "../../main/CoinProvider";
import { ResponsiveContext } from "../provider";
import useStyles from "./styles.css";
import Timer from "./timer";

const Collect = () => {
  const styles = useStyles();
  const session  = useAppSelector(selectedAuthSession);
  const checker = useAppSelector(selectedHourlyReward);
  const { loading, runCallbackRequest } = useFetchPost();
  const { on3HourTime, onUpdateMissions } = useLobbyService();
  const { onAdditionalCurrency } = React.useContext(CoinContext);
  const { baseWidth, baseHeight } = React.useContext(ResponsiveContext);

  const onCollect = () => {
    runCallbackRequest(
      async() => {
        await collect3HoursRewards(session, session.token);
        const hourlyReward = await check3hoursReward(session, session.token);

        return hourlyReward;
      },
      (response) => {
        console.log("responseresponse", response);
        on3HourTime(response);
        onAdditionalCurrency({silver: 50000, bg: 0, gwz: 0});
        onUpdateMissions("collect bonus");
      },
      (error) => {
        console.log("errorerror", error);
      }
    );
  };

  return (
    <View style={[styles.container, { left: baseWidth * 0.2468, bottom: 0} ]}>
      {(checker.timer > 0 || checker.id === null) ? null : 
        <ImageButton06
          isLoad={loading}
          onPress={onCollect}
          disabled={loading}
          label="COLLECT"
          labelStyle={{fontSize: baseWidth * 0.008}}
          style={[
            styles.btnCollect,
            { width: baseWidth * 0.0405, height: baseHeight * 0.21,  },
          ]}
        />}
      <View style={[styles.v_timer, { width: baseWidth * 0.0802, height: baseHeight * 0.1606 }]}>
        <Timer baseHeight={baseHeight} />
      </View>
      <Image
        source={images.btn_hourglass}
        style={[styles.imageCollect, { 
          width: baseHeight * 0.71, 
          height: baseHeight * 0.73, 
          right: baseHeight * 0.17
        }]}
        resizeMode="stretch"
      />
    </View>
  );
};

export default React.memo(Collect);
