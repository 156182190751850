import { CreateResponsiveStyle, DEVICE_SIZES } from "rn-responsive-styles";
import { colors } from "@src/theme";

const useStyles = CreateResponsiveStyle(
  {
    v_fullscreen: {
      zIndex: 5,
      width: "100%",
      height: "100%",
      backgroundColor: colors.translucent3,
    },
    container: {
      top: "15%",
      width: "100%",
      height: "85%",
      position: "absolute",
    },
    v_top_divider: { height: "15%" },
    v_bottom: {
      bottom: 0,
      zIndex: 10,
      height: "25%",
      width: "100%",
      flexDirection: "row",
      justifyContent: "center",
    },
    btn_back: {
      width: "10%",
      height: "45%",
      left: "5%",
      position: "absolute",
    },
    btn_change: { width: "20%", height: "45%" },

    // ITEM LIST STYLES
    bodyStyle: {
      flex: 1,
      zIndex: 3,
    },
    contentContainerStyle: {
      paddingLeft: "10%",
      paddingRight: "10%",
    },

    // ITEM SLOT STYLES
    slotContainerStyle: {
      heigth: "58%",
      alignItems: "center",
      justifyContent: "center",
    },
    slotImageStyle: {
      width: "100%",
      height: "100%",
    },
    slotImageStyle_active: {
      borderWidth: 5,
      borderColor: colors.orange,
    },
  },
  {
    [DEVICE_SIZES.SM]: {},
    [DEVICE_SIZES.XS]: {},
  }
);

export default useStyles;
