import SVGDrawer from "@assets/svg/side-drawer";
import { DimensionContext } from "@src/DimensionProvider";
import React from "react";
import { Pressable, StyleSheet } from "react-native";
import Animated, {
  Extrapolation,
  interpolate,
  useAnimatedStyle
} from "react-native-reanimated";

type Props = {
  onPress: () => void;
  animated: Animated.SharedValue<number>
}

function ButtonSwitch({ animated, onPress }: Props) {
  const { baseWidth } = React.useContext(DimensionContext);

  const animateStyle = useAnimatedStyle(() => {
    const rotate = interpolate(
      animated.value,
      [0, 1],
      [180, 0],
      Extrapolation.CLAMP
    );

    return {
      transform: [{rotate: `${rotate}deg`}]
    };
  }, []);

  return (
    <Pressable onPress={onPress} style={styles.btn_switch} >
      <Animated.View style={animateStyle}>
        <SVGDrawer name="arrow" width={baseWidth * 0.02} height={baseWidth * 0.02} />
      </Animated.View>
    </Pressable>
   
  );
}

const styles = StyleSheet.create({
  btn_switch: {position: "absolute", right: "0%", 
    top: "10.5%", width: "28%", height: "14%",
    alignItems: "center", justifyContent: "center",
  },
});

export default ButtonSwitch;