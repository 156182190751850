import images from "@assets/images";
import React from "react";
import { Image, View } from "react-native";
import Avatar from "./avatar";
import Email from "./email";
import GameSwitch from "./game-switch";
import GoldBar from "./goldbar";
import Home from "./home";
import ResponsiveProvider from "./provider";
import Settings from "./settings";
import SilverBar from "./silverbar";
import useStyles from "./styles.css";

const TopNav = () => {
  const styles = useStyles();

  return (
    <ResponsiveProvider>
      <>
        <Image
          source={images.hud_top}
          style={styles.style_navtop}
          resizeMode="stretch"
        />
        <View style={styles.wrapcenter}>
          <View style={styles.leftStyle}>
            <View style={styles.leftStyleCenter}>
              <Home />
              <Email />
              <SilverBar />
            </View>
          </View>
          <View style={styles.middleStyle}>
            <Avatar />
          </View>
          <View style={styles.rightStyle}>
            <View style={styles.leftStyleCenter}>
              <GoldBar />
              <GameSwitch />
              <Settings />
            </View>
          </View>
        </View>
      </>
    </ResponsiveProvider>
  );
};

export default TopNav;
