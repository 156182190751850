import React from "react";
import { selectedProfileStatus } from "@src/ducks/slices/settings.slice";
import { selectedActiveAvatarWB } from "@src/ducks/slices/lobby.slice";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useSettingsService } from "@src/ducks/hooks";
import { Image, View, Pressable } from "react-native";
import { ResponsiveContext } from "../provider";
import useStyles from "./styles.css";
import images from "@assets/images";

const Avatar = () => {
  const styles = useStyles();
  const avatar = useAppSelector(selectedActiveAvatarWB);
  const hasProfileOpen = useAppSelector(selectedProfileStatus);
  const { onChangeSettings, onChangeProfile }  = useSettingsService();
  const { baseHeight } = React.useContext(ResponsiveContext);

  const onPress = () => {
    onChangeSettings(false);
    onChangeProfile(!hasProfileOpen);
  };

  return (
    <Pressable onPress={onPress} style={[styles.centerStyle, { width: baseHeight * 1, height: baseHeight * 1 } ]} >
      <Image style={styles.i_avatar_bg} source={images.avatar_bg} resizeMode="stretch" />
      <Image style={[styles.i_avatar_shine, { width: baseHeight * 0.6, height: baseHeight * 0.6 }]} source={images.avatar_shine} resizeMode="stretch" />
      <Image style={[styles.i_avatar_shine2, { width: baseHeight * 0.3, height: baseHeight * 0.3 }]} source={images.avatar_shine} resizeMode="stretch" />
      <View style={[styles.v_mask_avatar, { width: baseHeight * 0.89, height: baseHeight * 0.89 }]}>
        <Image style={[styles.i_avatar, { width: baseHeight * 1.2, height: baseHeight * 1.2}]} 
          source={avatar} resizeMode="stretch" />
      </View>
    </Pressable>
  );
};

export default Avatar;
