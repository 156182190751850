import React, { useState, useImperativeHandle } from "react";
import { View, FlatList } from "react-native";
import { MAavatars } from "utils/mockdata";
import AvatarItem from "./AvatarItem";
import useStyles from "./styles.css";

const AvatarList = React.forwardRef(({ baseWidth }: any, ref) => {
  const styles = useStyles();
  const [active, setActive] = useState<any>({});
  const onItem = (item: any) => setActive(item);

  useImperativeHandle(ref, () => ({
    getActive: () => active,
  }));

  return (
    <View style={[styles.bodyStyle, { top: baseWidth * 0.07}]}>
      <FlatList
        horizontal
        data={MAavatars}
        scrollEnabled
        renderItem={({ item, index }) => {
          const isActive = active.id === item.id;

          return (
            <AvatarItem
              key={index.toString()}
              {...{ item, isActive, onPress: () => onItem(item), baseWidth }}
            />
          );
        }}
        showsHorizontalScrollIndicator={false}
        keyExtractor={(item, index) => index.toString()}
        contentContainerStyle={styles.contentContainerStyle}
      />
    </View>
  );
});

AvatarList.displayName = "AvatarList";

export default AvatarList;
