import images from "@assets/images";

const Avatars = [
  {
    id: 1,
    body: images.avatar_bill_wb,
  },
  {
    id: 2,
    body: images.avatar_casino_wb,
  },
  {
    id: 3,
    body: images.avatar_cowboy_wb,
  },
  {
    id: 4,
    body: images.avatar_cowgirl_wb,
  },
  {
    id: 5,
    body: images.avatar_gambler_wb,
  },
  {
    id: 6,
    body: images.avatar_gamer_wb,
  },
  {
    id: 7,
    body: images.avatar_glam_wb,
  },
  {
    id: 8,
    body: images.avatar_hiphop_wb,
  },
  {
    id: 9,
    body: images.avatar_popstar_wb,
  },
  {
    id: 10,
    body: images.avatar_rockstar_wb,
  },
];

export default Avatars;
