import React from "react";
import { Image, View, TouchableWithoutFeedback } from "react-native";
import useStyles from "./styles.css";

interface AvatarItemProps {
  item: any;
  baseWidth: number;
  isActive?: boolean;
  onPress?: () => void;
}

const AvatarItem = (props: AvatarItemProps) => {
  const styles = useStyles();
  const { item, onPress, isActive } = props;

  return (
    <View style={[styles.slotContainerStyle, { width: props.baseWidth * 0.2399, height: props.baseWidth * 0.2599 }]}>
      <TouchableWithoutFeedback onPress={onPress}>
        <Image
          source={item.body}
          resizeMode="stretch"
          style={[
            styles.slotImageStyle,
            isActive && styles.slotImageStyle_active,
          ]}
        />
      </TouchableWithoutFeedback>
    </View>
  );
};

export default AvatarItem;
