import { CreateResponsiveStyle, DEVICE_SIZES } from "rn-responsive-styles";
import { StyleSheet, Platform } from "react-native";
import { colors } from "@src/theme";

const color = Platform.select({
  android: colors.black,
  default: colors.white,
});

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      zIndex: 6,
      alignItems: "center",
      justifyContent: "center",
      ...StyleSheet.absoluteFillObject,
      backgroundColor: colors.translucent,
    },
    center_view: {
      width: "67%",
      height: "18%",
      justifyContent: "center",
    },
    i_background: {
      width: "100%",
      height: "100%",
      position: "absolute",
    },
    v_textwrap: {
      left: "5%",
      width: "71%",
      height: "63%",
      justifyContent: "center",
      backgroundColor: colors.transparent,
    },
    v_textwrap_error: {
      borderWidth: 2,
      borderColor: colors.red1,
    },
    ti_username: {
      flex: 1,
      fontSize: 22,
      paddingLeft: "2%",
      color,
      fontFamily: "Roboto",
      backgroundColor: colors.transparent,
    },
    t_error: {
      right: 8,
      fontSize: 15,
      color: colors.white,
      position: "absolute",
      fontFamily: "Roboto",
    },
    btn_close: {
      width: "17%",
      height: "60%",
      right: "4%",
      position: "absolute",
    },
    btn_ok: {
      zIndex: 2,
      width: "20%",
      height: "15%",
      bottom: "10%",
      right: "8%",
      position: "absolute",
    },
  },
  {
    [DEVICE_SIZES.SM]: {},
    [DEVICE_SIZES.XS]: {},
  }
);

export default useStyles;
