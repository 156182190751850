import ButtonShopAssets from "@assets/images/buyshop/button-shop-assets";

export default {
  "v": "5.12.1",
  "fr": 30,
  "ip": 0,
  "op": 60,
  "w": 1080,
  "h": 1080,
  "nm": "Shop_Anim",
  "ddd": 0,
  "assets": [
    {
      "id": "image_0",
      "w": 562,
      "h": 554,
      "u": "",
      "p": ButtonShopAssets["img_0"],
      "e": 0
    },
    {
      "id": "image_1",
      "w": 562,
      "h": 554,
      "u": "",
      "p": ButtonShopAssets["img_1"],
      "e": 0
    },
    {
      "id": "image_2",
      "w": 1611,
      "h": 1680,
      "u": "",
      "p": ButtonShopAssets["img_2"],
      "e": 0
    },
    {
      "id": "image_3",
      "w": 411,
      "h": 376,
      "u": "",
      "p": ButtonShopAssets["img_3"],
      "e": 0
    },
    {
      "id": "image_4",
      "w": 1611,
      "h": 1680,
      "u": "",
      "p": ButtonShopAssets["img_4"],
      "e": 0
    },
    {
      "id": "image_5",
      "w": 1611,
      "h": 1680,
      "u": "",
      "p": ButtonShopAssets["img_5"],
      "e": 0
    },
    {
      "id": "comp_0",
      "nm": "Main",
      "fr": 30,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          "nm": "Sparks",
          "refId": "comp_1",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                540,
                540,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                540,
                540,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 1080,
          "h": 1080,
          "ip": 0,
          "op": 60,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 2,
          "nm": "Shop_anim3.png",
          "cl": "png",
          "refId": "image_2",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                532,
                840,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                793.056,
                1306.667,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 0,
                  "s": [
                    64.286,
                    64.286,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 5,
                  "s": [
                    64.286,
                    60.153,
                    100
                  ]
                },
                {
                  "t": 10,
                  "s": [
                    64.286,
                    64.286,
                    100
                  ]
                }
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 60,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 0,
          "nm": "Coins",
          "refId": "comp_2",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                540,
                540,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                540,
                540,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 1080,
          "h": 1080,
          "ip": 0,
          "op": 60,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 2,
          "nm": "Shop_anim2.png",
          "cl": "png",
          "refId": "image_4",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                524,
                840,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                780.611,
                1306.667,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 0,
                  "s": [
                    64.286,
                    64.286,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833,
                      0.833,
                      0.833
                    ],
                    "y": [
                      0.833,
                      0.833,
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167,
                      0.167,
                      0.167
                    ],
                    "y": [
                      0.167,
                      0.167,
                      0.167
                    ]
                  },
                  "t": 5,
                  "s": [
                    64.286,
                    60.765,
                    100
                  ]
                },
                {
                  "t": 10,
                  "s": [
                    64.286,
                    64.286,
                    100
                  ]
                }
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 60,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 5,
          "ty": 2,
          "nm": "Shop_anim1.png",
          "cl": "png",
          "refId": "image_5",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                540,
                540,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                805.5,
                840,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                64.286,
                64.286,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 60,
          "st": 0,
          "bm": 0
        }
      ]
    },
    {
      "id": "comp_1",
      "nm": "Sparks",
      "fr": 30,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 2,
          "nm": "Spark1.png",
          "cl": "png",
          "refId": "image_0",
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 1,
                  "s": [
                    1
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 6,
                  "s": [
                    100
                  ]
                },
                {
                  "t": 11,
                  "s": [
                    0
                  ]
                }
              ],
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                256,
                442,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                281,
                277,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                54.231,
                54.231,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 1,
          "op": 61,
          "st": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 2,
          "nm": "Spark2.png",
          "cl": "png",
          "refId": "image_1",
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 4,
                  "s": [
                    0
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 9,
                  "s": [
                    100
                  ]
                },
                {
                  "t": 14,
                  "s": [
                    2
                  ]
                }
              ],
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                720,
                300,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                281,
                277,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                46.011,
                46.011,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 4,
          "op": 64,
          "st": 4,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 2,
          "nm": "Spark2.png",
          "cl": "png",
          "refId": "image_1",
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 9,
                  "s": [
                    1
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 14,
                  "s": [
                    100
                  ]
                },
                {
                  "t": 19,
                  "s": [
                    0
                  ]
                }
              ],
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                328,
                760,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                281,
                277,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                46.011,
                46.011,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 9,
          "op": 69,
          "st": 9,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 2,
          "nm": "Spark2.png",
          "cl": "png",
          "refId": "image_1",
          "sr": 1,
          "ks": {
            "o": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 0,
                  "s": [
                    0
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 5,
                  "s": [
                    100
                  ]
                },
                {
                  "t": 10,
                  "s": [
                    0
                  ]
                }
              ],
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                716,
                564,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                281,
                277,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                46.011,
                46.011,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 60,
          "st": 0,
          "bm": 0
        }
      ]
    },
    {
      "id": "comp_2",
      "nm": "Coins",
      "fr": 30,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 2,
          "nm": "Shop_anim4.png",
          "cl": "png",
          "refId": "image_3",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 0,
                  "s": [
                    -7
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 5,
                  "s": [
                    3
                  ]
                },
                {
                  "t": 10,
                  "s": [
                    -7
                  ]
                }
              ],
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                732,
                183,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                205.5,
                188,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                40.963,
                40.963,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 60,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 2,
          "nm": "Shop_anim4.png",
          "cl": "png",
          "refId": "image_3",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 8,
                  "s": [
                    -17
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 13,
                  "s": [
                    -26
                  ]
                },
                {
                  "t": 18,
                  "s": [
                    -17
                  ]
                }
              ],
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                615,
                335,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                205.5,
                188,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                40.963,
                40.963,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 60,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 2,
          "nm": "Shop_anim4.png",
          "cl": "png",
          "refId": "image_3",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 5,
                  "s": [
                    25
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 10,
                  "s": [
                    2
                  ]
                },
                {
                  "t": 15,
                  "s": [
                    25
                  ]
                }
              ],
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                839,
                332,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                205.5,
                188,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                63.963,
                63.963,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 60,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 2,
          "nm": "Shop_anim4.png",
          "cl": "png",
          "refId": "image_3",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 2,
                  "s": [
                    -15
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 7,
                  "s": [
                    -1
                  ]
                },
                {
                  "t": 12,
                  "s": [
                    -15
                  ]
                }
              ],
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                211,
                217,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                205.5,
                188,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                -64.286,
                64.286,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 60,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 5,
          "ty": 2,
          "nm": "Shop_anim4.png",
          "cl": "png",
          "refId": "image_3",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 11,
                  "s": [
                    5
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 16,
                  "s": [
                    21
                  ]
                },
                {
                  "t": 21,
                  "s": [
                    5
                  ]
                }
              ],
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                505,
                161,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                205.5,
                188,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                -43.286,
                43.286,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 60,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 6,
          "ty": 2,
          "nm": "Shop_anim4.png",
          "cl": "png",
          "refId": "image_3",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 4,
                  "s": [
                    11
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 9,
                  "s": [
                    -9
                  ]
                },
                {
                  "t": 14,
                  "s": [
                    11
                  ]
                }
              ],
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                431,
                356,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                205.5,
                188,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                -64.286,
                64.286,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 60,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 7,
          "ty": 2,
          "nm": "Shop_anim4.png",
          "cl": "png",
          "refId": "image_3",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 0,
                  "s": [
                    0
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.833
                    ],
                    "y": [
                      0.833
                    ]
                  },
                  "o": {
                    "x": [
                      0.167
                    ],
                    "y": [
                      0.167
                    ]
                  },
                  "t": 5,
                  "s": [
                    -33
                  ]
                },
                {
                  "t": 10,
                  "s": [
                    0
                  ]
                }
              ],
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                636,
                476,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                205.5,
                188,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                64.286,
                64.286,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 60,
          "st": 0,
          "bm": 0
        }
      ]
    }
  ],
  "layers": [
    {
      "ddd": 0,
      "ind": 1,
      "ty": 0,
      "nm": "Main",
      "refId": "comp_0",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            540,
            540,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            540,
            540,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "w": 1080,
      "h": 1080,
      "ip": 0,
      "op": 60,
      "st": 0,
      "bm": 0
    }
  ],
  "markers": [],
  "props": {}
};