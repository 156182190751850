import images from "@assets/images";
import ShopJson from "@assets/json/shop";
import { useDimensionContext } from "@src/DimensionProvider";
import React from "react";
import Lottie from "react-lottie-player";
import { Image, View } from "react-native";
import useStyles from "./styles.css";

const Shop = () => {
  const styles = useStyles();
  const { hudHeight } = useDimensionContext();

  return (
    <View
      style={[
        styles.container,
        { width: hudHeight * 1.31, height: hudHeight * 1.31 },
      ]}
    >
      <Image
        source={{uri: images.avatar_bg}}
        style={[styles.imageStyle]}
        resizeMode="stretch"
      />
      <Lottie
        play
        loop
        animationData={ShopJson}
        style={{position: "absolute", alignSelf: "center", width: hudHeight * 1, height: hudHeight * 0.78, top: -hudHeight * 0.05}}
      />
    </View>
  );
};

export default Shop;
