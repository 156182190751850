import { useNavigation } from "@react-navigation/native";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedNotificationsUnread } from "@src/ducks/slices/lobby.slice";
import images from "@assets/images";
import { ImageButton02 } from "components/button";
import React from "react";
import { Image, View } from "react-native";
import { ResponsiveContext } from "../provider";
import useStyles from "./styles.css";

const Email = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const { baseHeight } = React.useContext(ResponsiveContext);
  const hasRead = useAppSelector(selectedNotificationsUnread);

  return (
    <ImageButton02
      onPress={() => navigation.navigate("Inbox")}
      style={[styles.container, 
        { width:  baseHeight * 0.3730, height: baseHeight * 0.2797, marginTop: baseHeight * 0.1 }]}
    >
      <View style={[styles.dotStyle,
        { width: baseHeight * 0.1, 
          height: baseHeight * 0.1, 
          top: -(baseHeight * 0.05), 
          right: -(baseHeight * 0.05)  }, 
        hasRead && styles.dotActiveStyle]} />
      <Image
        source={images.btn_mail}
        style={styles.size_egg}
        resizeMode="stretch"
      />
    </ImageButton02>
  );
};

export default Email;
