import { colors } from "@src/theme";
import { CreateResponsiveStyle } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      zIndex: 2,
      alignItems: "center",
      justifyContent: "center",
    },
    center_view: {
      width: "75%",
      height: "86%",
      alignItems: "center",
      justifyContent: "center",
    },
    i_background: {
      width: "100%",
      height: "100%",
      position: "absolute",
    },
    btn_close: {
      top: "10%",
      zIndex: 200,
      width: "5%",
      right: "15%",
      height: "9%",
      position: "absolute",
    },
    btn_close2: {
      top: "-6%",
      zIndex: 200,
      right: "-6%",
      position: "absolute",
    },
    v_inbox: {
      top: "-10%",
      width: "15%",
      height: "12%",
      position: "absolute",
      alignSelf: "center",
    },
    i_inbox: {
      width: "100%",
      height: "100%",
    },
    i_message: {
      width: "24%",
      height: "75%",
      position: "absolute",
      top: "-50%",
      right: "-30%",
    },
    v_unread_indicator: {
      top: "-2.3%",
      zIndex: 200,
      left: "-2.3%",
      position: "absolute",
      borderWidth: 1,
      backgroundColor: colors.green5
    },

    // INBOX LIST STYLE
    v_body: {
      zIndex: 2,
      width: "90%",
      height: "76%",
      marginBottom: "2%",
      // paddingTop: "4%",
      alignItems: "center",
      paddingHorizontal: "5%",
      justifyContent: "center",
    },
    flatlist: { width: "85%", paddingTop: "2%" },
    height20: { height: 20 },

    // INBOX ITEM COMPONENTS STYLES
    v_item: {
      marginHorizontal: "1%",
      height: "20%",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    i_bg_message: {
      width: "100%",
      height: "82%",
      position: "absolute",
    },
    v_thumbnail: { width: "100%", height: "90%", alignItems: "center", justifyContent: "center" },
    i_item_icon: { width: "100%", height: "100%", position: "absolute" },
    v_thumnail_source: { 
      width: "91%", height: "91%",
      marginTop: "1%",
      overflow: "hidden",
      position: "absolute", 
    },
    v_subject: { alignItems: "center", justifyContent: "center", marginTop: "3%"},
    t_notif: {
      textAlign: "center",
      color: colors.white,
      fontFamily: "Roboto-Bold",
    },
    btn_read: { top: "5%", width: "17%", height: "60%", marginRight: "2%" },
    btn_notif_close: { width: "4%", height: "30%", right: "1%" },

    btn_style: { width: "18%", height: "100%"},
    btn_delete_all: { width: "20%", height: "9%", position: "absolute", bottom: "2%", alignSelf: "center"},

    v_action: {
      width: "90%", height: "15%", 
      position: "absolute", 
      bottom: 0, 
      alignItems: "center"
    },
    "LEFT": { alignItems: "flex-start" },
    "CENTER": { alignItems: "center" },
    "RIGHT": { alignItems: "center" },
  },
);

export default useStyles;
