import HotDealsButtonAssets from "@assets/images/hot-deals-button-assets";

export default {
  "v": "5.12.2",
  "fr": 30,
  "ip": 0,
  "op": 30,
  "w": 1000,
  "h": 1000,
  "nm": "Comp 1",
  "ddd": 0,
  "assets": [
    {
      "id": "image_0",
      "w": 562,
      "h": 554,
      "u": "",
      "p": HotDealsButtonAssets["img_0"],
      "e": 0
    },
    {
      "id": "image_1",
      "w": 745,
      "h": 309,
      "u": "",
      "p": HotDealsButtonAssets["img_1"],
      "e": 0
    },
    {
      "id": "image_2",
      "w": 745,
      "h": 309,
      "u": "",
      "p": HotDealsButtonAssets["img_2"],
      "e": 0
    },
    {
      "id": "imgSeq_0",
      "w": 335,
      "h": 541,
      "t": "seq",
      "u": "",
      "p": HotDealsButtonAssets["seq_0_0"],
      "e": 0
    },
    {
      "id": "imgSeq_1",
      "w": 335,
      "h": 541,
      "t": "seq",
      "u": "",
      "p": HotDealsButtonAssets["seq_0_1"],
      "e": 0
    },
    {
      "id": "imgSeq_2",
      "w": 335,
      "h": 541,
      "t": "seq",
      "u": "",
      "p": HotDealsButtonAssets["seq_0_2"],
      "e": 0
    },
    {
      "id": "imgSeq_3",
      "w": 335,
      "h": 541,
      "t": "seq",
      "u": "",
      "p": HotDealsButtonAssets["seq_0_3"],
      "e": 0
    },
    {
      "id": "imgSeq_4",
      "w": 335,
      "h": 541,
      "t": "seq",
      "u": "",
      "p": HotDealsButtonAssets["seq_0_4"],
      "e": 0
    },
    {
      "id": "imgSeq_5",
      "w": 335,
      "h": 541,
      "t": "seq",
      "u": "",
      "p": HotDealsButtonAssets["seq_0_5"],
      "e": 0
    },
    {
      "id": "imgSeq_6",
      "w": 335,
      "h": 541,
      "t": "seq",
      "u": "",
      "p": HotDealsButtonAssets["seq_0_6"],
      "e": 0
    },
    {
      "id": "imgSeq_7",
      "w": 335,
      "h": 541,
      "t": "seq",
      "u": "",
      "p": HotDealsButtonAssets["seq_0_7"],
      "e": 0
    },
    {
      "id": "imgSeq_8",
      "w": 335,
      "h": 541,
      "t": "seq",
      "u": "",
      "p": HotDealsButtonAssets["seq_0_8"],
      "e": 0
    },
    {
      "id": "imgSeq_9",
      "w": 335,
      "h": 541,
      "t": "seq",
      "u": "",
      "p": HotDealsButtonAssets["seq_0_9"],
      "e": 0
    },
    {
      "id": "imgSeq_10",
      "w": 335,
      "h": 541,
      "t": "seq",
      "u": "",
      "p": HotDealsButtonAssets["seq_0_10"],
      "e": 0
    },
    {
      "id": "imgSeq_11",
      "w": 335,
      "h": 541,
      "t": "seq",
      "u": "",
      "p": HotDealsButtonAssets["seq_0_11"],
      "e": 0
    },
    {
      "id": "comp_0",
      "nm": "Comp",
      "fr": 30,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 0,
          "nm": "[1-12].png",
          "cl": "png",
          "refId": "sequence_0",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                167.5,
                270.5,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                167.5,
                270.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 335,
          "h": 541,
          "ip": 0,
          "op": 12,
          "st": 0,
          "bm": 0
        }
      ]
    },
    {
      "id": "sequence_0",
      "layers": [
        {
          "ty": 2,
          "sc": "#00ffff",
          "refId": "imgSeq_0",
          "ks": {
            "p": {
              "a": 0,
              "k": [
                0,
                0
              ]
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0
              ]
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100
              ]
            },
            "r": {
              "a": 0,
              "k": [
                0
              ]
            },
            "o": {
              "a": 0,
              "k": [
                100
              ]
            }
          },
          "ip": 0,
          "st": 0,
          "op": 1,
          "sr": 1,
          "bm": 0
        },
        {
          "ty": 2,
          "sc": "#00ffff",
          "refId": "imgSeq_1",
          "ks": {
            "p": {
              "a": 0,
              "k": [
                0,
                0
              ]
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0
              ]
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100
              ]
            },
            "r": {
              "a": 0,
              "k": [
                0
              ]
            },
            "o": {
              "a": 0,
              "k": [
                100
              ]
            }
          },
          "ip": 1,
          "st": 1,
          "op": 2,
          "sr": 1,
          "bm": 0
        },
        {
          "ty": 2,
          "sc": "#00ffff",
          "refId": "imgSeq_2",
          "ks": {
            "p": {
              "a": 0,
              "k": [
                0,
                0
              ]
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0
              ]
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100
              ]
            },
            "r": {
              "a": 0,
              "k": [
                0
              ]
            },
            "o": {
              "a": 0,
              "k": [
                100
              ]
            }
          },
          "ip": 2,
          "st": 2,
          "op": 3,
          "sr": 1,
          "bm": 0
        },
        {
          "ty": 2,
          "sc": "#00ffff",
          "refId": "imgSeq_3",
          "ks": {
            "p": {
              "a": 0,
              "k": [
                0,
                0
              ]
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0
              ]
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100
              ]
            },
            "r": {
              "a": 0,
              "k": [
                0
              ]
            },
            "o": {
              "a": 0,
              "k": [
                100
              ]
            }
          },
          "ip": 3,
          "st": 3,
          "op": 4,
          "sr": 1,
          "bm": 0
        },
        {
          "ty": 2,
          "sc": "#00ffff",
          "refId": "imgSeq_4",
          "ks": {
            "p": {
              "a": 0,
              "k": [
                0,
                0
              ]
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0
              ]
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100
              ]
            },
            "r": {
              "a": 0,
              "k": [
                0
              ]
            },
            "o": {
              "a": 0,
              "k": [
                100
              ]
            }
          },
          "ip": 4,
          "st": 4,
          "op": 5,
          "sr": 1,
          "bm": 0
        },
        {
          "ty": 2,
          "sc": "#00ffff",
          "refId": "imgSeq_5",
          "ks": {
            "p": {
              "a": 0,
              "k": [
                0,
                0
              ]
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0
              ]
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100
              ]
            },
            "r": {
              "a": 0,
              "k": [
                0
              ]
            },
            "o": {
              "a": 0,
              "k": [
                100
              ]
            }
          },
          "ip": 5,
          "st": 5,
          "op": 6,
          "sr": 1,
          "bm": 0
        },
        {
          "ty": 2,
          "sc": "#00ffff",
          "refId": "imgSeq_6",
          "ks": {
            "p": {
              "a": 0,
              "k": [
                0,
                0
              ]
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0
              ]
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100
              ]
            },
            "r": {
              "a": 0,
              "k": [
                0
              ]
            },
            "o": {
              "a": 0,
              "k": [
                100
              ]
            }
          },
          "ip": 6,
          "st": 6,
          "op": 7,
          "sr": 1,
          "bm": 0
        },
        {
          "ty": 2,
          "sc": "#00ffff",
          "refId": "imgSeq_7",
          "ks": {
            "p": {
              "a": 0,
              "k": [
                0,
                0
              ]
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0
              ]
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100
              ]
            },
            "r": {
              "a": 0,
              "k": [
                0
              ]
            },
            "o": {
              "a": 0,
              "k": [
                100
              ]
            }
          },
          "ip": 7,
          "st": 7,
          "op": 8,
          "sr": 1,
          "bm": 0
        },
        {
          "ty": 2,
          "sc": "#00ffff",
          "refId": "imgSeq_8",
          "ks": {
            "p": {
              "a": 0,
              "k": [
                0,
                0
              ]
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0
              ]
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100
              ]
            },
            "r": {
              "a": 0,
              "k": [
                0
              ]
            },
            "o": {
              "a": 0,
              "k": [
                100
              ]
            }
          },
          "ip": 8,
          "st": 8,
          "op": 9,
          "sr": 1,
          "bm": 0
        },
        {
          "ty": 2,
          "sc": "#00ffff",
          "refId": "imgSeq_9",
          "ks": {
            "p": {
              "a": 0,
              "k": [
                0,
                0
              ]
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0
              ]
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100
              ]
            },
            "r": {
              "a": 0,
              "k": [
                0
              ]
            },
            "o": {
              "a": 0,
              "k": [
                100
              ]
            }
          },
          "ip": 9,
          "st": 9,
          "op": 10,
          "sr": 1,
          "bm": 0
        },
        {
          "ty": 2,
          "sc": "#00ffff",
          "refId": "imgSeq_10",
          "ks": {
            "p": {
              "a": 0,
              "k": [
                0,
                0
              ]
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0
              ]
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100
              ]
            },
            "r": {
              "a": 0,
              "k": [
                0
              ]
            },
            "o": {
              "a": 0,
              "k": [
                100
              ]
            }
          },
          "ip": 10,
          "st": 10,
          "op": 11,
          "sr": 1,
          "bm": 0
        },
        {
          "ty": 2,
          "sc": "#00ffff",
          "refId": "imgSeq_11",
          "ks": {
            "p": {
              "a": 0,
              "k": [
                0,
                0
              ]
            },
            "a": {
              "a": 0,
              "k": [
                0,
                0
              ]
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100
              ]
            },
            "r": {
              "a": 0,
              "k": [
                0
              ]
            },
            "o": {
              "a": 0,
              "k": [
                100
              ]
            }
          },
          "ip": 11,
          "st": 11,
          "op": 13,
          "sr": 1,
          "bm": 0
        }
      ]
    }
  ],
  "layers": [
    {
      "ddd": 0,
      "ind": 1,
      "ty": 2,
      "nm": "img_1.png",
      "cl": "png",
      "refId": "image_0",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 7,
              "s": [
                3
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 10,
              "s": [
                100
              ]
            },
            {
              "t": 13,
              "s": [
                3
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            830,
            646,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            281,
            277,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            59,
            59,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 6,
      "op": 18,
      "st": 6,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 2,
      "ty": 2,
      "nm": "img_1.png",
      "cl": "png",
      "refId": "image_0",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 3,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 6,
              "s": [
                100
              ]
            },
            {
              "t": 9,
              "s": [
                8
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            494,
            730,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            281,
            277,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            30,
            30,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 3,
      "op": 15,
      "st": 3,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 3,
      "ty": 2,
      "nm": "img_1.png",
      "cl": "png",
      "refId": "image_0",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 10,
              "s": [
                3
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 12,
              "s": [
                100
              ]
            },
            {
              "t": 14,
              "s": [
                3
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            196,
            786,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            281,
            277,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            34,
            34,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 10,
      "op": 22,
      "st": 10,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 4,
      "ty": 2,
      "nm": "img_1.png",
      "cl": "png",
      "refId": "image_0",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 0,
              "s": [
                3
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 2,
              "s": [
                100
              ]
            },
            {
              "t": 4,
              "s": [
                3
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            212,
            568,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            281,
            277,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            34,
            34,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 0,
      "op": 12,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 5,
      "ty": 2,
      "nm": "BTN watch.png",
      "cl": "png",
      "refId": "image_1",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            500,
            656,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            372.5,
            154.5,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 0,
      "op": 30,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 6,
      "ty": 0,
      "nm": "Comp",
      "refId": "comp_0",
      "sr": 3,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": -11,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            268,
            668,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            165.761,
            435.717,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            115,
            115,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "w": 335,
      "h": 541,
      "ip": 0,
      "op": 36,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 7,
      "ty": 2,
      "nm": "BTN.png",
      "cl": "png",
      "refId": "image_2",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            500,
            656,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            372.5,
            154.5,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 0,
      "op": 30,
      "st": 0,
      "bm": 0
    }
  ],
  "markers": [],
  "props": {}
};