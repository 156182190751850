import { colors } from "@src/theme";
import { CreateResponsiveStyle } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    i_background: {
      width: "100%",
      height: "100%",
      position: "absolute"
    },
    i_logo: {
      bottom: "36%",
      width: "20%",
      height: "40%",
      position: "absolute",
      alignSelf: "center",
    },

    // PROGRESS BAR
    progress_container: {
      height: "100%",
      borderRadius: 15,
      overflow: "hidden",
      backgroundColor: colors.progress,
    },
    t_loading: {
      fontSize: 16,
      bottom: "30%",
      fontWeight: "500",
      color: colors.white,
      alignSelf: "center",
      position: "absolute",
    },
    v_progress: {
      height: "5%",
      width: "30%",
      bottom: "23%",
      overflow: "hidden",
      alignItems: "center",
      justifyContent: "center",
      alignSelf: "center",
      position: "absolute",
    },
    i_gameworkz: {
      width: "20%",
      height: "11%",
      position: "absolute",
      left: "6%",
      bottom: "10%",
    },
    i_progress_bg: {
      width: "100%",
      height: "100%",
      overflow: "hidden",
      position: "absolute",
    },
    v_maskprogress: {
      width: "98%",
      height: "80%",
      borderRadius: 100,
    },
    v_mask: {
      left: 0,
      width: "1%",
      height: "80%",
      position: "absolute",
      backgroundColor: colors.progress,
    },
    t_version: {
      bottom: "3%",
      fontSize: 12,
      position: "absolute",
      color: colors.white,
    },
  },
);

export default useStyles;
