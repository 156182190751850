import images from "@assets/images";
import { DimensionContext } from "@src/DimensionProvider";
import React, { useMemo } from "react";
import { Image, Text, View } from "react-native";
import useStyles from "./styles.css";

interface DetailsProps {
  subject: string;
}

const getTopLabel = (subject: string) => {
  const lower = subject.toLowerCase();

  if (lower.includes("first")) {
    return { label: "FIRST", amount: "1,000,000" };
  } else if (lower.includes("second")) {
    return { label: "SECOND", amount: "500,000" };
  } else if (lower.includes("third")) {
    return { label: "THIRD", amount: "200,000" };
  } else{
    return {};
  }
};

const TopPlace = (props: DetailsProps) => {
  const styles = useStyles();
  const { baseWidth } = React.useContext(DimensionContext);

  const spot: any = useMemo(
    () => getTopLabel(props.subject || ""),
    [props.subject]
  );

  return (
    <View style={styles.v_top_body}>
      <View style={[styles.v_top_player]}>
        <Image
          resizeMode="stretch"
          style={styles.i_trophy}
          source={{uri: images.ic_trophy}}
        />
        <View style={styles.top_info}>
          <Text style={[styles.t_congrats, { fontSize: baseWidth * 0.024 }]}>
          CONGRATULATIONS!
          </Text>
          <Text style={[styles.t_place, { fontSize: baseWidth * 0.018  }]}>
          You’ve place
            {" "}<Text style={styles.t_place_color}>{spot.label}</Text>{" "}
          in this weeks tournament
          </Text>
        </View>
        <View style={styles.top_silver}>
          <Image
            style={styles.i_icon}
            source={{uri: images.silver_bar}}
            resizeMode="stretch"
          />
          <Text
            style={[styles.t_silver_amount, { fontSize: baseWidth * 0.019 }]}
          >
            {spot.amount}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default TopPlace;
