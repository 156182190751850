import React from "react";
import { useNavigation } from "@react-navigation/native";
import { useSettingsService } from "@src/ducks/hooks";
import { ImageButton05 } from "components/button";
import SVGSettings from "@assets/svg/settings";
import { View, Linking } from "react-native";
import useStyles from "./styles.css";
import Alert from "utils/alert";

const Policy = ({baseWidth, baseHeight}: any) => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const { onDeactivatingRequest } = useSettingsService();
  const onShare = () => Linking.openURL("https://www.facebook.com/Fortune8Games?_rdc=2&_rdr");

  const onDeactivate = () => {
    Alert(
      "Deactivate Account",
      "Are you sure you want to deactivate your account?",
      [
        {
          text: "Okay",
          onPress: () => onDeactivatingRequest(),
        },
      ],
      { cancelable: false }
    );
  };

  return (
    <View style={styles.v_policy}>
      <ImageButton05 scaleSize={0.98} onPress={() => navigation.navigate("Disclaimer")} >
        <SVGSettings name="btn-disclaimer" width={baseWidth * 0.25} height={baseHeight * 0.083} />
      </ImageButton05>
      <ImageButton05 scaleSize={0.98} onPress={onShare} >
        <SVGSettings name="btn-share" width={baseWidth * 0.2627} height={baseHeight * 0.083} />
      </ImageButton05>
      <View style={{width: baseWidth * 0.03}} />
      <ImageButton05 scaleSize={0.98} onPress={onDeactivate} >
        <SVGSettings name="btn-deactivate" width={baseWidth * 0.2372} height={baseHeight * 0.088} />
      </ImageButton05>
    </View>
  );
};

export default Policy;
