import React from "react";
import { useNavigation } from "@react-navigation/native";
import { ImageButton05 } from "components/button";
import SVGSettings from "@assets/svg/settings";
import useStyles from "./styles.css";
import { View } from "react-native";
import Switch from "./Switch";

export default ({baseWidth, baseHeight}: any) => {
  const styles = useStyles();
  const navigation = useNavigation<any>();

  return (
    <View style={[styles.v_fbandsound]}>
      <ImageButton05 scaleSize={0.98} onPress={() => navigation.navigate("TierLevel")} >
        <SVGSettings name="btn-tier-level" width={baseWidth * 0.4} height={baseHeight * 0.088} />
      </ImageButton05>
      <View style={[styles.v_sound, { width: baseWidth * 0.4, height: baseHeight * 0.088, paddingRight: baseWidth * 0.03}]}>
        <View style={{position: "absolute"}}>
          <SVGSettings name="bg-sound" width={baseWidth * 0.4} height={baseHeight * 0.088} />
        </View>
        <Switch />
      </View>
    </View>
  );
};
