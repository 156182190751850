import React from "react";
import { View, useWindowDimensions } from "react-native";
import useStyles from "./styles.css";

interface AuthProviderProps {
  children: React.ReactElement;
}

interface ContextValue {
  baseWidth: number;
  baseHeight: number;
}

export const ResponsiveContext = React.createContext<ContextValue>(
  {
    baseWidth: 0,
    baseHeight: 0
  } as ContextValue
);

const ResponsiveProvider = ({ children }: AuthProviderProps) => {
  const styles = useStyles();
  const { width } = useWindowDimensions();
  const baseHeight = React.useMemo(() => {
    return width * 0.1005;
  },[width]);

  return (
    <ResponsiveContext.Provider value={{ baseWidth: width, baseHeight }}>
      <View pointerEvents="auto" style={[styles.vw_topnav, { height: baseHeight}]}>
        {children}
      </View>
    </ResponsiveContext.Provider>
  );
};

export default ResponsiveProvider;
