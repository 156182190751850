import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { WEB_URL } from "@env";
import { StackActions, useNavigation, useRoute } from "@react-navigation/native";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useErrorService, useLobbyService } from "@src/ducks/hooks";
import { selectedUserEmail, selectedUserName, selectedUserSession } from "@src/ducks/slices/user.slice";
import _ from "lodash";
import moment from "moment";
import React, { createContext, useCallback } from "react";
import { useFetchApcopay, useFetchBitpace } from "utils/api/hooks";
import NewWindowPayment from "./new-window-payment";

interface ContextValue {
  loading: boolean;
  onApcopayCreditCard: () => void;
  onBitpaceCreateURL: () => void;
}

export const PaymentContext = createContext<ContextValue>({
} as ContextValue);

interface HomeProviderProps {
  children: React.ReactElement;
}


const PaymentProvider = ({ children }: HomeProviderProps) => {
  const route = useRoute<any>();
  const sendDataToGTM = useGTMDispatch();
  const navigation = useNavigation<any>();
  const isJadeEgg = route?.params?.isJadeEgg;
  const productId = route?.params?.productId;
  const user = useAppSelector(selectedUserName);
  const email = useAppSelector(selectedUserEmail);
  const { onSetErrorMessage } = useErrorService();
  const [url, setURL] = React.useState<string>("");
  const session = useAppSelector(selectedUserSession);
  const { onUpdateMissions, onPurchaseSuccess } = useLobbyService();
  const {loading, data, error, runRequest, runReset } = useFetchApcopay();
  const {loading: loadBitpace, data: bitpaceData, 
    error: bitpaceError, runRequest: bitpaceRunrequest, runReset: bitpaceRunReset } = useFetchBitpace();

  const onApcopayCreditCard = useCallback(() => {
    const timestamp = +moment();
    const params = {
      "clientacc": session.userId,
      "email": email,
      "mobileno": "",
      "oref": `${session.userId}${productId}${timestamp}`,
      "productName": productId,
      "productType": route?.params?.isSubscription ? "subscription" : "product",
      "redirectionURL": `${WEB_URL}/apcopay`,
      "value": route?.params?.amount,
      token: session.token,
    };

    const transaction = {
      userID: session.userId,
      amount: route?.params?.amount,
      currency: route?.params?.currency,
      status: "PENDING",
      paymentTypeID: 8,
      purchaseItem: productId,
      emailAddress: email,
    };

    runRequest(params, transaction);
  },[session, email, route.params]);

  const onBitpaceCreateURL = useCallback(() => {
    const productId = route?.params?.productId;
    const params = {
      order_amount: route?.params?.amount,
      currency: route?.params?.currency,
      return_url: `${WEB_URL}/bitpace/success`,
      failure_url: `${WEB_URL}/bitpace/error`,
      merchant_name: "GW'z Fortune 8",
      description: "Gameworkz Fortune 8",
      customer: {
        reference_id: `${session.userId}-${productId}`,
        first_name: user.firstName,
        last_name: user.lastName,
        email: email,
      },
    };
    
    const transaction = {
      userID: session.userId,
      amount: route?.params?.amount,
      currency: route?.params?.currency,
      status: "PENDING",
      paymentTypeID: 4,
      purchaseItem: productId,
      emailAddress: email,
    };

    bitpaceRunrequest(params, transaction);
  },[session, user, email, route.params]);

  const onPaymentSuccess = () => {
    const transId = `${session.userId}${productId}${+moment()}`;
    sendDataToGTM({
      event: "purchase",
      ecommerce: {
        transaction_id: transId,
        user_id: session.userId,
        email: email,
        value: route?.params?.amount,
        currency: route?.params?.currency,
        paymentTypeID: 8,
        tax: 0,
        shipping: 0,
        items: [
          {
            item_id: _.uniqueId(`id-${moment()}`),
            item_name: productId,
            currency: route?.params?.currency,
            price: route?.params?.currency,
            paymentTypeID: 8,
            quantity: 1,
          },
        ],
      },
    });
    if(isJadeEgg){
      navigation.goBack();
      const timeout = setTimeout(() => {
        clearTimeout(timeout);
        onPurchaseSuccess({...session, isJadeEgg});
      }, 400);
    }else{
      onUpdateMissions("purchase 1x on shop");
      navigation.dispatch(StackActions.replace("PaymentConfirm", route?.params));
    }
  };

  React.useEffect(() => {
    if(!_.isEmpty(data)){
      setURL(data);
      runReset();
    }
    if(!_.isEmpty(bitpaceData)){
      setURL(bitpaceData.payment_url);
      bitpaceRunReset();
    }
    if(!_.isEmpty(bitpaceError)){
      onSetErrorMessage(bitpaceError.message);
    }
    if(!_.isEmpty(error)){
      onSetErrorMessage(error.message);
    }
  },[data, bitpaceData, error, bitpaceError]);

  return (
    <PaymentContext.Provider value={{ loading: loading || loadBitpace, onApcopayCreditCard, onBitpaceCreateURL }}>
      {children}
      {!_.isEmpty(url) && <NewWindowPayment 
        url={url} onClose={() => setURL("")} onPaymentSuccess={onPaymentSuccess} />}
    </PaymentContext.Provider>
  );
};

export default PaymentProvider;
