import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { useRoute } from "@react-navigation/native";
import { useLobbyService } from "@src/ducks/hooks";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { ActivityIndicator, View, useWindowDimensions } from "react-native";
import useStyles from "./styles.css";

const PaypalButtons = () => {
  const styles = useStyles();
  const route = useRoute<any>();
  const { height } = useWindowDimensions();
  const amount = route?.params?.price;
  const productId = route?.params?.name;
  const { onPurchaseItem } = useLobbyService();
  const [{ isPending }] = usePayPalScriptReducer();

  return (
    <>
      {isPending && (
        <View style={[styles.v_spinner, { height: height * 0.2}]}>
          <ActivityIndicator
            animating
            size="large"
            color="black"
            style={[styles.indicator]}
          />
        </View>
      )}
      <PayPalButtons
        style={{ disableMaxWidth: true }}
        forceReRender={[amount]}
        fundingSource={undefined}
        createOrder={(data, actions) => {
          return actions.order
            .create({
              purchase_units: [
                {
                  amount: {
                    currency_code: "USD",
                    value: amount,
                  },
                },
              ],
            })
            .then((orderId) => {
              // Your code here after create the order
              return orderId;
            });
        }}
        onApprove={function (data, actions: any) {
          return actions.order.capture().then(function (details: any) {
            // Your code here after capture the order
            const purchase_units = details.purchase_units[0] || {};

            console.log("details.purchase_units", purchase_units);

            if (details.status === "COMPLETED" && !_.isEmpty(purchase_units)) {
              const create_time = moment(new Date(details.create_time));
              const params = {
                transactionNo: details.id,
                emailAddress: details.payer.email_address,
                paymentTypeID: purchase_units?.soft_descriptor ? 2 : 1,
                amount: _.toNumber(purchase_units?.amount?.value ?? 0),
                currency: purchase_units?.amount?.currency_code ?? "USD",
                transactionDate: create_time.format("YYYY-MM-DD HH:mm:ss"),
                status: details.status,
                silverCoin: route?.params?.value ?? route?.params?.rewards,
                isSubscription: route?.params?.isSubscription,
                purchaseItem: productId,
              };

              onPurchaseItem(params);
            }
          });
        }}
      />
    </>
  );
};

export default React.memo(PaypalButtons);
