import { sendToGTM } from "@elgorditosalsero/react-gtm-hook";
import { SagaIterator } from "@redux-saga/core";
import qs from "qs";
import { call, delay, put, select, takeEvery } from "redux-saga/effects";

// Types
import * as Types from "../types";

// API
import {
  check3hoursReward,
  checkDailyReward,
  checkWeeklyMissions,
  collectMissionReward,
  collectSigningBonus,
  createMyFavoriteGames,
  deleteMyFavoriteGames,
  fetchAvatarTier,
  fetchFavouriteGames,
  fetchFeaturesGames,
  fetchGames,
  fetchMissions,
  fetchNotifications,
  fetchOneTimeShow,
  fetchProducts,
  fetchRandomRewards,
  fetchSubscriptionProducts,
  fetchTierUpgradeBonuses,
  fetchUserDetails,
  gameCategoriesAvailable,
  jadeEggAccumulation,
  purchaseItem,
  recordPurchase,
  recordPurchaseJade,
  recordPurchaseSubscription,
  walletBalance
} from "@src/utils/api";

// Slice
import _ from "lodash";
import { checkDismissalStatus, schedulePopup } from "utils/filter-helper";
import { compileTransformation, switchSelectedFavoriteGames, updatedCategories } from "utils/transform-helper";
import { authActions, selectedAuthToken } from "../slices/auth.slice";
import { gamesActions, selectedFetchGames, selectedGameCategoryID, selectedGoldAllowStatus } from "../slices/games.slice";
import { lobbyActions } from "../slices/lobby.slice";
import { tierActions } from "../slices/tier.slice";
import { selectedUserEmail, selectedUserNewUser, selectedUserUserID, userActions } from "../slices/user.slice";
import { PROMOTION_POPUPS } from "utils/constants";

function* handleLobbyRequest(): SagaIterator {
  try {
    const accessToken = yield select(selectedAuthToken);

    const userDetails = yield call(fetchUserDetails, accessToken);
    yield put(userActions.fetchUserDetails(userDetails));
    const userId = userDetails.id;

    const jadeEgg = yield call(jadeEggAccumulation, { userId }, accessToken);
    yield put(lobbyActions.jadeAmount(jadeEgg.data.amount));

    // const userRank = yield call(fetchUserRank, { userId }, accessToken);
    // yield put(lobbyActions.userRank(userRank));

    const randomRewards = yield call(fetchRandomRewards, { userId }, accessToken);
    yield put(lobbyActions.randomRewards(randomRewards.data));

    const missions = yield call(fetchMissions, { userId }, accessToken);
    yield put(lobbyActions.missions(missions.data));

    const avatarTier = yield call(fetchAvatarTier, { userId }, accessToken);
    yield put(lobbyActions.avatarTier(avatarTier));

    const fetchCategorie = yield call(gameCategoriesAvailable, accessToken);
    const categoriesUpdate = yield call(updatedCategories, fetchCategorie?.items ?? []);
    yield put(gamesActions.fetchedCategoryAvailability(categoriesUpdate));

    const featureGame = yield call(fetchFeaturesGames, "", accessToken);
    yield put(gamesActions.fetchedFeatureGames(featureGame.items || []));

    const gamesOrigin = yield call(fetchGames, "", accessToken);
    const gamesTransform = yield call(compileTransformation, gamesOrigin?.tiers ?? []);
    yield put(gamesActions.fetchedGames(gamesTransform));

    const serverTime = yield call(checkDailyReward, { userId }, accessToken);
    yield put(lobbyActions.serverTime(serverTime));

    const weeklyMissionActive = yield call(checkWeeklyMissions, { userId }, accessToken);
    yield put(lobbyActions.isWeeklyMissionActive(weeklyMissionActive.data));

    const hourlyReward = yield call(check3hoursReward, { userId }, accessToken);
    yield put(lobbyActions.hourlyReward(hourlyReward));

    const notifications = yield call(fetchNotifications, { userId }, accessToken);
    yield put(lobbyActions.notifications(notifications.data));

    const products = yield call(fetchProducts, accessToken);
    const subscription = yield call(fetchSubscriptionProducts, accessToken);
    yield put(lobbyActions.products(products));
    yield put(lobbyActions.subscriptionProducts(subscription.items));

    const tierUpgradeBonuses = yield call(fetchTierUpgradeBonuses, { userId }, accessToken);
    yield put(tierActions.tierUpgradeBonuses(tierUpgradeBonuses.content));

    const oneTimeShow = yield call(fetchOneTimeShow, userId, accessToken);
    const dismissStatus = yield call(checkDismissalStatus, oneTimeShow.ctaMessages || []);
    yield put(lobbyActions.oneTimeShowFavourite(dismissStatus["ADD_GAME_TO_FAVOURITES"]));
    yield put(lobbyActions.oneTimeShowHowToPlay(dismissStatus["HOW_TO_PLAY"]));

    yield put(lobbyActions.lobbySuccess());
  } catch (error: any) {
    if(error.status === 401 && error.error === "Unauthorized"){
      yield put(authActions.logout());
      yield put(authActions.setErrorMessage("Session Expired"));
    }
    yield put(lobbyActions.lobbyFailure(error?.error ?? error));
  }
}

function* handleCollectSigningBonus(action: {
  type: typeof lobbyActions.signingBonusRequest;
  payload: Types.UserDetailValue;
}): SagaIterator {
  try {
    const accessToken = yield select(selectedAuthToken);
    yield call(collectSigningBonus, action.payload, accessToken);
    const balance = yield call(walletBalance, action.payload, accessToken);
    yield put(userActions.updateWallet(balance.data));

    yield put(lobbyActions.signingBonusSuccess());
    yield put(userActions.updateUserRewards(action.payload.userRewardsId));
  } catch (error: any) {
    if(error.status === 401 && error.error === "Unauthorized"){
      yield put(authActions.logout());
      yield put(authActions.setErrorMessage("Session Expired"));
    }
    yield put(lobbyActions.signingBonusFailed());
  }
}

function* handleMissionReward(): SagaIterator {
  try {
    const userId = yield select(selectedUserUserID);
    const accessToken = yield select(selectedAuthToken);

    yield call(collectMissionReward, { userId }, accessToken);
    yield put(lobbyActions.isWeeklyMissionActive(true));

    const balance = yield call(walletBalance, { userId }, accessToken);
    yield put(userActions.updateWallet(balance.data));

    yield put(lobbyActions.signingBonusSuccess());
  } catch (error: any) {
    if(error.status === 401 && error.error === "Unauthorized"){
      yield put(authActions.logout());
      yield put(authActions.setErrorMessage("Session Expired"));
    }
    yield put(lobbyActions.signingBonusFailed());
  }
}

function* handlePurchaseRequest(action: {
  type: typeof lobbyActions.purchaseRequest;
  payload: Types.Transaction;
}): SagaIterator {
  try {
    const userId = yield select(selectedUserUserID);
    const accessToken = yield select(selectedAuthToken);
    const emailAddress = yield select(selectedUserEmail);
    const result = yield call(purchaseItem, {
      ...action.payload,
      userId,
    }, accessToken);

    if (action.payload.isSubscription) {
      const params = {
        userId: userId,
        paymentRefNo: action.payload.transactionNo,
        subscriptionId: action.payload.purchaseItem,
      };
      
      yield call(recordPurchaseSubscription, params, accessToken);
      yield put(lobbyActions.updateMissions("purchase 1x on shop"));
    }else{
      const params = {
        userId: userId,
        paymentRefNo: action.payload.transactionNo,
        purchaseItem: action.payload.purchaseItem,
      };
      yield call(recordPurchase, params, accessToken);
      yield put(lobbyActions.updateMissions("purchase 1x on shop"));
    }

    sendToGTM({
      dataLayerName: "purchase_event_layer",
      data: {
        event: "purchase_event",
        dataLayer: {
          ecommerce: {
            transaction_id: action.payload.transactionNo,
            user_id: userId,
            email: emailAddress,
            value: result.data.amount,
            currency: result.data.currency,
            paymentTypeID: action.payload.paymentTypeID,
            tax: 0,
            shipping: 0,
            items: [
              {
                item_id: result.data.id,
                item_name: action.payload.purchaseItem,
                currency: result.data.currency,
                price: result.data.amount,
                paymentTypeID: action.payload.paymentTypeID,
                quantity: 1,
              },
            ],
          },
        },
      }
    });

    console.log("HKSJDHKSJHKSJHFKJHF", {
      dataLayerName: "purchase_event_layer",
      data: {
        event: "purchase_event",
        dataLayer: {
          ecommerce: {
            transaction_id: action.payload.transactionNo,
            user_id: userId,
            email: emailAddress,
            value: result.data.amount,
            currency: result.data.currency,
            paymentTypeID: action.payload.paymentTypeID,
            tax: 0,
            shipping: 0,
            items: [
              {
                item_id: result.data.id,
                item_name: action.payload.purchaseItem,
                currency: result.data.currency,
                price: result.data.amount,
                paymentTypeID: action.payload.paymentTypeID,
                quantity: 1,
              },
            ],
          },
        },
      }
    });

    yield put(lobbyActions.purchaseSuccess({ ...action.payload, ...result }));
    yield delay(1000);
    yield put(lobbyActions.purchaseSuccess({}));
  } catch (error: any) {
    if(error.status === 401 && error.error === "Unauthorized"){
      yield put(authActions.logout());
      yield put(authActions.setErrorMessage("Session Expired"));
    }
    yield put(lobbyActions.purchaseFailure());
  }
}

function* handlePurchaseSuccess(action: {
  type: typeof lobbyActions.purchaseSuccess;
  payload: Types.Transaction;
}): SagaIterator {
  try {
    const accessToken = yield select(selectedAuthToken);
    const userId = yield select(selectedUserUserID);

    if(action.payload.purchaseItem === PROMOTION_POPUPS.JADE_EGG){
      yield call(recordPurchaseJade, { userId }, accessToken);
      const jadeEgg = yield call(jadeEggAccumulation, { userId }, accessToken);
      yield put(lobbyActions.jadeAmount(jadeEgg.data.amount));
    }

    const tierUpgradeBonuses = yield call(fetchTierUpgradeBonuses, { userId }, accessToken);
    yield put(tierActions.tierUpgradeBonuses(tierUpgradeBonuses.content));

  } catch (error: any) {
    if(error.status === 401 && error.error === "Unauthorized"){
      yield put(authActions.logout());
      yield put(authActions.setErrorMessage("Session Expired"));
    }
  }
}

function* handleBalanceRequest(): SagaIterator {
  try {
    const userId = yield select(selectedUserUserID);
    const accessToken = yield select(selectedAuthToken);
    const balance = yield call(walletBalance, { userId }, accessToken);
    yield put(userActions.updateWallet(balance.data));
  } catch (error: any) {
    if(error.status === 401 && error.error === "Unauthorized"){
      yield put(authActions.logout());
      yield put(authActions.setErrorMessage("Session Expired!"));
    }
  }
}


function* handleFetchingGameByCategory(action: {
  type: typeof gamesActions.fetchingGames;
  payload: number | string;
}): SagaIterator {
  try {
    const accessToken = yield select(selectedAuthToken);
    const isGoldAllow = yield select(selectedGoldAllowStatus);
    const params: any = {};

    if(isGoldAllow){
      params.isGoldAllow = 1;
    }

    if(action.payload === 0){
      const convertToString = qs.stringify(params);
      const favGames = yield call(fetchFavouriteGames, convertToString, accessToken);
      const newArray = [ { games: favGames.items } ];
      const gamesTransform = yield call(compileTransformation, newArray);
      yield put(gamesActions.fetchedGames(gamesTransform));
      return;
    }

    params.gameCategoryId = action.payload;

    if(action.payload === -1){
      delete params.gameCategoryId;
    }

    const convertToString = qs.stringify(params);
    const gamesOrigin = yield call(fetchGames, convertToString, accessToken);
    const gamesTransform = yield call(compileTransformation, gamesOrigin?.tiers ?? []);
    yield put(gamesActions.fetchedGames(gamesTransform));
  } catch (error: any) {
    if(error.status === 401 && error.error === "Unauthorized"){
      yield put(authActions.logout());
      yield put(authActions.setErrorMessage("Session Expired!"));
    }
    yield put(gamesActions.fetchingGamesFailed(error?.error ?? error));
  }
}

function* handleFetchingGameByGoldCurrency(action: {
  type: typeof gamesActions.fetchGoldGames;
  payload: number | string;
}): SagaIterator {
  try {
    const accessToken = yield select(selectedAuthToken);
    const gameCategoryId = yield select(selectedGameCategoryID);
    const params: any = {};
    if(action.payload){
      params.isGoldAllow = 1;
    }

    if(gameCategoryId === 0){
      const convertToString = qs.stringify(params);
      const favGames = yield call(fetchFavouriteGames, convertToString, accessToken);
      const newArray = [ { games: favGames.items } ];
      const gamesTransform = yield call(compileTransformation, newArray);
      yield put(gamesActions.fetchedGames(gamesTransform));
      return;
    }

    params.gameCategoryId = gameCategoryId;

    if(gameCategoryId === -1){
      delete params.gameCategoryId;
    }

    const convertToString = qs.stringify(params);
    const gamesOrigin = yield call(fetchGames, convertToString, accessToken);
    const gamesTransform = yield call(compileTransformation, gamesOrigin?.tiers ?? []);
    yield put(gamesActions.fetchedGames(gamesTransform));
  } catch (error: any) {
    if(error.status === 401 && error.error === "Unauthorized"){
      yield put(authActions.logout());
      yield put(authActions.setErrorMessage("Session Expired!"));
    }
    yield put(gamesActions.fetchingGamesFailed(error?.error ?? error));
  }
}

function* handleChangeFavoriteGames(action: {
  type: typeof gamesActions.fetchFavouriteGames;
  payload: any;
}): SagaIterator {
  try {
    const token = yield select(selectedAuthToken);
    const games = yield select(selectedFetchGames);
    const userId = yield select(selectedUserUserID);
    const accessToken = yield select(selectedAuthToken);
    const gameCategory = yield select(selectedGameCategoryID);
    const isGoldAllow = yield select(selectedGoldAllowStatus);
    const updatedGames = yield call(switchSelectedFavoriteGames, games, action.payload.id);
    yield put(gamesActions.fetchedGames(updatedGames));

    if(action.payload.isFavourite){
      yield call(deleteMyFavoriteGames, {
        "gameId": action.payload.id,
        "userId": userId
      },token);
      if(gameCategory === 0){
        const params: any = {};

        if(isGoldAllow){
          params.isGoldAllow = 1;
        }

        const convertToString = qs.stringify(params);
        const favGames = yield call(fetchFavouriteGames, convertToString, accessToken);
        const newArray = [ { games: favGames.items } ];
        const gamesTransform = yield call(compileTransformation, newArray);
        yield put(gamesActions.fetchedGames(gamesTransform));
      }
    }else {  
      yield call(createMyFavoriteGames, {
        "gameId": action.payload.id,
        "userId": userId
      }, token);
    }
  } catch (error: any) {

    console.log("handleChangeFavoriteGames", error);
    // empty
  }
}

function* handlePopupScreenToDisplay(): SagaIterator {
  try {
    const user = yield select(selectedUserNewUser);
    const popScreen = [];

    if (!_.isEmpty(user)) {
      popScreen.push("WelcomeBonus");
    }else {
      popScreen.push(schedulePopup());
    }

    yield put(lobbyActions.popupScreenToDisplay(popScreen));
  } catch (error: any) {
    // empty
  }
}

function* handleFetchingProducts(): SagaIterator {
  try {
    const accessToken = yield select(selectedAuthToken);
    const products = yield call(fetchProducts, accessToken);
    const subscription = yield call(fetchSubscriptionProducts, accessToken);
    yield put(lobbyActions.products(products));
    yield put(lobbyActions.subscriptionProducts(subscription.items));
  } catch (error: any) {
    yield put(lobbyActions.subscriptionProducts([]));
  }
}

// Watcher Saga
function* lobbyWatcherSaga(): SagaIterator {
  yield takeEvery(lobbyActions.lobbyRequest.type, handleLobbyRequest);
  yield takeEvery(lobbyActions.balanceRequest.type, handleBalanceRequest);
  yield takeEvery(gamesActions.fetchingGames.type, handleFetchingGameByCategory);
  yield takeEvery(gamesActions.fetchGoldGames.type, handleFetchingGameByGoldCurrency);
  yield takeEvery(gamesActions.fetchFavouriteGames.type, handleChangeFavoriteGames);
  yield takeEvery(lobbyActions.signingBonusRequest.type, handleCollectSigningBonus);
  yield takeEvery(lobbyActions.missionRewardRequest.type, handleMissionReward);
  yield takeEvery(lobbyActions.purchaseRequest.type, handlePurchaseRequest);
  yield takeEvery(lobbyActions.purchaseSuccess.type, handlePurchaseSuccess);
  yield takeEvery(lobbyActions.lobbySuccess.type, handlePopupScreenToDisplay);
  yield takeEvery(lobbyActions.fetchingProducts.type, handleFetchingProducts);
}

export default lobbyWatcherSaga;
