import { CreateResponsiveStyle } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    centerStyle: {
      top: 0,
      width: "90%",
      height: "87%",
      alignSelf: "center",
      alignItems: "center",
      position: "absolute",
      justifyContent: "center",
    },
    i_avatar_bg: {width: "100%", height: "100%"},
    i_avatar_shine: {
      zIndex: 3,
      top: "-13%",
      left: "-13%",
      position: "absolute",
    },
    i_avatar_shine2: {
      zIndex: 3,
      left: "10%",
      bottom: "24%",
      position: "absolute",
    },
    v_mask_avatar: {
      position: "absolute", 
      borderRadius: 100,
      overflow: "hidden", 
      alignItems: "center" 
    },
    i_avatar: { width: "100%", height: "100%", top: "4%" },
  },
);

export default useStyles;
