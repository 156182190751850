import images from "@assets/images";
import { useNavigation } from "@react-navigation/native";
import { useForgotService } from "@src/ducks/hooks";
import { ImageButton, ImageButton06 } from "components/button";
import { TextInput01 } from "components/textinput";
import React, { useEffect, useState } from "react";
import { Image, Text, View } from "react-native";
import useStyles from "./styles.css";
import { useDimensionContext } from "@src/DimensionProvider";

const EnterEmail = () => {
  const styles = useStyles();
  const navigation = useNavigation();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const { baseWidth } = useDimensionContext();
  const { onForgot, isLoading}= useForgotService();

  const onSubmit = () => {
    if (!email) {
      setError("Required.");
    } else {
      onForgot(email);
    }
  };

  useEffect(() => {
    setError("");
  }, [email]);

  return (
    <View style={[styles.bodyStyle]}>
      <Image
        source={images.bg_transparent}
        style={styles.imageBorderStyle}
        resizeMode="stretch"
      />
      <ImageButton
        onPress={() => navigation.goBack()}
        style={styles.btnClose}
        source={images.btn_close}
      />
      <View style={styles.viewEmail}>
        <Image
          resizeMode="contain"
          source={images.forgot}
          style={styles.forgotTextStyle}
        />
        <Text style={[styles.txtEmailNote, {marginTop: "8%", fontSize: baseWidth * 0.016}]}>Enter your email address</Text>
        <TextInput01
          value={email}
          error={error}
          placeholder="Email"
          secureTextEntry={false}
          onChangeText={setEmail}
          disableFullscreenUI={true}
          inputStyle={[styles.textAlign, { fontSize: baseWidth * 0.012 }]}
          containerStyle={styles.inputEmailStyle}
        />
        <ImageButton06
          onPress={onSubmit}
          isLoad={isLoading}
          label="SUBMIT"
          style={styles.btnSubmit}
          labelStyle={{ fontSize: baseWidth * 0.015 }}
        />
      </View>
    </View>
  );
};

export default EnterEmail;
