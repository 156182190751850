import EggJson from "@assets/json/egg.json";
import { useNavigation } from "@react-navigation/native";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedJadeAmount } from "@src/ducks/slices/lobby.slice";
import { NavigationScreenProps } from "@src/navigators/NavigatorTypes";
import { ImageButton02 } from "components/button";
import React from "react";
import Lottie from "react-lottie-player";
import Animated, {
  Extrapolate,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import { ResponsiveContext } from "../provider";
import useStyles from "./styles.css";

const Egg = () => {
  const styles = useStyles();
  const animated = useSharedValue(0);
  const jadeAmount = useAppSelector(selectedJadeAmount);
  const navigation = useNavigation<NavigationScreenProps<"JadeEgg">>();
  const { baseWidth, baseHeight } = React.useContext(ResponsiveContext);
  const segments: any = React.useMemo(() => {
    if(jadeAmount === 16000000){
      return [238, 295];
    }else if(jadeAmount >= 12000000 && jadeAmount < 16000000){
      return [220, 227];
    }else if(jadeAmount >= 8000000 && jadeAmount < 12000000){
      return [165, 170];
    }else if(jadeAmount >= 4000000 && jadeAmount < 8000000){
      return [95, 100];
    }else {
      return [1, 2];
    }
  },[jadeAmount]);

  const animateStyle = useAnimatedStyle(() => {
    const translateX = interpolate(
      animated.value,
      [0, 1],
      [0, baseWidth * 0.033],
      Extrapolate.CLAMP
    );

    return {
      transform: [{ translateX }, { translateY: 5}],
    };
  }, [baseWidth]);

  React.useEffect(() => {
    animated.value = withTiming(1, { duration: 1000});
  }, []);

  return (
    <Animated.View style={animateStyle}>
      <ImageButton02
        onPress={() => navigation.navigate("JadeEggShow")}
        style={[styles.container, { width: baseHeight * 0.5559, height: baseHeight * 0.7421 }]}
      >
        <Lottie
          play
          loop
          animationData={EggJson}
          segments={segments}
          style={{ alignSelf: "center", width: "100%", height: "100%"}}
        />
      </ImageButton02>
    </Animated.View>
  );
};

export default React.memo(Egg);
