import { colors } from "@src/theme";
import { StyleSheet } from "react-native";
import { CreateResponsiveStyle } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      backgroundColor: colors.translucent,
    },
    container2: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      ...StyleSheet.absoluteFillObject,
      backgroundColor: colors.translucent,
    },
    scrollview: {
      width: "100%",
      height: "100%",
      paddingTop: "2%",
    },
    bodyStyle: {
      width: "80%",
      height: "90%",
      alignItems: "center",
      justifyContent: "center",
    },
    imageBorderStyle: {
      width: "100%",
      height: "100%",
      ...StyleSheet.absoluteFillObject,
    },
    btn_close: {
      width: 50,
      height: 50,
      top: "6%",
      right: "6%",
      zIndex: 6,
      position: "absolute",
    },
    btn_read_more: {
      width: "16%",
      height: 60,
      left: "40%",
      bottom: "7%",
      position: "absolute",
    },

    v_center: {
      width: "96%",
      height: "94%",
      left: "-0.5%",
      alignItems: "center",
    },
    viewForgotStyle: {
      marginTop: "1%",
      width: "50%",
      height: "20%",
      alignItems: "center",
      justifyContent: "center",
    },
    viewEmail: {
      right: "1%",
      width: "93%",
      height: "55%",
      alignItems: "center",
      paddingHorizontal: "7%",
    },
    t_description: {fontFamily: "Roboto", color: colors.white, textAlign: "center"},

    // TIERINFOMORE
    btnSubmit: {
      width: "16%",
      left: "40%",
      bottom: "17%",
      position: "absolute",
    },
    btn_close2: {
      width: 50,
      height: 50,
      top: "3%",
      right: "5%",
      zIndex: 6,
      position: "absolute",
    },
    v_scroll: {
      top: "10%",
      right: "5%",
      zIndex: 6,
      position: "absolute",
    },
    v_content: {
      width: "100%",
      height: "92%",
      // top: "1%",
      alignItems: "center",
    },
    t_regular: {
      fontFamily: "Roboto", 
      color: colors.white
    },
    v_info_more: {
      left: "15%",
      width: "80%",
      height: "180%",
    },
    btn_close_more: {
      width: 50,
      height: 50,
      top: "3%",
      right: "6%",
      zIndex: 6,
      position: "absolute",
    },
  },
);

export default useStyles;
