import { getStateFromPath } from "@react-navigation/native";
import * as Linking from "expo-linking";
import _ from "lodash";
import qs from "qs";

const prefix = Linking.createURL("/");
export const config: any = {
  screens: {
    SignInOption: "signin-option",
    SignIn: "signin",
    Forgot: "forgot",
    SignUp: "signup",
    ResetPassword: "reset-password",

    Home: "home",
    WelcomeBonus: "welcome-bonus",
    DailyBonus: "daily-bonus",
    EditProfile: "update-profile",
    CollectBonus: "congratulations",
    JadeEggFull: "jadeeggfull",
    JadeEggShow: "jade-egg",
    Mission: "mission",
    BuyShop: "buyshop",
    PaymentGateway: "paymentgateway",
    PaymentConfirm: "payment-confirm",
    BitpaceResult: "bitpace/:status",
    Apcopay: "apcopay/:status",
    StarterPack: "starter-pack",
    OpeningSpecial: "opening-special",
    GameMode: "game-mode",
    Settings: "settings",
    AccountVerification: "account/verify",
  },
};

const linking: any = {
  prefixes: [prefix, "https://gameworkz.page.link", "https://www.gameworkz.io"],
  config,
  async getInitialURL() {
    return await Linking.getInitialURL();
  },
  subscribe(listener: any) {
    const onReceiveURL = ({ url }: { url: string }) => listener(url);
    const subscription = Linking.addEventListener("url", onReceiveURL);

    return () => subscription.remove();
  },

  getStateFromPath: (path: string) => {
    if (path.includes("reset_password")) {
      const parsePath: any = qs.parse(path.replace("/", ""));
      const reset_password = parsePath.reset_password;
      const split = _.split(reset_password, "-");
      const email = split[1] || "";
      const otp = split[0] || 0;

      return {
        routes: [
          { name: "SignInOption" },
          { name: "ResetPassword", params: { otp, email } }
        ],
      };
    } else if (path.includes("starter-pack") || path.includes("game-mode") || 
      path.includes("welcome-bonus") || path.includes("daily-bonus") ||
      path.includes("paymentgateway") || path.includes("mission") || 
      path.includes("opening-special")  || path.includes("jade-egg")) {
      return {
        routes: [{ name: "Home" }],
      };
    } else if (path.includes("buyshop")) {
      return {
        routes: [{ name: "Home" }, { name: "BuyShop" }],
      };
    } else if (path.includes("apcopay")) {
      const parsePath: any = qs.parse(path.replace("/apcopay?", ""));
      const status = parsePath?.params?.includes("<Result>OK</Result>") ? "success" : "error";
      return {
        routes: [ { name: "Apcopay", params: { status } }],
      };
    } else if (path.includes("signup")) {
      return {
        routes: [ { name: "SignInOption" },  { name: "SignUp" }],
      };
    }

    return getStateFromPath(path, config);
  },
};

export default linking;
