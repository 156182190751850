import images from "@assets/images";
import { useAuthContext } from "@src/AuthProvider";
import { useDimensionContext } from "@src/DimensionProvider";
import { useLobbyService } from "@src/ducks/hooks";
import { Button02 } from "components/button";
import Loading from "components/loading/Loading";
import React, { useMemo } from "react";
import { Image, Pressable, Text, View } from "react-native";
import { deleteNotification, readNotification } from "utils/api";
import { useFetchPost } from "utils/api/hooks";
import useStyles from "./styles.css";

const getImage = (subject: string, thumbnailImage: string) => {
  if (subject.toLowerCase().includes("welcome")) {
    return images.ic_fortune;
  } else if (subject.toLowerCase().includes("first")) {
    return images.ic_trophy_gold;
  } else if (subject.toLowerCase().includes("second")) {
    return images.ic_trophy_silver;
  } else if (subject.toLowerCase().includes("third")) {
    return images.ic_trophy_bronze;
  } else {
    return thumbnailImage;
  }
};

const InboxItem = ({ item, detailsChange }: any) => {
  const styles = useStyles();
  const { onErrorMessage } = useAuthContext();
  const { baseWidth } = useDimensionContext();
  const { onUpdateNotifications } = useLobbyService();
  const { loading, runCallbackRequest } = useFetchPost();
  const icon = useMemo(() => getImage(item.subject, item?.mail?.thumbnailImage), [item]);

  const handleRead = () => {
    runCallbackRequest(
      async() => await readNotification(item.id),
      ({ data }) => {
        onUpdateNotifications(data);
        if(data.status === "READ"){
          detailsChange(data);
        }
      },
      (error) => {
        onErrorMessage(error?.message);
      }
    );
  };

  const handleDelete = () => {
    runCallbackRequest(
      async() => await deleteNotification(item.id),
      ({ data }) => {
        onUpdateNotifications(data);
      },
      (error) => {
        onErrorMessage(error?.message);
      }
    );
  };

  return (
    <View
      style={[
        styles.v_item,
        {
          width: baseWidth * 0.12,
          height: baseWidth * 0.125,
        },
      ]}
    >
      <Pressable onPress={handleRead} style={[styles.v_thumbnail]} >
        <Image style={[styles.i_item_icon]} 
          source={{uri: images[item?.status === "UNREAD" ? "inbox-item-unread-bg" : "inbox-item-bg"]}} 
          resizeMode="stretch" />
        <View style={[styles.v_thumnail_source, { borderRadius: baseWidth * 0.006}]}>
          <Image style={[styles.i_item_icon]} source={{uri: icon}} resizeMode="stretch" />
        </View>
        {item?.status === "UNREAD" && 
        <View style={[styles.v_unread_indicator, 
          { width: baseWidth * 0.014, 
            height: baseWidth * 0.014, 
            borderRadius: baseWidth * 0.014 / 2
          }]} />}
        <Button02 style={styles.btn_close2} onPress={handleDelete}>
          <Image style={{width: baseWidth * 0.022, height: baseWidth * 0.022}} source={{uri: images["inbox-close"]}} resizeMode="stretch" />
        </Button02>
        <Loading isLoading={loading} />
      </Pressable>
      <View style={[styles.v_subject]}>
        <Text numberOfLines={2} style={[styles.t_notif, { fontSize: baseWidth * 0.008}]}> 
          {item.subject}
        </Text>
      </View>
    </View>
  );
};

export default React.memo(InboxItem);
