import images from "@assets/images";
import { useDimensionContext } from "@src/DimensionProvider";
import React from "react";
import { Image, ScrollView, Text, View } from "react-native";
import useStyles from "./styles.css";

const Details = () => {
  const styles = useStyles();
  const { baseWidth } = useDimensionContext();

  return (
    <View style={styles.v_body}>
      <View style={[styles.default_details]}>
        <View style={styles.v_info}>
          <ScrollView showsVerticalScrollIndicator={false}>
            <Text style={[styles.t_subject, { fontSize: baseWidth * 0.019}]}>Добре дошъл в Game Zone!</Text>
            <Text style={[styles.t_details, { fontSize: baseWidth * 0.0125}]}>
            Вече си част от семейството на Gamezone! Наслади се на безплатните бонуси и играй на любимите си игри още сега. Не забравяй да персонализираш профила си, като си избереш аватар и уникално име. Очакваме те в ежеседмичната класация, състезавайки се за спечелването на още БОНУС МОНЕТИ.
            </Text>
          </ScrollView>
        </View>
        <View style={styles.v_welcome}>
          <Image style={styles.i_welcome_icon} source={{uri: images.Avatar_boy_3}} resizeMode="contain" />
          <Image style={styles.i_welcome_logo} source={{uri: images["gamezone-logo"]}} resizeMode="contain" />
          <Image style={styles.i_welcome_girl} source={{uri: images.Avatar_girl_1}} resizeMode="contain" />
        </View>
      </View>
    </View>
  );
};

export default Details;
