import React, { createContext, useContext } from "react";
import { Dimensions, StyleSheet, View, useWindowDimensions } from "react-native";
import { selectedGModeURL } from "./ducks/slices/gmode.slice";
import { useAppSelector } from "./ducks/ducksHook";
import { isMobile } from "react-device-detect";
import { colors } from "./theme";

interface ContextValue {
  baseWidth: number;
  height: number;
  baseHeight: number;
  hudHeight: number;
  maxHeight: number;
  frameHeight: number;
}

const SCREEN_WIDTH = Dimensions.get("screen").width;
const SCREEN_HEIGHT = Dimensions.get("screen").height;

const minHeight = Math.min(SCREEN_WIDTH, SCREEN_HEIGHT);

export const DimensionContext = createContext<ContextValue>({
  baseWidth: 0,
  height: 0,
  baseHeight: 0,
  hudHeight: 0,
  maxHeight: 0,
  frameHeight: 0,
} as ContextValue);

interface DimensionProviderProps {
  children: React.ReactElement;
}

const DimensionProvider = ({ children }: DimensionProviderProps) => {
  const { width, height } = useWindowDimensions();
  const gameLink = useAppSelector(selectedGModeURL);

  const baseSize = React.useMemo(() => {
    const baseHeight = width * 0.3614;
    return {
      baseWidth: width,
      baseHeight: baseHeight,
      maxHeight: width * 0.5625,
      hudHeight: width * 0.1005,
      frameHeight: baseHeight * 0.5893,
    };
  },[width]);

  return (
    <DimensionContext.Provider value={{ ...baseSize, height }}>
      <View style={styles.container}>
        <View style={[styles.bodyStyle, 
          { minHeight: isMobile ? minHeight : 0, 
            width, height, maxHeight: gameLink ? height : baseSize.maxHeight} ]}>
          {children}
        </View>
      </View>
    </DimensionContext.Provider>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.black1,
  },
  bodyStyle: {
    width: "100%",
    height: "100%",
    overflowX: "hidden",
    overflowY: "hidden",
  },
});

export const useDimensionContext = () => {
  return useContext(DimensionContext);
};

export default DimensionProvider;
