import React from "react";
import { Image, TouchableOpacity } from "react-native";
import { PaymentContext } from "../provider";
import useStyles from "./styles.css";
import images from "@assets/images";

const BitpaceButton = () => {
  const styles = useStyles();
  const { onBitpaceCreateURL } = React.useContext(PaymentContext);

  return (
    <TouchableOpacity
      activeOpacity={0.9}
      style={styles.btn_buynow}
      onPress={onBitpaceCreateURL} >
      <Image
        style={styles.i_bitpace}
        source={images.logo_bitpace}
        resizeMode="stretch" />
    </TouchableOpacity>
  );
};

export default BitpaceButton;
