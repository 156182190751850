import { useNavigation } from "@react-navigation/native";
import { DimensionContext } from "@src/DimensionProvider";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useLobbyService } from "@src/ducks/hooks";
import { selectedServerTime } from "@src/ducks/slices/lobby.slice";
import { ImageButton02 } from "components/button";
import React from "react";
import { Image } from "react-native";
import images from "@assets/images";
import CountDown from "./count-down";
import useStyles from "./styles.css";

const Bonus = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const { onServerTime } = useLobbyService();
  const dailyTimer = useAppSelector(selectedServerTime);
  const { hudHeight } = React.useContext(DimensionContext);

  return (
    <>
      <ImageButton02
        onPress={() => navigation.navigate("DailyBonus")}
        disabled={dailyTimer > 0}
        style={[styles.container, {
          width: hudHeight * 0.9733,
          height: hudHeight * 0.9733,
          borderRadius: hudHeight,
          left: hudHeight * 0.86,
          bottom: hudHeight * 0.26,
        }]}
      >
        <Image 
          style={styles.imageStyle} 
          source={dailyTimer > 0 ? images.daily_timer : images.btn_bonus} 
          resizeMode="stretch" />
        {dailyTimer > 0 && <CountDown onDone={() => onServerTime(0)}  dailyTimer={dailyTimer} baseHeight={hudHeight} />}
      </ImageButton02>
    </>
  );
};

export default Bonus;
