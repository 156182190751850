import images from "@assets/images";
import React, { useEffect } from "react";
import { Image } from "react-native";
import Animated, {
  Extrapolation,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import { CreateResponsiveStyle, DEVICE_SIZES } from "rn-responsive-styles";
const AnimatedImage = Animated.createAnimatedComponent(Image);

const CoinsInsert = ({ duration, transX, transY }: any) => {
  const styles = useStyles();
  const animated = useSharedValue(0);

  const animateStyle = useAnimatedStyle(() => {
    const translateX = interpolate(
      animated.value,
      [0, 1],
      [0, transX || -179],
      Extrapolation.CLAMP
    );

    const translateY = interpolate(
      animated.value,
      [0, 1],
      [0, transY || -160.57143020629883],
      Extrapolation.CLAMP
    );

    const scale = interpolate(
      animated.value,
      [0, 1],
      [1, 0.5],
      Extrapolation.CLAMP
    );

    const opacity = interpolate(
      animated.value,
      [0, 0.9, 1],
      [1, 1, 0],
      Extrapolation.CLAMP
    );

    return {
      opacity,
      transform: [{ translateX }, { translateY }, { scale }],
    };
  }, [transX, transY]);

  useEffect(() => {
    animated.value = withTiming(1, { duration: duration || 1000 });
  }, [duration]);

  return (
    <AnimatedImage
      style={[
        styles.coin,
        animateStyle,
        // { transform: [{ translateY: transY }, { translateX: transX }] },
      ]}
      source={images.silver_coin}
      resizeMode="stretch"
    />
  );
};

const useStyles = CreateResponsiveStyle(
  {
    coin: {
      width: 80,
      height: 80,
      position: "absolute",
    },
  },
  {
    [DEVICE_SIZES.MD]: {
      coin: {
        width: 80,
        height: 80,
        position: "absolute",
      },
    },
    [DEVICE_SIZES.SM]: {
      coin: {
        width: 50,
        height: 50,
        position: "absolute",
      },
    },
    [DEVICE_SIZES.XS]: {
      coin: {
        width: 50,
        height: 50,
        position: "absolute",
      },
    },
  }
);

export default CoinsInsert;
