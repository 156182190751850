import images from "@assets/images";
import { useForgotService } from "@src/ducks/hooks";
import React from "react";
import {
  ActivityIndicator,
  Image,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import useStyles from "./styles.css";
import { useDimensionContext } from "@src/DimensionProvider";

const CheckEmail = ({ email }: any) => {
  const styles = useStyles();
  const { baseWidth } = useDimensionContext();
  const {onForgot, isLoading}= useForgotService();

  const onSubmit = () => onForgot(email);

  return (
    <View style={styles.bodyStyle}>
      <Image
        source={images.bg_transparent}
        style={styles.imageBorderStyle}
        resizeMode="stretch"
      />
      <View style={[styles.viewEmail]}>
        <Image
          resizeMode="contain"
          source={images.check_email}
          style={styles.forgotTextStyle}
        />
        <Text style={[styles.txtEmailNote, { fontSize: baseWidth * 0.012 }]}>
          We've sent password reset instructions to:
        </Text>
        <Text style={[styles.txtEmailNote, { fontFamily: "Roboto-Bold", fontSize: baseWidth * 0.012 }]}>
          {email}
        </Text>
        <Text style={[styles.txtEmailNote, { fontSize: baseWidth * 0.012 }]}>
          If it doesn't arrive soon, check your spam folder or
        </Text>
        {isLoading ? (
          <ActivityIndicator
            style={{ marginTop: "2%" }}
            size={20}
            color="white"
            animating
          />
        ) : (
          <TouchableOpacity style={{ padding: 10 }} onPress={onSubmit}>
            <Text
              style={[styles.txtEmailNote, { textDecorationLine: "underline", fontSize: baseWidth * 0.012 }]}
            >
              send the email again.
            </Text>
          </TouchableOpacity>
        )}
      </View>
      <View style={styles.bottomHeight} />
    </View>
  );
};

export default CheckEmail;
