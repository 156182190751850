import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from "react";
import CoinsView from "./CoinsView";

export type Currency = {
  silver: number;
  bg: number;
  gwz: number;
}

interface CoordinateValue {
  transX: number;
  transY: number;
}

interface CoinContextValue {
  currency: Currency;
  onAdditionalCurrency: (value: Currency) => void;
  setCoordination: Dispatch<SetStateAction<CoordinateValue>>;
}

export const CoinContext = createContext<CoinContextValue>({
  currency: {silver: 0, bg: 0, gwz: 0} as Currency,
  onAdditionalCurrency: () => {},
  setCoordination: () => {},
});

interface CoinProviderProps {
  children: React.ReactElement;
}

const CoinProvider = ({ children }: CoinProviderProps) => {
  const [currency, setAdditionalCurrency] = useState<Currency>({} as Currency);
  const [coordination, setCoordination] = useState<CoordinateValue>({
    transX: 0,
    transY: 0,
  });

  const onAdditionalCurrency = useCallback((value: Currency) => {
    setAdditionalCurrency(value);
  },[currency]);

  return (
    <CoinContext.Provider value={{ setCoordination, currency, onAdditionalCurrency }}>
      {children}
      <CoinsView {...{ coordination, silver: currency.silver }} />
    </CoinContext.Provider>
  );
};

export const useCoinContext = () => {
  return useContext(CoinContext);
};

export default CoinProvider;
