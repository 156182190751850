import images, { SplashAssets } from "@assets/images";
import { DimensionContext } from "@src/DimensionProvider";
import { Loading02 } from "components/loading";
import Constants from "expo-constants";
import React from "react";
import { Image, Text, View } from "react-native";
import useCacheImage from "utils/cache-helper";
import ProgressBar from "./progress-bar";
import useStyles from "./styles.css";

const VERSION = `Version ${Constants.expoConfig?.version}`;

type Props = {
  onComplete: () => void;
}

const Splash = ({ onComplete }: Props) => {
  const styles = useStyles();
  const appIsReady = useCacheImage(SplashAssets);
  const { maxHeight } = React.useContext(DimensionContext);

  if(!appIsReady){
    return <Loading02 />;
  }

  return (
    <View style={styles.container}>
      <Image style={styles.i_background} source={{uri: images["gw_bg"]}} resizeMode="stretch" />
      <Image
        source={{uri: images.fortune_logo}}
        style={styles.i_logo}
        resizeMode="stretch"
      />
      <Image
        source={{uri: images.gameworkz}}
        style={styles.i_gameworkz}
        resizeMode="stretch"
      />
      <ProgressBar maxHeight={maxHeight} onComplete={onComplete} />
      <Text style={[styles.t_version, {fontSize: maxHeight * 0.014}]}>{VERSION}</Text>
    </View>
  );
};

export default React.memo(Splash);
