import { getStateFromPath, StackActions, useNavigation } from "@react-navigation/native";
import { useDimensionContext } from "@src/DimensionProvider";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useErrorService, useLobbyService, useProductService } from "@src/ducks/hooks";
import { selectedFetchGames } from "@src/ducks/slices/games.slice";
import { selectedUserUserID, selectPalmsPointBalance } from "@src/ducks/slices/user.slice";
import { useCoinContext } from "@src/screens/home/main/CoinProvider";
import { ImageButton06 } from "components/button";
import Loading from "components/loading/Loading";
import React from "react";
import { Image, Linking, Pressable, View } from "react-native";
import { claimCoinBonus } from "utils/api";
import { useFetchPost } from "utils/api/hooks";
import { findGameByID, findScreenKey } from "utils/filter-helper";
import useStyles from "./styles.css";

const Default = (props: any) => {
  const styles = useStyles();
  const { id, mail = {} } = props;

  const navigation = useNavigation<any>();
  const { baseWidth } = useDimensionContext();
  const { getProductById } = useProductService();
  const { onSetErrorMessage } = useErrorService();
  const userId = useAppSelector(selectedUserUserID);
  const gameList = useAppSelector(selectedFetchGames);
  const ppbalance = useAppSelector(selectPalmsPointBalance);
  const { loading, runCallbackRequest } = useFetchPost();
  const { onAdditionalCurrency } = useCoinContext();
  const {onChangeScreen, onFetchNotification} = useLobbyService();

  const position = React.useMemo(() => {
    const pos = mail.ctaType === "CTA" 
      ? mail.ctaButtonPosition 
      : mail.coinBonusButtonPosition;
  
    switch (pos) {
    case "LEFT":
      return "LEFT";
    case "CENTER":
      return "CENTER";
    case "RIGHT":
      return "RIGHT";
    default:
      return undefined;
    }
  }, [mail, styles]);

  const buttonImage = React.useMemo(() => {
    return mail.actionButtonType === "CTA" 
      ? mail.ctaButtonImage 
      : mail.coinBonusButtonImage;
  }, [mail]);

  const onPress = () => {
    try {
      console.log("mail", props);
      if(mail.actionButtonType === "CTA"){
        if(mail.ctaType === "URL"){
          const path = new URL(mail.url).pathname;
  
          // Parse URL and get navigation state
          const state = getStateFromPath(path);
    
          // Check if the state was successfully parsed
          if (state && state.routes && state.routes.length > 0) {
            // Extract route name and parameters (if any)
            const { name } = state.routes[0];
            const params = state.routes[0]?.params || {};
            const ScreenKey = findScreenKey(name);
            if(ScreenKey){
              if(ScreenKey === "Mission"){
                navigation.pop();
                onChangeScreen({ screen: "mission" });
                return;
              }
              navigation.dispatch(StackActions.replace(ScreenKey, params));
            }else{
              Linking.openURL(mail.url);
            }
          } else {
            console.error("Invalid URL or path");
          }
        }else if(mail.ctaType === "GAME"){
          const result = findGameByID(gameList, mail.gameId);
          if(result){
            navigation.dispatch(StackActions.replace("GameMode", { id: result.id, gameTypeId: result.gameTypeId }));
          }
        }else if(mail.ctaType === "PRODUCT"){
          const Item = getProductById(mail.productId);
          if(Item.palmsPoints && Item.palmsPoints > ppbalance){
            navigation.navigate("insufficient-palms-point");
            return;
          }
          navigation.dispatch(StackActions.replace("PaymentGateway", {...Item, mailId: mail.id}));
        }
      }else if(mail.actionButtonType === "COIN_BONUS"){
        runCallbackRequest(
          async () => {
            await claimCoinBonus(userId, id);
            onFetchNotification();
          },
          () => {
            navigation.pop();
            onAdditionalCurrency({silver: mail.silverAmount, bg: mail.bonusGoldAmount, gwz: 0});
          },
          (error) => {
            if(error.message){
              onSetErrorMessage(error?.message);
            }
          }
        );
      }
    } catch (error) {
      console.error("Error handling dynamic navigation:", error);
    }
  };

  return (
    <Pressable disabled={!mail.actionButtonType} onPress={onPress} style={[styles.v_default, { width: "100%"}]}>
      <Image style={[styles.i_inbox]} source={{uri: mail.contentImage}} resizeMode="stretch" />
      <Loading isLoading={loading} />
      <View style={[styles.v_action, position && styles[position] ]}>
        <ImageButton06 
          onPress={onPress}
          style={styles.btn_style}
          source={{uri: buttonImage}}
          width={baseWidth * 0.1} height={baseWidth * 0.05}
          labelStyle={{fontSize: baseWidth * 0.009}} />
      </View>
    </Pressable>
  );
};

export default React.memo(Default);
