import images from "@assets/images";
import { useNavigation } from "@react-navigation/native";
import { useAuthContext } from "@src/AuthProvider";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useLobbyService } from "@src/ducks/hooks";
import { selectedUserUserID } from "@src/ducks/slices/user.slice";
import { ImageButton } from "components/button";
import React, { useEffect, useState } from "react";
import { Image, Text, TextInput, View } from "react-native";
import { useFetchPost } from "utils/api/hooks";
import useMetrics from "utils/metrics";
import useStyles from "./styles.css";

const EditName = () => {
  const styles = useStyles();
  const {verticalScale} = useMetrics();
  const [name, setName] = useState("");
  const navigation = useNavigation<any>();
  const [error, setError] = useState(false);
  const { onErrorMessage } = useAuthContext();
  const {loading, runRequest } = useFetchPost();
  const { onUpdateMissions } = useLobbyService();
  const { onUpdateAvatarTier } = useLobbyService();
  const userId = useAppSelector(selectedUserUserID);
  const onDone = () => {
    if (name) {
      const tarea_regex = /(http:|https:|ws:|wss:)/;
      if (tarea_regex.test(name.toLowerCase())) {
        setError(true);
      } else {
        runRequest(`/api/v1/user/${userId}/changeusername`, { username: name }, 
          () => {
            onUpdateAvatarTier({displayName: name});
            onUpdateMissions("displayname");
            navigation.goBack();
          },
          (error) => {
            if(error?.message?.code === "DUPLICATED_DATA"){
              onErrorMessage("This display name is already in use. Please choose a different one.");
            }            
          }
        );
      }
    } else {
      navigation.goBack();
    }
  };

  useEffect(() => {
    setError(false);
  }, [name]);

  return (
    <View style={styles.container}>
      <View style={[styles.center_view, { height: verticalScale(150)}]}>
        <Image
          style={styles.i_background}
          source={{uri: images.bg_edit_name}}
          resizeMode="stretch"
        />
        <View style={[styles.v_textwrap, error && styles.v_textwrap_error]}>
          <TextInput
            value={name}
            maxLength={15}
            onChangeText={setName}
            placeholder="Type name here"
            style={styles.ti_username}
            placeholderTextColor={"white"}
          />
          {error && <Text style={styles.t_error}>Invalid</Text>}
        </View>
        <ImageButton
          onPress={onDone}
          isLoad={loading}
          style={styles.btn_close}
          source={images.btn_done}
        />
      </View>
    </View>
  );
};

export default EditName;
