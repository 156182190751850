import images, { SplashAssets } from "@assets/images";
import { Loading02 } from "components/loading";
import React from "react";
import { ImageBackground } from "react-native";
import useCacheImage from "utils/cache-helper";
import useStyles from "./styles.css";

const SplashBG = ({ style }: any) => {
  const styles = useStyles();
  const appIsReady = useCacheImage(SplashAssets);

  if(!appIsReady){
    return <Loading02 />;
  }

  return (
    <ImageBackground
      resizeMode="stretch"
      source={images.gw_bg}
      style={[styles.container, style]}
    >
    </ImageBackground>
  );
};

export default React.memo(SplashBG);
