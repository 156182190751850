import images from "@assets/images";
import { useRoute } from "@react-navigation/native";
import { DimensionContext } from "@src/DimensionProvider";
import { useForgotService } from "@src/ducks/hooks";
import { ImageButton06 } from "components/button";
import { TextInput01 } from "components/textinput";
import _ from "lodash";
import React, { useState } from "react";
import { Image, Text, View } from "react-native";
import useStyles from "./styles.css";

const ResetPassword = () => {
  const styles = useStyles();
  const route = useRoute<any>();
  const otp = route?.params?.otp;
  const email = route?.params?.email;
  const [error, setError] = useState<any>({});
  const [newPassword, setNewPassword] = useState("");
  const {isLoading, onResetPassword} = useForgotService();
  const { baseWidth } = React.useContext(DimensionContext);
  const [confirmPassword, setConfirmPassword] = useState("");
  
  const onSubmit = () => {
    const newErr: any = {};
    if (!newPassword) {
      newErr.newPassword = "Required.";
    } else if (!confirmPassword) {
      newErr.newPassword = "Required.";
    } else if (newPassword !== confirmPassword) {
      newErr.newPassword = "Not match.";
    }

    setError(newErr);

    if (_.isEmpty(newErr)) {
      const params = {
        otp,
        email,
        password: newPassword,
      };

      onResetPassword(params);
    }
  };

  return (
    <View style={styles.bodyStyle}>
      <Image
        source={images.bg_transparent}
        style={styles.imageBorderStyle}
        resizeMode="stretch"
      />
      <View style={[styles.viewEmail]}>
        <Image
          resizeMode="contain"
          source={images.reset_password}
          style={styles.forgotTextStyle}
        />
        <Text style={[styles.txtEmailNote, { marginTop: "5%", fontSize: baseWidth * 0.012 }]}>
          Strong passwords include numbers, letters and punctuation marks
        </Text>
        <TextInput01
          value={newPassword}
          placeholder="New Password"
          secureTextEntry={false}
          error={error.newPassword}
          onChangeText={setNewPassword}
          disableFullscreenUI={true}
          inputStyle={[styles.textAlign, { fontSize: baseWidth * 0.012 }]}
          containerStyle={[styles.ti_reset, { marginTop: "3%" }]}
        />
        <TextInput01
          value={confirmPassword}
          placeholder="Confirm New Password"
          secureTextEntry={false}
          onChangeText={setConfirmPassword}
          disableFullscreenUI={true}
          error={error.confirmPassword}
          inputStyle={[styles.textAlign, { fontSize: baseWidth * 0.012 }]}
          containerStyle={styles.ti_reset}
        />
        <ImageButton06
          onPress={onSubmit}
          isLoad={isLoading}
          style={styles.btn_reset_password}
          label="RESET PASSWORD"
          labelStyle={{ fontSize: baseWidth * 0.012 }}
        />
      </View>
    </View>
  );
};

export default ResetPassword;
