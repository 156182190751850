import { CDN_URL } from "@env";
import { StarterPackTypes } from "./types/hot-deals/starter-pack-types";

const StarterPackAssets: StarterPackTypes = {
  "img_0": CDN_URL + "/image-assets/animation-assets/hot-deals/starter-pack/images/img_0.png",
  "img_1": CDN_URL + "/image-assets/animation-assets/hot-deals/starter-pack/images/img_1.png",
  "img_2": CDN_URL + "/image-assets/animation-assets/hot-deals/starter-pack/images/img_2.png",
  "img_3": CDN_URL + "/image-assets/animation-assets/hot-deals/starter-pack/images/img_3.png",
  "img_4": CDN_URL + "/image-assets/animation-assets/hot-deals/starter-pack/images/img_4.png",
  "img_5": CDN_URL + "/image-assets/animation-assets/hot-deals/starter-pack/images/img_5.png",
  "img_6": CDN_URL + "/image-assets/animation-assets/hot-deals/starter-pack/images/img_6.png",
};

export default StarterPackAssets;
