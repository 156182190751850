import images from "@assets/images";
import { useNavigation } from "@react-navigation/native";
import { useDimensionContext } from "@src/DimensionProvider";
import { ImageButton06 } from "components/button";
import React from "react";
import { Image, Text, View } from "react-native";
import useStyles from "./styles.css";

const Success = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const { baseWidth } = useDimensionContext();

  const onPress = () => {
    navigation.navigate("SignIn");
  };

  return (
    <View style={styles.bodyStyle}>
      <Image
        source={images.bg_transparent}
        style={styles.imageBorderStyle}
        resizeMode="stretch"
      />
      <View style={styles.v_center}>
        <Image
          resizeMode="contain"
          source={images.success}
          style={styles.i_success}
        />
        <View style={[styles.v_logo]}>
          <Image
            resizeMode="stretch"
            source={images.fortune_logo}
            style={styles.i_fortune_logo}
          />
        </View>
        <View style={[styles.viewEmail]}>
          <Text style={[styles.txtEmailNote, { fontSize: baseWidth * 0.011}]}>
            Your password has been updated.
          </Text>
          <View style={[styles.height20, { height: baseWidth * 0.01}]} />
          <Text style={[styles.txtEmailNote, { fontSize: baseWidth * 0.011}]}>
            You may log in and start playing!
          </Text>
          <View style={[styles.height20, { height: baseWidth * 0.01}]} />
        </View>
        <ImageButton06
          onPress={onPress}
          label="LOG IN"
          style={styles.btnSubmit}
          labelStyle={{ fontSize: baseWidth * 0.014 }}
        />
      </View>
    </View>
  );
};

export default Success;
