import images from "@assets/images";
import { useNavigation } from "@react-navigation/native";
import { DimensionContext } from "@src/DimensionProvider";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useSettingsService } from "@src/ducks/hooks";
import { selectedAvatarTier } from "@src/ducks/slices/lobby.slice";
import { selectedUserUserID } from "@src/ducks/slices/user.slice";
import { colors } from "@src/theme";
import { ImageButton } from "components/button";
import React from "react";
import { Image, Text, View } from "react-native";
import { useFetchGet } from "utils/api/hooks/useFetch";
import useStyles from "./styles.css";

const Details = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const tier = useAppSelector(selectedAvatarTier);
  const { onChangeProfile } = useSettingsService();
  const userId = useAppSelector(selectedUserUserID);
  const { baseWidth } = React.useContext(DimensionContext);
  const { data } = useFetchGet(`/api/v1/leaderboard/score/${userId}`);

  return (
    <View style={styles.p_container}>
      <View style={[styles.v_center_profile]}>
        <Image
          style={styles.i_profile_frame}
          source={{uri: images.profile_frame}}
          resizeMode="stretch"
        />
        <View style={[styles.v_profile_divider, { height: baseWidth * 0.078}]} />
        <View style={[styles.v_details]}>
          <View
            style={[
              styles.v_player,
              { 
                width: baseWidth * 0.1927, 
                height: baseWidth * 0.039,
                borderWidth: baseWidth * 0.004,
                borderColor: colors.white3,
                borderRadius: (baseWidth * 0.033) / 2 },
            ]} >
            <ImageButton
              onPress={() => navigation.push("update-name")}
              style={[styles.btn_edit, {
                width: baseWidth * 0.017, 
                height: baseWidth * 0.017,
              }]}
              source={images.icon_edit}
            />
            <Text style={[styles.t_player_name, { fontSize: baseWidth * 0.011 }]} >
              {tier.displayName}
            </Text>
          </View>
          <Text style={[styles.t_userid, { fontSize: baseWidth * 0.011 }]}>
            USER ID: {userId}
          </Text>
          <ImageButton
            onPress={() => navigation.push("EditProfile")}
            style={[
              styles.btn_edit_profile,
              { width: baseWidth * 0.16, height: baseWidth * 0.033 },
            ]}
            source={images.btn_edit_profile}
          />
          <Image
            style={[
              styles.i_yourrank,
              { width: baseWidth * 0.216, height: baseWidth * 0.066 },
            ]}
            source={{uri: images.your_rank}}
            resizeMode="stretch"
          />
          <View
            style={[
              styles.v_ranking,
              { 
                width: baseWidth * 0.112, 
                height: baseWidth * 0.025,
                borderWidth: baseWidth * 0.0025,
                borderColor: colors.black3,
                borderRadius: (baseWidth * 0.025) / 2  },
            ]}
          >
            <Text style={[styles.t_rank, { fontSize: baseWidth * 0.011 }]}>
              {data?.rank}
            </Text>
          </View>
        </View>
      </View>
      <ImageButton
        scaleSize={0.98}
        onPress={() => onChangeProfile(false)}
        style={[styles.btn_back, {
          width: baseWidth * 0.05, 
          height: baseWidth * 0.05,
        }]}
        source={images.btn_arrow}
      />
    </View>
  );
};

export default Details;
