import { colors } from "@src/theme";
import { StyleSheet } from "react-native";
import { CreateResponsiveStyle } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    container: {
      flex: 1,
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    bodyStyle: {
      width: "53.77%",
      height: "65.86%",
      alignItems: "center",
      justifyContent: "center",
    },
    imageBorderStyle: {
      width: "100%",
      height: "100%",
      ...StyleSheet.absoluteFillObject,
    },
    bottomHeight: {
      width: 100,
      height: "10%",
    },
    header: {
      zIndex: 1,
      fontSize: 30,
      paddingTop: 48,
      color: "#6C0B7B",
      marginBottom: 40,
      fontFamily: "Roboto-Medium",
    },

    // ENTER EMAIL COMPONENT STYLE
    forgotTextStyle: { width: "72.83%", height: "8.09%" },
    viewEmail: {
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    txtEmailNote: {
      fontSize: 16,
      marginTop: "3%",
      textAlign: "center",
      color: colors.white,
      fontFamily: "Roboto",
    },
    inputEmailStyle: { width: "64.57%", height: "10%", marginTop: "3%" },
    height20: { height: 20 },
    imageCloseStyle: { height: 30, width: 30 },
    textAlign: { textAlign: "center", paddingLeft: 0, fontSize: 16 },
    btnSubmit: { height: "12.99%", width: "21.29%", marginLeft: "1%", marginTop: "3%" },

    // RESET PASSWORD
    ti_reset: { marginTop: 20, height: "14%" },
    btn_reset_password: { height: "20%", width: "30%", marginLeft: 10 },

    // v_check_email: {
    //   width: "55%",
    //   height: verticalScale(250),
    //   alignItems: "center",
    //   justifyContent: "center",
    // },

    btnClose: {
      width: "8.47%",
      height: "13.15%",
      zIndex: 1,
      top: "5%",
      right: "4%",
      position: "absolute",
    },
  },
);

export default useStyles;
