import { CDN_URL } from "@env";
import { CurtainTypes } from "./types/jade-egg/curtain-types";

const JadeEggCurtainAssets: CurtainTypes = {
  "seq_0_0": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_0.png",
  "seq_0_1": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_1.png",
  "seq_0_2": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_2.png",
  "seq_0_3": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_3.png",
  "seq_0_4": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_4.png",
  "seq_0_5": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_5.png",
  "seq_0_6": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_6.png",
  "seq_0_7": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_7.png",
  "seq_0_8": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_8.png",
  "seq_0_9": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_9.png",
  "seq_0_10": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_10.png",
  "seq_0_11": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_11.png",
  "seq_0_12": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_12.png",
  "seq_0_13": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_13.png",
  "seq_0_14": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_14.png",
  "seq_0_15": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_15.png",
  "seq_0_16": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_16.png",
  "seq_0_17": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_17.png",
  "seq_0_18": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_18.png",
  "seq_0_19": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_19.png",
  "seq_0_20": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_20.png",
  "seq_0_21": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_21.png",
  "seq_0_22": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_22.png",
  "seq_0_23": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_23.png",
  "seq_0_24": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_24.png",
  "seq_0_25": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_25.png",
  "seq_0_26": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_26.png",
  "seq_0_27": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_27.png",
  "seq_0_28": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_28.png",
  "seq_0_29": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_29.png",
  "seq_0_30": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_30.png",
  "seq_0_31": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_31.png",
  "seq_0_32": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_32.png",
  "seq_0_33": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_33.png",
  "seq_0_34": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_34.png",
  "seq_0_35": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_35.png",
  "seq_0_36": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_36.png",
  "seq_0_37": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_37.png",
  "seq_0_38": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_38.png",
  "seq_0_39": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_39.png",
  "seq_0_40": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_40.png",
  "seq_0_41": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_41.png",
  "seq_0_42": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_42.jpg",
  "seq_0_43": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_43.jpg",
  "seq_0_44": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_44.jpg",
  "seq_0_45": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_45.jpg",
  "seq_0_46": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_46.jpg",
  "seq_0_47": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_47.jpg",
  "seq_0_48": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_48.jpg",
  "seq_0_49": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_49.jpg",
  "seq_0_50": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_50.jpg",
  "seq_0_51": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_51.jpg",
  "seq_0_52": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_52.jpg",
  "seq_0_53": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_53.jpg",
  "seq_0_54": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_54.jpg",
  "seq_0_55": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_55.jpg",
  "seq_0_56": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_56.jpg",
  "seq_0_57": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_57.jpg",
  "seq_0_58": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_58.jpg",
  "seq_0_59": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_59.jpg",
  "seq_0_60": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_60.jpg",
  "seq_0_61": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_61.jpg",
  "seq_0_62": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_62.jpg",
  "seq_0_63": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_63.jpg",
  "seq_0_64": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_64.jpg",
  "seq_0_65": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_65.jpg",
  "seq_0_66": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_66.jpg",
  "seq_0_67": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_67.jpg",
  "seq_0_68": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_68.jpg",
  "seq_0_69": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_69.jpg",
  "seq_0_70": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_70.jpg",
  "seq_0_71": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_71.jpg",
  "seq_0_72": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_72.jpg",
  "seq_0_73": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_73.jpg",
  "seq_0_74": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_74.jpg",
  "seq_0_75": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_75.jpg",
  "seq_0_76": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_76.jpg",
  "seq_0_77": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_77.jpg",
  "seq_0_78": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_78.jpg",
  "seq_0_79": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_79.jpg",
  "seq_0_80": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_80.jpg",
  "seq_0_81": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_81.jpg",
  "seq_0_82": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_82.png",
  "seq_0_83": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_83.png",
  "seq_0_84": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_84.png",
  "seq_0_85": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_85.png",
  "seq_0_86": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_86.png",
  "seq_0_87": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_87.png",
  "seq_0_88": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_88.png",
  "seq_0_89": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_89.png",
  "seq_0_90": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_90.png",
  "seq_0_91": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_91.png",
  "seq_0_92": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_92.png",
  "seq_0_93": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_93.png",
  "seq_0_94": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_94.png",
  "seq_0_95": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_95.png",
  "seq_0_96": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_96.png",
  "seq_0_97": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_97.png",
  "seq_0_98": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_98.png",
  "seq_0_99": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_99.png",
  "seq_0_100": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_100.png",
  "seq_0_101": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_101.png",
  "seq_0_102": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_102.png",
  "seq_0_103": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_103.png",
  "seq_0_104": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_104.png",
  "seq_0_105": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_105.png",
  "seq_0_106": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_106.png",
  "seq_0_107": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_107.png",
  "seq_0_108": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_108.png",
  "seq_0_109": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_109.png",
  "seq_0_110": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_110.png",
  "seq_0_111": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_111.png",
  "seq_0_112": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_112.png",
  "seq_0_113": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_113.png",
  "seq_0_114": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_114.png",
  "seq_0_115": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_115.png",
  "seq_0_116": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_116.png",
  "seq_0_117": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_117.png",
  "seq_0_118": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_118.png",
  "seq_0_119": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_119.png",
  "seq_0_120": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_120.png",
  "seq_0_121": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_121.png",
  "seq_0_122": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_122.png",
  "seq_0_123": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_123.png",
  "seq_0_124": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_124.png",
  "seq_0_125": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_125.png",
  "seq_0_126": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_126.png",
  "seq_0_127": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_127.png",
  "seq_0_128": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_128.png",
  "seq_0_129": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_129.png",
  "seq_0_130": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_130.png",
  "seq_0_131": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_131.png",
  "seq_0_132": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_132.png",
  "seq_0_133": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_133.png",
  "seq_0_134": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_134.png",
  "seq_0_135": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_135.png",
  "seq_0_136": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_136.png",
  "seq_0_137": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_137.png",
  "seq_0_138": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_138.png",
  "seq_0_139": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_139.png",
  "seq_0_140": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_140.png",
  "seq_0_141": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_141.png",
  "seq_0_142": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_142.png",
  "seq_0_143": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_143.png",
  "seq_0_144": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_144.png",
  "seq_0_145": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_145.png",
  "seq_0_146": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_146.png",
  "seq_0_147": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_147.png",
  "seq_0_148": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_148.png",
  "seq_0_149": CDN_URL + "/image-assets/animation-assets/jade-egg/curtain/images/seq_0_149.png",
};

export default JadeEggCurtainAssets;
