import { CDN_URL } from "@env";
import { TierRewardsTypes } from "./types/tier-rewards-types";

const TierRewardsAssets: TierRewardsTypes = {
  "img_0": CDN_URL + "/image-assets/animation-assets/tier-rewards/images/img_0.png",
  "img_1": CDN_URL + "/image-assets/animation-assets/tier-rewards/images/img_1.png",
  "img_2": CDN_URL + "/image-assets/animation-assets/tier-rewards/images/img_2.png",
  "img_3": CDN_URL + "/image-assets/animation-assets/tier-rewards/images/img_3.png",
  "img_4": CDN_URL + "/image-assets/animation-assets/tier-rewards/images/img_4.png",
  "img_5": CDN_URL + "/image-assets/animation-assets/tier-rewards/images/img_5.png",
  "img_6": CDN_URL + "/image-assets/animation-assets/tier-rewards/images/img_6.png",
  "img_7": CDN_URL + "/image-assets/animation-assets/tier-rewards/images/img_7.png",
 
};

export default TierRewardsAssets;
