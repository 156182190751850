import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedChangeScreen } from "@src/ducks/slices/lobby.slice";
import React from "react";
import { ActivityIndicator, View } from "react-native";
import LobbyProvider from "./provider";
import useStyles from "./styles.css";

const MissionList = React.lazy(() => import("@src/screens/mission2/missions"));
const LeaderBoard = React.lazy(() => import("@src/screens/leaderboard"));
const GoldShop = React.lazy(() => import("@src/screens/goldshop"));
const LobbyMain = React.lazy(() => import("./lobby-main"));

const Lobby = () => {
  const styles = useStyles();
  const { screen } = useAppSelector(selectedChangeScreen);

  const RenderScreens = React.useMemo(() => {
    switch(screen){
    case "leaderboard":
      return (
        <View style={[styles.centerStyle, styles.h_100]}>
          <LeaderBoard />
        </View>
      );
    case "mission":
      return (
        <View style={[styles.centerStyle]}>
          <MissionList />
        </View>
      );
    case "gold-shop":
      return (
        <View style={[styles.centerStyle, styles.h_100]}>
          <GoldShop />
        </View>
      );
    case "main":
    default:
      return (
        <View style={[styles.centerStyle]}>
          <LobbyMain />
        </View>
      );
    }
  },[screen]);

  return (
    <LobbyProvider>
      <React.Suspense fallback={<ActivityIndicator color="white" />}>
        {RenderScreens}
      </React.Suspense>
    </LobbyProvider>
  );
};

export default React.memo(Lobby);
