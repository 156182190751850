import { CDN_URL } from "@env";
import { ShakeTypes } from "./types/jade-egg/shake-types";

const JadeEggShakeAssets: ShakeTypes = {
  "img_0": CDN_URL + "/image-assets/animation-assets/jade-egg/shake/images/img_0.png",
  "img_1": CDN_URL + "/image-assets/animation-assets/jade-egg/shake/images/img_1.png",
  "img_2": CDN_URL + "/image-assets/animation-assets/jade-egg/shake/images/img_2.png",
  "img_3": CDN_URL + "/image-assets/animation-assets/jade-egg/shake/images/img_3.png",
  "img_4": CDN_URL + "/image-assets/animation-assets/jade-egg/shake/images/img_4.png",
  "img_5": CDN_URL + "/image-assets/animation-assets/jade-egg/shake/images/img_5.png",
  "img_6": CDN_URL + "/image-assets/animation-assets/jade-egg/shake/images/img_6.png",
};

export default JadeEggShakeAssets;
